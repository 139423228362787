import { Box, Flex, Loader, Text } from "@mantine/core";

export default function Loading({ msg }: { msg: string }) {

    return (
        <Box
            style={{
                height: '100vh',
                width: '100vw',
            }}
        >
            <Flex
                justify="center"
                align="center"
                style={{
                    height: '100%',
                }}
                gap={20}
                direction="column"
            >
                <Loader size="xl" />
                <Text>{msg}</Text>
            </Flex>
        </Box>
    )

}