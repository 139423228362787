import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Invoice, Table } from "../../components";
import useApi from "../../hooks/useApi";
import { Button, Flex, NumberFormatter, Text, useMantineTheme } from "@mantine/core";
import { IconPrinter, IconX } from "@tabler/icons-react";
import { compile } from "@onedoc/react-print";
import { modals } from "@mantine/modals";
import useUserCan from "../../hooks/useUserCan";
import CreateInvoice from "./createInvoice";

export default () => {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const { userCan } = useUserCan();

    const studentsApiInterceptor = useApi<any>({
        key: 'students',
        params: {},
    });
    const academicYearsApiInterceptor = useApi<any>({
        key: 'academic_years',
        params: {},
    });
    const studentsInvoicesApiInterceptor = useApi<any>({
        key: 'students_invoices',
        params: {},
    });

    const printInvoice = useMemo(() => async (item: any) => {
        // open <Invoice /> in new window
        const html = await compile(<Invoice
            invoice={item}
        />);
        const newWindow = window.open();
        if (!newWindow) return;
        newWindow.document.write(html);
        newWindow.document.title = `Invoice #${item.id} - ${item.student.first_name} ${item.student.second_name} ${item.student.third_name} ${item.student.fourth_name} - ${item.amount} د.ع`;
        newWindow.document.close();
        newWindow.focus();
        setTimeout(() => {
            newWindow.close();
        }, 500);
        newWindow.print();
    }, []);

    return (
        <>
            {
                userCan('create_students_invoices') && (
                    <CreateInvoice />
                )
            }
            <Table
                disableAdd
                disableEdit
                interceptor={'students_invoices'}
                singular={t('وصل')}
                plural={t('الوصولات')}
                columns={[
                    {
                        accessorKey: 'number',
                        header: t('رقم الوصل'),
                        accessorFn: (item: any) => (
                            <Flex gap={'xs'} align={'center'}>
                                <Text size={'sm'} style={{ direction: 'ltr' }}>#{item.number}</Text>
                                <Button
                                    size={'xs'}
                                    variant={'light'}
                                    onClick={async () => {
                                        printInvoice(item);
                                    }}
                                    leftSection={<IconPrinter />}
                                >
                                    {t('طباعة')}
                                </Button>
                            </Flex>
                        ),
                    },
                    {
                        accessorKey: 'student_id',
                        accessorFn: ({ student }: any) => (
                            <Flex
                                direction={'column'}
                            >
                                <Text size={'sm'}>{student.first_name} {student.second_name} {student.third_name} {student.fourth_name}</Text>
                                <Text size={'xs'}>{student.department.college.name} / {student.department.name} / {student.study_type === 'morning' ? t('صباحي') : t('مسائي')} / {t('المرحلة')} {student.stage}</Text>
                            </Flex>
                        ),
                        header: t('الطالب'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (studentsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: `${item.first_name} ${item.second_name} ${item.third_name} ${item.fourth_name} / ${item.department.college.name} / ${item.department.name} / ${item.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${item.stage}`,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                studentsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'tuition_fee_id',
                        accessorFn: ({ tuition_fee }: any) => (
                            <Text size={'sm'}>{tuition_fee.name}</Text>
                        ),
                        header: t('القسط'),
                        // filterVariant: 'select',
                        // mantineFilterSelectProps: () => ({
                        //     data: (tuitionFeesApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                        //         value: String(item.id),
                        //         label: item.name,
                        //     })) || [],
                        //     searchable: true,
                        //     onSearchChange: (value: string) => {
                        //         tuitionFeesApiInterceptor.setParam('search', value);
                        //     },
                        // }),
                        // enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                    {
                        accessorKey: 'academic_year_id',
                        accessorFn: ({ academic_year }: { academic_year: any }) => academic_year.name,
                        header: t('العام الدراسي'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (academicYearsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                academicYearsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'amount',
                        accessorFn: ({ amount, discount_amount }: any) => (
                            <Flex direction={'column'}>
                                <NumberFormatter
                                    value={amount}
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 'bold',
                                    }}
                                    suffix={t('د.ع')}
                                    thousandSeparator
                                />
                                {
                                    Boolean(discount_amount) && (
                                        <Flex direction={'column'}>
                                            <NumberFormatter
                                                value={discount_amount}
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: theme.colors.red[6],
                                                }}
                                                color={'teal'}
                                                suffix={t('د.ع')}
                                                prefix={'خصم '}
                                                thousandSeparator
                                            />
                                            <Text size={'xs'} style={{ color: 'gray' }}>{t('المبلغ الصافي')}: <NumberFormatter
                                                value={amount - discount_amount}
                                                style={{
                                                    fontWeight: 'bold',
                                                }}
                                                suffix={t('د.ع')}
                                                thousandSeparator
                                            /></Text>
                                        </Flex>
                                    )
                                }
                            </Flex>
                        ),
                        header: t('المبلغ'),
                    },
                    {
                        accessorKey: 'daleel_id',
                        accessorFn: ({ daleel_id }: any) => (
                            <Text size={'sm'}>{daleel_id}</Text>
                        ),
                        header: t('رقم الدليل'),
                    },
                    {
                        accessorKey: 'created_at',
                        accessorFn: ({ created_at }: any) => (
                            <Text size={'sm'}>{created_at}</Text>
                        ),
                        header: t('تاريخ الإصدار'),
                        filterVariant: 'date-range',
                    },
                    {
                        accessorKey: 'author_id',
                        accessorFn: ({ author }: any) => (
                            <Flex direction={'column'}>
                                <Text size={'sm'}>{author.name}</Text>
                                <Text size={'sm'} style={{ color: 'gray' }}>{author.email}</Text>
                            </Flex>
                        ),
                        header: t('الموظف'),
                        // enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                    {
                        accessorKey: 'actions',
                        accessorFn: (row: any) => {
                            // if created at is older than 30 minutes, disable delete button
                            // const createdAt = new Date(row.created_at).getTime();
                            // const now = new Date().getTime();
                            // const diff = now - createdAt;
                            // const minutes = diff / 1000 / 60;

                            // if (minutes > 30) {
                            //     return <></>;
                            // }

                            if (!userCan('delete_students_invoices')) {
                                return <></>;
                            }

                            return (
                                <Button
                                    size={'xs'}
                                    variant={'outline'}
                                    color={'red'}
                                    onClick={() => {
                                        modals.openConfirmModal({
                                            title: t('تأكيد الحذف'),
                                            children: (
                                                <Text size={'sm'}>
                                                    {t('هل أنت متأكد من حذف هذا الوصل؟')}
                                                </Text>
                                            ),
                                            labels: {
                                                cancel: t('إلغاء'),
                                                confirm: t('حذف'),
                                            },
                                            onConfirm: () => studentsInvoicesApiInterceptor.mutation.delete(row.id),
                                        });
                                    }}
                                    leftSection={<IconX size={14} />}
                                >
                                    {t('مسح')}
                                </Button>
                            );
                        },
                        header: t('الإجراءات'),
                        enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                ]}
            />
        </>
    );

}