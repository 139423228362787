import { ActionIcon, Card, Flex, Menu, NumberFormatter, Text } from "@mantine/core"
import { IconAdjustments } from "@tabler/icons-react"
import useUserCan from "../../hooks/useUserCan";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { getMyTotals } from "../../api/routes/financeanalytics";
import { useTranslation } from "react-i18next";
import { useInterval } from "@mantine/hooks";

export default () => {

    const { userCan } = useUserCan();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [totalIncome, setTotalIncome] = useState(0);

    const refreshTotals = useMemo(() => async () => {
        getMyTotals().then((res) => {
            setTotalIncome(res.total_income);
        });
    }, []);

    const interval = useInterval(() => {
        refreshTotals();
    }, 1000);

    const userCanCreateInvocies = userCan(['financial_employee', 'financial_admin']);
    useEffect(() => {
        if (!userCanCreateInvocies) return;
        refreshTotals();
        interval.start();
        return interval.stop;
    }, [userCanCreateInvocies]);

    return (
        <Flex align="center" gap="xs">
            {
                userCan(['financial_employee', 'financial_admin']) && (
                    <Card py={3} withBorder shadow="md">
                        <Flex direction="column">
                            <Text size={'xs'}>{t('مقبوضات اليوم')}</Text>
                            <strong>
                                <NumberFormatter value={totalIncome} thousandSeparator suffix=" IQD" />
                            </strong>
                        </Flex>
                    </Card>
                )
            }
            {
                userCan('edit_system_options') && (
                    <ActionIcon variant="filled" aria-label="Settings"
                        onClick={() => navigate('/app/options')}
                    >
                        <IconAdjustments style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    </ActionIcon>
                )
            }
        </Flex>
    )

}