import { useTranslation } from "react-i18next";
import { Table } from "../components";
import useApi from "../hooks/useApi";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "@mantine/form";
import { Box, Button, Card, Divider, Flex, Modal, ScrollArea, SimpleGrid, Space, Text, TextInput, Title, useMantineTheme } from "@mantine/core";
import { modals } from "@mantine/modals";
import { ReactSortable } from "react-sortablejs";
import { notifications } from "@mantine/notifications";
import { useHover } from "@mantine/hooks";
import { CSS } from "@onedoc/react-print";
import { TimeInput } from "@mantine/dates";

export default () => {

    const { t, i18n } = useTranslation();

    const departmentsApiInterceptor = useApi<any>({
        key: 'departments',
        params: {},
    });

    const [selectedRow, setSelectedRow] = useState<any>(null);

    return (
        <>
            {
                Boolean(selectedRow) && (
                    <TimetableManage itemData={selectedRow} />
                )
            }
            <Table
                interceptor={'timetable'}
                singular={t('جدول')}
                plural={t('جداول الحصص')}
                // drawerSize={'calc(100% - 300px)'}
                disableEdit={true}
                onRowClick={(item: any) => {
                    setSelectedRow({ ...item });
                    window.scrollTo(0, 0);
                }}
                columns={[
                    {
                        accessorKey: 'department_id',
                        accessorFn: ({ department }: { department: any }) => `${department.college.name} / ${department.name}`,
                        header: t('القسم'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (departmentsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                departmentsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'stage',
                        header: t('المرحلة'),
                    },
                    {
                        accessorKey: 'study_type',
                        accessorFn: ({ study_type }: { study_type: string }) => study_type === 'morning' ? t('صباحي') : study_type === 'evening' ? t('مسائي') : study_type,
                        header: t('الدراسة'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: [
                                {
                                    label: t('صباحي'),
                                    value: '$morning'
                                },
                                {
                                    label: t('مسائي'),
                                    value: '$evening'
                                }
                            ]
                        }),
                    },
                    {
                        accessorKey: 'created_at',
                        header: t('تاريخ الإنشاء'),
                        filterVariant: 'date',
                        mantineFilterDateInputProps: {
                            valueFormat: 'YYYY-MM-DD',
                            locale: i18n.language,
                            clearable: true,
                            dateParser: (value: string) => dayjs(value).toDate(),
                        }
                    },
                ]}
                fields={[
                    {
                        type: 'select',
                        label: t('القسم'),
                        name: 'department_id',
                        params: {
                            limit: 9999,
                        },
                        labelAccessor(item) {
                            return `${item.college.name} / ${item.name}`;
                        },
                        initialValue: item => item?.department?.id,
                        required: true,
                        interceptor: 'departments',
                    },
                    {
                        type: 'select',
                        label: t('الدراسة'),
                        name: 'study_type',
                        required: true,
                        initialValue: (item) => item?.study_type,
                        initialData(item) {
                            return [
                                {
                                    value: 'morning',
                                    label: t('صباحي'),
                                },
                                {
                                    value: 'evening',
                                    label: t('مسائي'),
                                },
                            ];
                        },
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                    },
                    {
                        type: 'number',
                        label: t('المرحلة'),
                        name: 'stage',
                        required: true,
                        initialValue: item => item?.stage,
                    },
                ]}
            // customFields={}
            />
        </>
    );

}

const TimetableManage = ({ itemData }: { itemData: any }) => {

    const days = useMemo<any>(() => ({
        sunday: 'الأحد',
        monday: 'الإثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت',
    }), [itemData]);

    const theme = useMantineTheme();
    const { t } = useTranslation();

    const id = useMemo(() => Math.random().toString(36).substring(7), []);

    const apiInterceptor = useApi<any>({
        key: 'timetable',
        params: {},
        disableQuery: true,
        onDone(action, response) {
            if (action === 'update') {
                if (response?.message) {
                    notifications.show({
                        id,
                        title: t('حدث خطأ'),
                        message: response.message,
                        color: 'red',
                        autoClose: true,
                    });
                    return;
                }
                notifications.update({
                    id,
                    title: t('تم حفظ البيانات بنجاح'),
                    message: t('تم حفظ البيانات بنجاح'),
                    color: 'teal',
                    autoClose: true,
                });
            } else if (action === 'delete') {
                if (response?.message) {
                    notifications.show({
                        id,
                        title: t('حدث خطأ'),
                        message: response.message,
                        color: 'red',
                        autoClose: true,
                    });
                    return;
                }
                notifications.update({
                    id,
                    title: t('تم حذف الجدول بنجاح'),
                    message: t('تم حذف الجدول بنجاح'),
                    color: 'teal',
                    autoClose: true,
                });
                window.location.reload();
            }
        },
    });

    const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 });

    return (
        <>
            <ScrollArea onScrollPositionChange={onScrollPositionChange}>
                <Flex style={{ gap: 0, width: 'max-content' }} m={0}>
                    <TimetableDay itemData={itemData['saturday']} day={'saturday'} index={0} onChange={(value) => { itemData['saturday'] = value ?? [] }} />
                    <TimetableDay itemData={itemData['sunday']} day={'sunday'} index={1} onChange={(value) => { itemData['sunday'] = value ?? [] }} />
                    <TimetableDay itemData={itemData['monday']} day={'monday'} index={2} onChange={(value) => { itemData['monday'] = value ?? [] }} />
                    <TimetableDay itemData={itemData['tuesday']} day={'tuesday'} index={3} onChange={(value) => { itemData['tuesday'] = value ?? [] }} />
                    <TimetableDay itemData={itemData['wednesday']} day={'wednesday'} index={4} onChange={(value) => { itemData['wednesday'] = value ?? [] }} />
                    <TimetableDay itemData={itemData['thursday']} day={'thursday'} index={5} onChange={(value) => { itemData['thursday'] = value ?? [] }} />
                    <TimetableDay itemData={itemData['friday']} day={'friday'} index={6} onChange={(value) => { itemData['friday'] = value ?? [] }} />
                    <Space h="xs" />
                </Flex>
                <Box
                    style={{
                        background: `linear-gradient(to right, ${theme.colors.gray[5]} 0%, transparent 100%)`,
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '25%',
                        height: '100%',
                        pointerEvents: 'none',
                        display: scrollPosition.x == 0 ? 'block' : 'none',
                    }}
                ></Box>
            </ScrollArea>
            <Flex m={'xs'} gap={'xs'}>
                <Button variant="outline" size="xs" fullWidth
                    onClick={() => {
                        notifications.show({
                            id,
                            title: t('جاري حفظ البيانات'),
                            message: t('يرجى الانتظار'),
                            color: 'blue',
                            autoClose: false,
                        });
                        apiInterceptor.mutation.update(itemData.id, {
                            ...itemData,
                        });
                    }}
                >
                    {t('حفظ')}
                </Button>
                <Button variant="outline" size="xs" color="red"
                    onClick={() => {
                        // show confirm modal
                        modals.openConfirmModal({
                            title: t('حذف الجدول'),
                            children: t('هل أنت متأكد من حذف الجدول؟، لا يمكن التراجع عن هذا الإجراء'),
                            onConfirm: () => {
                                notifications.show({
                                    id,
                                    title: t('جاري حذف الجدول'),
                                    message: t('يرجى الانتظار'),
                                    color: 'blue',
                                    autoClose: false,
                                });
                                apiInterceptor.mutation.delete(itemData.id);
                            },
                            labels: {
                                confirm: t('حذف'),
                                cancel: t('إلغاء'),
                            },
                        });
                    }}
                >
                    {t('حذف')}
                </Button>
            </Flex>
            <Divider />
        </>
    )

};

const TimetableDay = ({
    itemData,
    onChange,
    day,
    index,
}: {
    itemData: any,
    onChange?: (value: any) => any,
    day: string,
    index: number,
}) => {

    const days = useMemo<any>(() => ({
        sunday: 'الأحد',
        monday: 'الإثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت',
    }), []);

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const [demoData, setDemoData] = useState<any>([
        // {
        //     time: '09:00 - 10:00',
        //     subject: 'الرياضيات',
        //     teacher: 'أحمد',
        //     room: 'قاعة A1',
        // },
        // {
        //     time: '10:00 - 11:00',
        //     subject: 'العربية',
        //     teacher: 'محمد',
        //     room: 'قاعة A2',
        // },
        ...(itemData ?? []),
    ]);

    useEffect(() => {
        setDemoData(itemData);
    }, [itemData]);

    useEffect(() => {
        onChange && onChange(demoData);
    }, [demoData]);

    const [opened, setOpened] = useState(false);

    const [selectedSubject, setSelectedSubject] = useState<any>(null);

    return (
        <Box
            key={day}
            p={'xs'}
            style={{
                backgroundColor: index % 2 === 0 ? theme.colors.gray[3] : theme.colors.gray[1],
                minWidth: '200px',
            }}
        >
            <Title order={6}>{days[day] ?? ''}</Title>
            <CSS>{`
                .timetable-item {
                    transition: 0.3s;
                    border: 1px solid transparent;
                }
                .timetable-item:hover {
                    border: 1px solid ${theme.colors.gray[5]};
                    background-color: ${theme.colors.gray[1]};
                }
            `}</CSS>
            <ReactSortable list={demoData} setList={(newList: any) => {
                // filter out empty items
                // newList = newList.filter((item: any) => item?.time && item?.subject && item?.teacher && item?.room);
                setDemoData(newList);
            }} animation={300}>
                {
                    demoData?.map((item: any, index: number) => (
                        <Card shadow="xs" padding="xs" mt="xs" key={index}
                            onClick={() => {
                                setSelectedSubject({
                                    index,
                                    subject: item,
                                });
                                setOpened(true);
                            }}
                            style={{
                                cursor: 'pointer',
                            }}
                            className="timetable-item"
                        >
                            <Flex direction="column">
                                <Text size={'xs'}>{item?.time}</Text>
                                <Text size={'xs'}>{item?.subject}</Text>
                                <Text size={'xs'}>{item?.teacher}</Text>
                                <Text size={'xs'}>{item?.room}</Text>
                                <Text size={'xs'}>{item?.group}</Text>
                            </Flex>
                        </Card>
                    )) ?? []
                }
            </ReactSortable>
            <Button
                style={{
                    width: '100%',
                }}
                size="xs"
                variant="outline"
                mt="xs"
                onClick={() => {

                    // modals.open({
                    //     title: t('إضافة حصة'),
                    //     children: (
                    //     ),
                    // });
                    setOpened(true);

                }}
            >
                {`+ ${t('إضافة')}`}
            </Button>
            <ModalForm
                opened={opened}
                setOpened={setOpened}
                selectedSubject={selectedSubject}
                onChange={(values) => {
                    if (selectedSubject) {
                        setDemoData(demoData.map((item: any, index: number) => {
                            if (index === selectedSubject.index) {
                                return { ...values };
                            }
                            return item;
                        }));
                    } else {
                        setDemoData([...demoData, { ...values }]);
                    }
                    setOpened(false);
                }}
                onDelete={() => {
                    setDemoData(demoData.filter((item: any, index: number) => index !== selectedSubject.index));
                    setOpened(false);
                }}
            />
        </Box>
    )

}

const ModalForm = function ({
    onChange,
    opened = false,
    setOpened,
    selectedSubject,
    onDelete,
}: {
    onChange?: (value: any) => any
    onDelete?: any
    opened?: boolean
    setOpened?: any
    selectedSubject?: any
}) {

    const { t } = useTranslation();

    const addForm = useForm({
        initialValues: {
            time: '',
            subject: '',
            teacher: '',
            room: '',
        },
    });

    useEffect(() => {
        if (selectedSubject) {
            addForm.setValues({
                time: selectedSubject?.subject?.time,
                subject: selectedSubject?.subject?.subject,
                teacher: selectedSubject?.subject?.teacher,
                room: selectedSubject?.subject?.room,
            });
        }
    }, [selectedSubject]);

    return (
        <Modal
            opened={opened}
            onClose={() => setOpened && setOpened(false)}
        >
            <form
                onSubmit={addForm.onSubmit((values) => {
                    onChange && onChange(values);
                })}
            >
                {/* <TextInput label={t('الوقت')} placeholder="08:00" required
                    onChange={(e) => {
                        addForm.setFieldValue('time', e.target.value);
                    }}
                    defaultValue={selectedSubject?.subject?.time}
                /> */}
                <Flex gap="xs">
                    <TimeInput
                        label={t('وقت البداية')}
                        placeholder={t('وقت البداية')}
                        w="100%"
                        onChange={(e) => {
                            const time = addForm.values.time ?? '00:00-00:00';
                            let [start, end] = time.split('-');
                            start = e.target.value;
                            addForm.setFieldValue('time', `${start}-${end}`);
                        }}
                        defaultValue={selectedSubject?.subject?.time.split('-')[0]}
                    />
                    <TimeInput
                        label={t('وقت النهاية')}
                        placeholder={t('وقت النهاية')}
                        w="100%"
                        onChange={(e) => {
                            const time = addForm.values.time ?? '00:00-00:00';
                            let [start, end] = time.split('-');
                            end = e.target.value;
                            addForm.setFieldValue('time', `${start}-${end}`);
                        }}
                        defaultValue={selectedSubject?.subject?.time.split('-')[1]}
                    />
                </Flex>
                <TextInput label={t('المادة')} placeholder="الرياضيات" required
                    onChange={(e) => {
                        addForm.setFieldValue('subject', e.target.value);
                    }}
                    defaultValue={selectedSubject?.subject?.subject}
                />
                <TextInput label={t('التدريسي')} placeholder="أحمد" required
                    onChange={(e) => {
                        addForm.setFieldValue('teacher', e.target.value);
                    }}
                    defaultValue={selectedSubject?.subject?.teacher}
                />
                <TextInput label={t('القاعة')} placeholder="A1" required
                    onChange={(e) => {
                        addForm.setFieldValue('room', e.target.value);
                    }}
                    defaultValue={selectedSubject?.subject?.room}
                />
                <TextInput label={t('المجموعة')} placeholder="A"
                    onChange={(e) => {
                        addForm.setFieldValue('group', e.target.value);
                    }}
                    defaultValue={selectedSubject?.subject?.group}
                />
                <Flex gap="xs" mt="md">
                    <Button fullWidth onClick={() => {
                        modals.closeAll();
                    }} type="submit" size="xs">
                        {selectedSubject ? t('حفظ') : t('إضافة')}
                    </Button>
                    {
                        selectedSubject && (
                            <Button variant="outline" fullWidth onClick={() => {
                                onDelete && onDelete();
                                modals.closeAll();
                            }} color="red" size="xs">
                                {t('حذف')}
                            </Button>
                        )
                    }
                </Flex>
            </form>
        </Modal>
    )

}