import api from '../api';
import { ApiConfig, AcademicYear } from '../types';

export const academic_years = new api<AcademicYear>('/academicyears', true);

export default {
    list: (params?: any) => academic_years.list(params),
    get: (id: number) => academic_years.get(id),
    update: (id: number, data: any) => academic_years.update(id, data),
    create: (data: any) => academic_years.create(data),
    delete: (id: number) => academic_years.delete(id),
} as ApiConfig<AcademicYear>;