import { useQuery } from "@tanstack/react-query";
import useMutation from "./useMutation";
import { ListResponse, QueryResponseType } from "../api/types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { notifications } from "@mantine/notifications";
import api from "../api";

export default <T>(config: {
    key: string,
    params?: { [key: string]: string | number },
    disableQuery?: boolean,
    disableCache?: boolean,
    disableNotification?: boolean,

    // TODO: api key is deprecated, just pass the key is enough
    api?: {
        get?: (id: number) => Promise<T>,
        list?: (params?: any) => Promise<ListResponse<T[]>>,
        update?: (id: number, data: any) => Promise<T>,
        create?: (data: any) => Promise<T>,
        delete?: (id: number) => Promise<{ id: number }>,
    };
    onDone?: (action: 'update' | 'create' | 'delete', response: any) => void;
}) => {

    const { t } = useTranslation();

    // TODO: this is a hack to stop using interceptor key and 'key' as the same name in every useApi call
    const apiInterceptor = api[config.key as keyof typeof api] as {
        get?: (id: number) => Promise<T>,
        list?: (params?: any) => Promise<ListResponse<T[]>>,
        update?: (id: number, data: any) => Promise<T>,
        create?: (data: any) => Promise<T>,
        delete?: (id: number) => Promise<{ id: number }>,
    };
    config.api = apiInterceptor;

    const mutation = useMutation({
        queryKey: config.key,
        updateFn: apiInterceptor.update,
        createFn: apiInterceptor.create,
        deleteFn: apiInterceptor.delete,
        onDone: config.onDone,
        disableCache: config.disableCache,
        disableNotification: config.disableNotification,
    })

    const [params, setParams] = useState<any>(config.params || {});


    const query = useQuery(
        {
            queryKey: [config.key, params],
            queryFn: () => {
                if (config.disableQuery) {
                    return Promise.resolve({ data: [], total: 0 });
                }
                if (config.api?.list) {
                    return config.api.list({
                        // meta_key: 'surname',
                        ...params,
                    });
                }
                return Promise.resolve({ data: [], total: 0 });
            },
            retry: false,
            staleTime: config.disableCache ? 0 : 1000 * 60 * 5,
        }
    ); // QueryResponseType<ListResponse<T[]>>;

    useEffect(() => {
        if (query.isError) {
            const msg = query.error?.message || t('حدث خطأ ما');
            notifications.show({
                title: t('خطأ'),
                message: msg,
                color: 'red',
            })
        }
    }, [query.data, query.error, query.isLoading, query.isError]);

    return {
        query: query as QueryResponseType<ListResponse<T[]>>,
        self: query as QueryResponseType<T>,
        mutation,
        get: apiInterceptor.get,
        params,
        setParams,
        setParam: (key: string, value: any) => setParams({ ...params, [key]: value }),
    }

}