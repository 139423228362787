import { useTranslation } from "react-i18next";
import { Table } from "../../components";
import { Avatar, Badge, Box, Button, Chip, Flex, Grid, Group, LoadingOverlay, Menu, Text, Title } from "@mantine/core";
import { StudentMetaenumType } from "../../api/types";
import useApi from "../../hooks/useApi";
import dayjs from "dayjs";
import Importer from "./importer";
import { IconDeviceIpadQuestion, IconDotsVertical, IconEditCircle, IconFileDollar, IconIdBadge2, IconMoneybag, IconWritingSign } from "@tabler/icons-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { updateStudentPicture } from "../../api/routes/students";
import { notifications } from "@mantine/notifications";
import useUserCan from "../../hooks/useUserCan";
import { useNavigate } from "react-router-dom";
import useTakeDocs from "../../hooks/useTakeDocs";
import { compile } from "@onedoc/react-print";
import Hostrequest from "../../components/print/hostrequest";
import AdministrativeStatements from "./administrativeStatements";
import { withdrawMoney } from "../../api/routes/withdraw_money";
import ImporterDocs from "./importerDocs";
import ImporterStatements from "./importerStatements";

export default ({
    studentStatus,
    singular,
    plural,
}: {
    studentStatus?: string;
    singular: string;
    plural: string;
}) => {

    const { t, i18n } = useTranslation();
    const { userCan } = useUserCan();

    const departmentsApiInterceptor = useApi<any>({
        key: 'departments',
        params: {},
    });

    const collegeApiInterceptor = useApi<any>({
        key: 'colleges',
        params: {},
    });

    const metaenumApiInterceptor = useApi<any>({
        key: 'students_metaenum',
        params: {
            meta_key: '',
        },
    });

    const academicYearsApiInterceptor = useApi<any>({
        key: 'academic_years',
        params: {},
    });

    const navigate = useNavigate();

    const {
        takeDocs,
        takeContext
    } = useTakeDocs();

    const printHostRequest = useMemo(() => async (student: any) => {

        // promise
        return new Promise<string>((resolve, reject) => {

            compile(<Hostrequest
                student={student}
            />).then((html) => {
                const newWindow = window.open();
                if (!newWindow) return;
                newWindow.document.write(html);
                newWindow.document.title = `طلب إستضافة ${student.first_name} ${student.second_name} ${student.third_name} ${student.fourth_name}`;
                newWindow.document.close();
                newWindow.focus();
                setTimeout(() => {
                    newWindow.print();
                    setTimeout(() => {
                        newWindow.close();
                        resolve(html);
                    }, 100);
                    setTimeout(() => {
                        newWindow.close();
                        resolve(html);
                    }, 500);
                }, 200);
            });

        });

    }, []);

    return (
        <>
            {takeContext}
            <Table
                interceptor={'students'}
                singular={singular}
                plural={plural}
                drawerSize="xl"
                tableActions={(
                    userCan('create_students') && (
                        <Flex gap={'xs'}>
                            <Importer />
                            <ImporterDocs />
                            <ImporterStatements />
                        </Flex>
                    )
                )}
                params={{
                    // student_status: studentStatus,
                    ...(studentStatus ? { student_status: studentStatus } : {}),
                }}
                enableGlobalSearch
                onCreateItem={(student: any) => {
                    if (student.study_type === 'evening') {
                        printHostRequest(student);
                    }
                    takeDocs(student);
                }}
                // onEditItem={(student: any) => {
                //     printHostRequest(student).then((html) => {
                //         printHostRequest(student);
                //     });
                // }}
                columns={[
                    {
                        accessorKey: 'profile_picture',
                        accessorFn: (student: any) => (
                            <Flex direction={'row'} align={'center'} gap={'xs'}>
                                <Menu withArrow trigger="hover" shadow="md">
                                    <Menu.Target>
                                        <Button size="compact-xs" variant="subtle" color="gray"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <IconDotsVertical />
                                        </Button>
                                    </Menu.Target>
                                    <Menu.Dropdown
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        {
                                            userCan('create_students_invoices') && (
                                                <Menu.Item
                                                    onClick={() => navigate(`/app/invoices/list?invoice_for=${student?.id}`)}
                                                    leftSection={<IconFileDollar size={16} />}
                                                >
                                                    {t('إصدار وصل')}
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            userCan('create_students_invoices') && student?.student_status === 'dropout' && (
                                                <Menu.Item
                                                    onClick={() => {
                                                        if (student?.money_withdrown === 'yes') {
                                                            withdrawMoney(student?.id, 'no').then(() => {
                                                                notifications.show({
                                                                    title: t('تم إلغاء تسليم الأجور'),
                                                                    message: t('تم إلغاء تسليم أجور الإنسحاب بنجاح'),
                                                                    color: 'red',
                                                                });
                                                                setTimeout(() => {
                                                                    window.location.reload();
                                                                }, 1000);
                                                            }).catch((error) => {
                                                                notifications.show({
                                                                    title: t('فشل إلغاء تسليم الأجور'),
                                                                    message: error.message,
                                                                    color: 'red',
                                                                });
                                                            });
                                                        } else {
                                                            withdrawMoney(student?.id, 'yes').then(() => {
                                                                notifications.show({
                                                                    title: t('تم تسليم الأجور'),
                                                                    message: t('تم تسليم أجور الإنسحاب بنجاح'),
                                                                    color: 'teal',
                                                                });
                                                                setTimeout(() => {
                                                                    window.location.reload();
                                                                }, 1000);
                                                            }).catch((error) => {
                                                                notifications.show({
                                                                    title: t('فشل تسليم الأجور'),
                                                                    message: error.message,
                                                                    color: 'red',
                                                                });
                                                            });
                                                        }
                                                    }}
                                                    leftSection={<IconMoneybag size={16} />}
                                                >
                                                    {
                                                        student?.money_withdrown === 'yes' ? t('إلغاء تسليج أجور الإنسحاب') : t('تسليم أجور الإنسحاب')
                                                    }
                                                </Menu.Item>
                                            )
                                        }
                                        {
                                            userCan('edit_students') && (
                                                <Menu.Item
                                                    onClick={() => takeDocs(student)}
                                                    leftSection={<IconIdBadge2 size={16} />}
                                                >
                                                    {t('إستلام الوثائق القانونية')}
                                                </Menu.Item>
                                            )
                                        }
                                        {/* {
                                            userCan('edit_students') && (
                                                <Menu.Item
                                                    onClick={() => printHostRequest(student)}
                                                    leftSection={<IconWritingSign size={16} />}
                                                >
                                                    {t('طباعة التعهد الدراسي')}
                                                </Menu.Item>
                                            )
                                        } */}
                                        {
                                            userCan('edit_students') && student?.study_type === 'evening' && (
                                                <Menu.Item
                                                    onClick={() => printHostRequest(student)}
                                                    leftSection={<IconDeviceIpadQuestion size={16} />}
                                                >
                                                    {t('طلب إستضافة إلى الدراسة الصباحية')}
                                                </Menu.Item>
                                            )
                                        }
                                    </Menu.Dropdown>
                                </Menu>
                                <Avatar src={student?.profile_picture?.sizes?.thumbnail} />
                            </Flex>
                        ),
                        header: '',
                        size: 50,
                        minSize: 50,
                        enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                    {
                        accessorKey: 'first_name',
                        header: t('الاسم'),
                    },
                    {
                        accessorKey: 'second_name',
                        header: t('الأب'),
                    },
                    {
                        accessorKey: 'third_name',
                        header: t('الجد'),
                    },
                    {
                        accessorKey: 'fourth_name',
                        header: t('الجد الثاني'),
                    },
                    {
                        accessorKey: 'account_status',
                        accessorFn: ({ account_status }: { account_status: string }) => account_status === 'active' ? <Badge color="teal">{t('نشط')}</Badge> : <Badge color="red">{t('موقف')}</Badge>,
                        header: t('حالة الحساب'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: [
                                {
                                    label: t('نشط'),
                                    value: '$active'
                                },
                                {
                                    label: t('موقف'),
                                    value: '$suspended'
                                }
                            ]
                        }),
                    },
                    {
                        accessorKey: 'student_status',
                        accessorFn: ({ student_status }: { student_status: string }) => student_status === 'continuing' ? <Badge color="teal">{t('مستمر')}</Badge> : student_status === 'graduated' ? <Badge color="purple">{t('متخرج')}</Badge> : student_status === 'dropout' ? <Badge color="red">{t('منسحب')}</Badge> : student_status === 'pending' ? <Badge color="orange">{t('معلق (غير مفعل)')}</Badge> : student_status === 'eligible' ? <Badge color="orange">{t('مؤهل (غير مفعل)')}</Badge> : student_status === 'suspended' ? <Badge color="red">{t('غير مستمر')}</Badge> : student_status,
                        header: t('حالة الطالب'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: [
                                {
                                    label: t('مستمر'),
                                    value: '$continuing'
                                },
                                {
                                    label: t('متخرج'),
                                    value: '$graduated'
                                },
                                {
                                    label: t('منسحب'),
                                    value: '$dropout'
                                },
                                {
                                    label: t('معلق (غير مفعل)'),
                                    value: '$pending'
                                },
                                {
                                    label: t('مؤهل (غير مفعل)'),
                                    value: '$eligible'
                                },
                                {
                                    label: t('غير مستمر'),
                                    value: '$suspended'
                                }
                            ]
                        }),
                    },
                    {
                        accessorKey: 'fees_paid',
                        accessorFn: ({ fees_paid }: { fees_paid: boolean }) => fees_paid ? <Badge color="teal">{t('مدفوعة')}</Badge> : <Badge color="red">{t('غير مدفوعة')}</Badge>,
                        header: t('الأقساط'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            disabled: true,
                            // data: [
                            //     {
                            //         label: t('مدفوعة'),
                            //         value: '$true'
                            //     },
                            //     {
                            //         label: t('غير مدفوعة'),
                            //         value: '$false'
                            //     }
                            // ]
                        }),
                    },
                    {
                        accessorKey: 'surname',
                        header: t('اللقب'),
                    },
                    {
                        accessorKey: 'absence_percentage',
                        header: t('نسبة الغياب'),
                        filterVariant: 'number',
                        mantineFilterNumberRangeProps: {
                            min: 0,
                            max: 100,
                        },
                        accessorFn: ({ absence_percentage }: { absence_percentage: number }) => `${absence_percentage}%`,
                    },
                    {
                        accessorKey: 'id_number',
                        header: t('رقم الهوية'),
                    },
                    {
                        accessorKey: 'gender',
                        accessorFn: ({ gender }: { gender: string }) => gender === 'male' ? t('ذكر') : gender === 'female' ? t('أنثى') : gender,
                        header: t('الجنس'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: [
                                {
                                    label: t('ذكر'),
                                    value: '$male'
                                },
                                {
                                    label: t('أنثى'),
                                    value: '$female'
                                }
                            ]
                        }),
                    },
                    {
                        accessorKey: 'religion',
                        accessorFn: ({ religion }: { religion: StudentMetaenumType }) => religion?.meta_value || '',
                        header: t('الديانة'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (metaenumApiInterceptor.query.data?.data as any)
                                ?.filter((item: any) => item.meta_key === 'religion')
                                ?.map((item: any) => ({
                                    value: String(item.id),
                                    label: item.meta_value,
                                })) || [],
                            searchable: true,
                            onDropdownOpen: () => metaenumApiInterceptor.setParams({ meta_key: 'religion' }),
                            onSearchChange: (value: string) => {
                                metaenumApiInterceptor.setParams({
                                    meta_key: 'religion',
                                    search: value,
                                });
                            },
                        }),
                    },
                    {
                        accessorKey: 'birth_place',
                        header: t('محل الميلاد'),
                    },
                    {
                        accessorKey: 'birth_date',
                        header: t('تاريخ الميلاد'),
                        filterVariant: 'date',
                        mantineFilterDateInputProps: {
                            valueFormat: 'YYYY-MM-DD',
                            locale: i18n.language,
                            clearable: true,
                            dateParser: (value: string) => dayjs(value).toDate(),
                        }
                    },
                    {
                        accessorKey: 'legal_guardian',
                        accessorFn: ({ legal_guardian }: { legal_guardian: StudentMetaenumType }) => legal_guardian?.meta_value || '',
                        header: t('ولي الأمر'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (metaenumApiInterceptor.query.data?.data as any)
                                ?.filter((item: any) => item.meta_key === 'legal_guardian')
                                ?.map((item: any) => ({
                                    value: String(item.id),
                                    label: item.meta_value,
                                })) || [],
                            searchable: true,
                            onDropdownOpen: () => metaenumApiInterceptor.setParams({ meta_key: 'legal_guardian' }),
                            onSearchChange: (value: string) => {
                                metaenumApiInterceptor.setParams({
                                    meta_key: 'legal_guardian',
                                    search: value,
                                });
                            },
                        }),
                    },
                    {
                        accessorKey: 'email',
                        header: t('البريد الالكتروني'),
                    },
                    {
                        accessorKey: 'phone',
                        header: t('رقم الهاتف'),
                    },
                    {
                        accessorKey: 'legal_guardian_phone',
                        header: t('رقم هاتف ولي الأمر'),
                    },
                    {
                        accessorKey: 'college_id',
                        accessorFn: ({ college }: { college: any }) => `${college.name}`,
                        header: t('الكلية'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (collegeApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                collegeApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'department_id',
                        accessorFn: ({ department }: { department: any }) => `${department.name}`,
                        header: t('القسم'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (departmentsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                departmentsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'stage',
                        header: t('المرحلة'),
                    },
                    {
                        accessorKey: 'registration_academic_year',
                        accessorFn: ({ registration_academic_year }: { registration_academic_year: any }) => registration_academic_year.name,
                        header: t('سنة القبول'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (academicYearsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                academicYearsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'host_academic_year',
                        accessorFn: ({ host_academic_year }: { host_academic_year: any }) => host_academic_year?.name,
                        header: t('سنة التسكين'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (academicYearsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                academicYearsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'study_type',
                        accessorFn: ({ study_type }: { study_type: string }) => study_type === 'morning' ? t('صباحي') : study_type === 'evening' ? t('مسائي') : study_type,
                        header: t('الدراسة'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: [
                                {
                                    label: t('صباحي'),
                                    value: '$morning'
                                },
                                {
                                    label: t('مسائي'),
                                    value: '$evening'
                                }
                            ]
                        }),
                    },
                    {
                        accessorKey: 'registration_channel',
                        accessorFn: ({ registration_channel }: { registration_channel: StudentMetaenumType }) => registration_channel?.meta_value || '',
                        header: t('قناة القبول'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (metaenumApiInterceptor.query.data?.data as any)
                                ?.filter((item: any) => item.meta_key === 'registration_channel')
                                ?.map((item: any) => ({
                                    value: String(item.id),
                                    label: item.meta_value,
                                })) || [],
                            searchable: true,
                            onDropdownOpen: () => metaenumApiInterceptor.setParams({ meta_key: 'registration_channel' }),
                            onSearchChange: (value: string) => {
                                metaenumApiInterceptor.setParams({
                                    meta_key: 'registration_channel',
                                    search: value,
                                });
                            },
                        }),
                    },
                    {
                        accessorKey: 'registration_gate',
                        accessorFn: ({ registration_gate }: { registration_gate: StudentMetaenumType }) => registration_gate?.meta_value || '',
                        header: t('بوابة القبول'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (metaenumApiInterceptor.query.data?.data as any)
                                ?.filter((item: any) => item.meta_key === 'registration_gate')
                                ?.map((item: any) => ({
                                    value: String(item.id),
                                    label: item.meta_value,
                                })) || [],
                            searchable: true,
                            onDropdownOpen: () => metaenumApiInterceptor.setParams({ meta_key: 'registration_gate' }),
                            onSearchChange: (value: string) => {
                                metaenumApiInterceptor.setParams({
                                    meta_key: 'registration_gate',
                                    search: value,
                                });
                            },
                        }),
                    },
                    {
                        accessorKey: 'legal_guardian_name',
                        header: t('أسم ولي الأمر'),
                    },
                    {
                        accessorKey: 'legal_guardian_work',
                        header: t('عمل ولي الأمر'),
                    },
                    {
                        accessorKey: 'mother_name',
                        header: t('أسم الأم'),
                    },
                    {
                        accessorKey: 'mother_work',
                        header: t('عمل الأم'),
                    },
                    {
                        accessorKey: 'work',
                        accessorFn: ({ work }: { work: StudentMetaenumType }) => work?.meta_value || '',
                        header: t('عمل الطالب'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (metaenumApiInterceptor.query.data?.data as any)
                                ?.filter((item: any) => item.meta_key === 'work')
                                ?.map((item: any) => ({
                                    value: String(item.id),
                                    label: item.meta_value,
                                })) || [],
                            searchable: true,
                            onDropdownOpen: () => metaenumApiInterceptor.setParams({ meta_key: 'work' }),
                            onSearchChange: (value: string) => {
                                metaenumApiInterceptor.setParams({
                                    meta_key: 'work',
                                    search: value,
                                });
                            },
                        }),
                    },
                    {
                        accessorKey: 'previous_address',
                        header: t('عنوان السكن السابق'),
                    },
                    {
                        accessorKey: 'current_address',
                        header: t('عنوان السكن الحالي'),
                    },
                    {
                        accessorKey: 'nationality',
                        accessorFn: ({ nationality }: { nationality: StudentMetaenumType }) => nationality?.meta_value || '',
                        header: t('الجنسية'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (metaenumApiInterceptor.query.data?.data as any)
                                ?.filter((item: any) => item.meta_key === 'nationality')
                                ?.map((item: any) => ({
                                    value: String(item.id),
                                    label: item.meta_value,
                                })) || [],
                            searchable: true,
                            onDropdownOpen: () => metaenumApiInterceptor.setParams({ meta_key: 'nationality' }),
                            onSearchChange: (value: string) => {
                                metaenumApiInterceptor.setParams({
                                    meta_key: 'nationality',
                                    search: value,
                                });
                            },
                        }),
                    }
                ]}
                customFields={(form) => {
                    const values = form?.values;
                    if (!values) return null;
                    const accountStatus = values.account_status;
                    const studentStatus = values.student_status;
                    if (!accountStatus) {
                        form.setFieldValue('account_status', 'active');
                    }
                    if (!studentStatus) {
                        form.setFieldValue('student_status', 'pending');
                    }
                    const accountStatusValue = values.account_status;
                    const studentStatusValue = values.student_status;
                    const moneyWithdrown = values.money_withdrown;
                    return (
                        <Flex gap={'md'} direction="column">
                            <Flex gap={'md'} align="center">
                                <ProfilePicture student={values} />
                                <Grid gutter={'xs'}>
                                    <Grid.Col span={12}>
                                        <Flex align="center" gap="xs">
                                            <Text size={'xs'}>{t('حالة الحساب')}</Text>
                                            <Chip.Group value={accountStatusValue} onChange={(value) => form.setFieldValue('account_status', value)}>
                                                <Group gap={'xs'}>
                                                    <Chip value="active" color="teal" size="xs"
                                                    // onClick={() => form.setFieldValue('account_status', 'active')}
                                                    // checked={accountStatusValue === 'active'}
                                                    >
                                                        {t('نشط')}
                                                    </Chip>
                                                    <Chip value="suspended" color="red" size="xs"
                                                    // onClick={() => form.setFieldValue('account_status', 'suspended')}
                                                    // checked={accountStatusValue === 'suspended'}
                                                    >
                                                        {t('موقف')}
                                                    </Chip>
                                                </Group>
                                            </Chip.Group>
                                        </Flex>
                                    </Grid.Col>
                                    <Grid.Col span={12}>
                                        <Flex align="center" gap="xs">
                                            <Text size={'xs'}>{t('حالة الطالب')}</Text>
                                            <Chip.Group value={studentStatusValue} onChange={(value) => {
                                                form.setFieldValue('student_status', value);
                                            }}>
                                                <Group gap={'xs'}>
                                                    <Chip value="pending" color="orange" size="xs">
                                                        {t('معلق (غير مفعل)')}
                                                    </Chip>
                                                    <Chip value="eligible" color="orange" size="xs">
                                                        {t('مؤهل (غير مفعل)')}
                                                    </Chip>
                                                    <Chip value="dropout" color="red" size="xs">
                                                        {t('منسحب')}
                                                    </Chip>
                                                    <Chip value="continuing" color="teal" size="xs">
                                                        {t('مستمر')}
                                                    </Chip>
                                                    <Chip value="suspended" color="red" size="xs">
                                                        {t('غير مستمر')}
                                                    </Chip>
                                                    <Chip value="graduated" color="purple" size="xs">
                                                        {t('متخرج')}
                                                    </Chip>
                                                </Group>
                                            </Chip.Group>
                                        </Flex>
                                    </Grid.Col>
                                    {
                                        studentStatusValue === 'dropout' && (
                                            <Grid.Col span={12}>
                                                <Flex align="center" gap="xs">
                                                    <Text size={'xs'}>{t('تسلم حقوق الإنسحاب')}</Text>
                                                    <Chip.Group value={moneyWithdrown}
                                                    // onChange={(value) => form.setFieldValue('money_withdrown', value)}
                                                    >
                                                        <Group gap={'xs'}>
                                                            <Chip value="yes" color="teal" size="xs">
                                                                {t('نعم')}
                                                            </Chip>
                                                            <Chip value="no" color="red" size="xs">
                                                                {t('كلا')}
                                                            </Chip>
                                                        </Group>
                                                    </Chip.Group>
                                                </Flex>
                                            </Grid.Col>
                                        )
                                    }
                                </Grid>
                            </Flex>
                            <Title order={3}>
                                {values?.id ? values.first_name + ' ' + values.second_name + ' ' + values.third_name + ' ' + values.fourth_name : ''}
                            </Title>
                        </Flex>
                    );
                }}
                fieldsFooter={(form) => {
                    return Boolean(form.values.id)
                        && userCan('read_administrative_statements')
                        && <AdministrativeStatements student_id={form.values.id} />;
                }}
                fields={[
                    {
                        type: 'divider',
                        name: 'divider',
                        label: t('المعلومات المطلوبة'),
                        widthRatio: 1,
                    },
                    {
                        type: 'text',
                        label: t('الاسم'),
                        name: 'first_name',
                        required: true,
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('أسم الأب'),
                        name: 'second_name',
                        required: true,
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('أسم الجد'),
                        name: 'third_name',
                        required: true,
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('أسم الجد الثاني'),
                        name: 'fourth_name',
                        required: true,
                        widthRatio: .3,
                    },
                    {
                        type: 'select',
                        label: t('الجنس'),
                        name: 'gender',
                        required: true,
                        initialData: () => ([
                            {
                                label: t('ذكر'),
                                value: 'male',
                            },
                            {
                                label: t('أنثى'),
                                value: 'female',
                            },
                        ]),
                        widthRatio: .2,
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                    },
                    {
                        type: 'text',
                        label: t('رقم الهاتف'),
                        name: 'phone',
                        required: true,
                        widthRatio: .4,
                    },
                    {
                        type: 'select',
                        label: t('القسم'),
                        name: 'department_id',
                        params: {
                            limit: 9999,
                        },
                        labelAccessor(item) {
                            // `${department.name} (${department.college.name})`
                            let departmentName = item.name;
                            // replace 'قسم' with ''
                            departmentName = departmentName.replace('قسم ', '');

                            let collegeName = item.college.name;
                            // replace 'كلية' with ''
                            collegeName = collegeName.replace('كلية ', '');

                            return `${departmentName} (${collegeName})`;
                        },
                        initialValue: item => item?.department?.id,
                        required: true,
                        interceptor: 'departments',
                        widthRatio: .5,
                    },
                    {
                        type: 'select',
                        label: t('الدراسة'),
                        name: 'study_type',
                        required: true,
                        initialValue: (item) => item?.study_type,
                        initialData(item) {
                            return [
                                {
                                    value: 'morning',
                                    label: t('صباحي'),
                                },
                                {
                                    value: 'evening',
                                    label: t('مسائي'),
                                },
                            ];
                        },
                        widthRatio: .3,
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                    },
                    {
                        type: 'select',
                        label: t('لديه معهد'),
                        name: 'instituteـstudent',
                        required: true,
                        initialValue: (item) => item?.instituteـstudent === 'yes' ? 'yes' : 'no',
                        initialData(item) {
                            return [
                                {
                                    value: 'no',
                                    label: t('كلا'),
                                },
                                {
                                    value: 'yes',
                                    label: t('نعم'),
                                },
                            ];
                        },
                        widthRatio: .3,
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                    },
                    {
                        type: 'select',
                        label: t('سنة القبول'),
                        name: 'registration_academic_year',
                        required: true,
                        interceptor: 'academic_years',
                        initialValue: item => item?.registration_academic_year?.id,
                        widthRatio: .3,
                    },
                    {
                        type: 'select',
                        label: t('قناة القبول'),
                        name: 'registration_channel',
                        required: true,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'registration_channel',
                        },
                        initialValue: item => item?.registration_channel?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .3,
                    },
                    {
                        type: 'select',
                        label: t('بوابة القبول'),
                        name: 'registration_gate',
                        required: true,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'registration_gate',
                        },
                        initialValue: item => item?.registration_gate?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('رقم الهوية'),
                        name: 'id_number',
                        required: true,
                        widthRatio: .3,
                    },
                    {
                        type: 'date',
                        label: t('تاريخ الميلاد'),
                        name: 'birth_date',
                        required: true,
                        widthRatio: .2,
                    },





                    {
                        type: 'divider',
                        name: 'divider',
                        label: t('المعلومات الشخصية'),
                        widthRatio: 1,
                    },
                    {
                        type: 'text',
                        label: t('اللقب'),
                        name: 'surname',
                        required: false,
                        widthRatio: .2,
                    },
                    {
                        type: 'select',
                        label: t('الديانة'),
                        name: 'religion',
                        required: false,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'religion',
                        },
                        initialValue: item => item?.religion?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .2,
                    },
                    {
                        type: 'text',
                        label: t('محل الميلاد'),
                        name: 'birth_place',
                        required: false,
                        widthRatio: .2,
                    },
                    {
                        type: 'select',
                        label: t('ولي الأمر'),
                        name: 'legal_guardian',
                        required: false,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'legal_guardian',
                        },
                        initialValue: item => item?.legal_guardian?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .2,
                    },


                    {
                        type: 'divider',
                        name: 'divider',
                        label: t('معلومات التواصل'),
                        widthRatio: 1,
                    },
                    {
                        type: 'text',
                        label: t('البريد الالكتروني'),
                        name: 'email',
                        required: false,
                        widthRatio: .4,
                    },
                    {
                        type: 'text',
                        label: t('رقم هاتف ولي الأمر'),
                        name: 'legal_guardian_phone',
                        required: false,
                        widthRatio: .4,
                    },



                    {
                        type: 'divider',
                        name: 'divider',
                        label: t('المعلومات الأكاديمية'),
                        widthRatio: 1,
                    },
                    // stage
                    {
                        type: 'number',
                        label: t('المرحلة'),
                        name: 'stage',
                        required: false,
                        minNumber: 1,
                        maxNumber: 5,
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('تخصص المعهد المناظر'),
                        name: 'instituteـdepartment',
                        required: false,
                        widthRatio: .3,
                    },
                    {
                        type: 'select',
                        label: t('سنة التسكين - نقل'),
                        name: 'host_academic_year',
                        required: false,
                        interceptor: 'academic_years',
                        initialValue: item => item?.host_academic_year?.id,
                        widthRatio: .3,
                    },

                    {
                        type: 'divider',
                        name: 'divider',
                        label: t('معلومات إضافية'),
                        widthRatio: 1,
                    },
                    {
                        type: 'text',
                        label: t('أسم ولي الأمر'),
                        name: 'legal_guardian_name',
                        required: false,
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('عمل ولي الأمر'),
                        name: 'legal_guardian_work',
                        required: false,
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('أسم الأم'),
                        name: 'mother_name',
                        required: false,
                        widthRatio: .3,
                    },
                    {
                        type: 'text',
                        label: t('عمل الأم'),
                        name: 'mother_work',
                        required: false,
                        widthRatio: .3,
                    },
                    {
                        type: 'select',
                        label: t('عمل الطالب'),
                        name: 'work',
                        required: false,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'work',
                        },
                        initialValue: item => item?.work?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .4,
                    },
                    {
                        type: 'select',
                        label: t('نتيجة الطالب للعام الحالي'),
                        name: 'current_result',
                        required: false,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'current_result',
                        },
                        initialValue: item => item?.current_result?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .4,
                    },
                    {
                        type: 'select',
                        label: t('المحافظة'),
                        name: 'province',
                        required: false,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'province',
                        },
                        initialValue: item => item?.province?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .2,
                    },
                    {
                        type: 'text',
                        label: t('عنوان السكن الحالي'),
                        name: 'current_address',
                        required: false,
                        widthRatio: .4,
                    },
                    {
                        type: 'text',
                        label: t('عنوان السكن السابق'),
                        name: 'previous_address',
                        required: false,
                        widthRatio: .4,
                    },
                    {
                        type: 'select',
                        label: t('الجنسية'),
                        name: 'nationality',
                        required: false,
                        interceptor: 'students_metaenum',
                        params: {
                            meta_key: 'nationality',
                        },
                        initialValue: item => item?.nationality?.id,
                        labelAccessor: 'meta_value',
                        widthRatio: .2,
                    },
                    {
                        type: 'textarea',
                        label: t('الملاحظات'),
                        name: 'notes',
                        required: false,
                    },
                ]}
            />
        </>
    );

}

export const ProfilePicture = ({
    student,
}: {
    student: any;
}) => {

    const [loading, setLoading] = useState(false);
    const [hoverAvatar, setHoverAvatar] = useState<boolean>(false);
    const profilePictureRef = useRef<HTMLInputElement>(null);
    const [studentObject, setStudentObject] = useState<any>(student);
    useEffect(() => {
        setStudentObject(student);
    }, [student]);
    const { t } = useTranslation();

    return (
        <Flex
            style={{
                position: 'relative',
            }}
        >
            <input type="file" accept="image/*" style={{ display: 'none' }} ref={profilePictureRef} id="picture" onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                    const file = e.target.files[0];
                    setLoading(true);

                    updateStudentPicture({
                        student_id: studentObject.id,
                        picture: file,
                    }).then((res) => {
                        setStudentObject(res);
                        notifications.show({
                            title: 'Success',
                            message: t('تم تحديث الصورة بنجاح'),
                            color: 'teal',
                        });
                    }).catch((err) => {
                        notifications.show({
                            title: 'Error',
                            message: err.message,
                            color: 'red',
                        });
                    }).finally(() => {
                        setLoading(false);
                    });
                }
            }} />
            <Avatar
                src={studentObject?.profile_picture?.sizes?.thumbnail}
                size={100}
                radius={999}
                color="blue"
                onMouseEnter={() => setHoverAvatar(true)}
                onMouseLeave={() => setHoverAvatar(false)}
            ></Avatar>
            {
                loading ? (
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 999,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                        }}
                    >
                        <LoadingOverlay visible={true} />
                    </Box>
                ) : (
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 999,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            opacity: hoverAvatar ? 1 : 0,
                            transition: 'opacity 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseEnter={() => setHoverAvatar(true)}
                        onMouseLeave={() => setHoverAvatar(false)}
                        onClick={() => {
                            if (profilePictureRef.current) {
                                profilePictureRef.current.click();
                            }
                        }}
                    >
                        <IconEditCircle size={30} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} color="white" />
                    </Box>
                )
            }
        </Flex>
    );

};