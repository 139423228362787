import { apiWithInterceptor } from '.';

export const sendBulkNotification = async (data: {
    title: string,
    message: string,
    link?: string,
    department_id?: number,
    stage?: number,
}): Promise<any> => apiWithInterceptor.post(`/notifications/student/send/bulk`, data);

export const sendPersonalizedNotification = async (data: {
    title: string,
    message: string,
    link?: string,
    student_id: number,
}): Promise<any> => apiWithInterceptor.post(`/notifications/student/send`, data);