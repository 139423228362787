import {
    type MRT_VisibilityState,
    MantineReactTable,
    useMantineReactTable,
    MRT_ColumnOrderState,
} from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { MRT_Localization_AR } from 'mantine-react-table/locales/ar';
import { useHotkeys, useInterval, useLocalStorage } from '@mantine/hooks';
import useApi from '../../hooks/useApi';
import './style.css';
import useModal from './useModal';
import { Anchor, Badge, Box, Button, Flex, Kbd, Loader, LoadingOverlay, MantineSize, Progress, RingProgress, Text, TextInput, Title, Tooltip } from '@mantine/core';
import useUserCan from '../../hooks/useUserCan';
import { IconPlus } from '@tabler/icons-react';
import { FieldType } from './fieldType';
import useExport from '../../hooks/useExport';

export default ({
    interceptor,
    params,
    singular,
    plural,
    columns,
    fields,
    customFields,
    beforeTable,
    disableCache,
    drawerSize,
    disableAdd = false,
    disableEdit = false,
    enableGlobalSearch = false,
    onRowClick,
    onCreateItem,
    onEditItem,
    tableActions,
    fieldsFooter,
}: {
    interceptor: string,
    params?: { [key: string]: string | number },
    singular?: string,
    plural?: string,
    columns: any,
    fields?: FieldType[],
    customFields?: (form: any, itemData: any) => React.ReactNode,
    beforeTable?: React.ReactNode,
    disableCache?: boolean,
    drawerSize?: MantineSize | (string & {}) | number,
    disableAdd?: boolean
    disableEdit?: boolean
    enableGlobalSearch?: boolean
    onRowClick?: (item: any) => void
    onCreateItem?: (item: any) => void
    onEditItem?: (item: any) => void
    tableActions?: React.ReactNode,
    fieldsFooter?: (form: any, itemData: any) => React.ReactNode,
}) => {

    const { t } = useTranslation();

    const { userCan } = useUserCan();

    const apiInterceptor = useApi<any>({
        key: interceptor,
        params: { ...params },
        disableCache,
    });

    useEffect(() => {
        apiInterceptor.setParams((curr: any) => {
            return {
                ...curr,
                ...params,
            }
        });
    }, [params]);

    const [sorting, setSorting] = useState([]);
    const [filters, setFilters] = useState([]);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        pageIndex: 0,
    });

    useEffect(() => {
        if (sorting.length === 0) {
            apiInterceptor.setParams((params: any) => {
                delete params.order_by;
                delete params.order;
                return params;
            });
            return;
        }
        const sort = sorting[0] as {
            id: string,
            desc: boolean,
        };
        apiInterceptor.setParams({
            ...apiInterceptor.params,
            order_by: sort.id,
            order: sort.desc ? 'DESC' : 'ASC',
        });
    }, [sorting]);

    useEffect(() => {
        if (filters.length === 0) {
            apiInterceptor.setParams((params: any) => {
                delete params.filters;
                return params;
            });
            return;
        }
        apiInterceptor.setParams({
            ...apiInterceptor.params,
            filters: filters.map((filter: any) => ({
                id: filter.id,
                value: filter.value,
            })) as any,
        });
    }, [filters]);

    useEffect(() => {
        apiInterceptor.setParams({
            ...apiInterceptor.params,
            limit: pagination.pageSize,
            offset: pagination.pageIndex * pagination.pageSize,
        });
    }, [pagination]);

    const [columnVisibility, setColumnVisibility] = useLocalStorage<MRT_VisibilityState>({
        key: `${interceptor}-columns-visibility`,
        defaultValue: {},
    });

    const [columnOrder, setColumnOrder] = useLocalStorage<MRT_ColumnOrderState>({
        key: `${interceptor}-columns-order`,
        defaultValue: [],
    });

    const modal = useModal({
        intercaptor: interceptor,
        fields: fields,
        customFields,
        fieldsFooter,
        size: drawerSize,
        disableCache,
        onCreateItem,
        onEditItem,
    });

    const exportInterceptor = useExport({
        key: interceptor,
        // columns,
    });

    const [seconds, setSeconds] = useState(0);
    const interval = useInterval(() => setSeconds((s) => s + 1), 1000);

    useEffect(() => {
        interval.start();
        return interval.stop;
      }, []);
    

    const table = useMantineReactTable({
        data: apiInterceptor.query.data?.data as any || [],
        paginationDisplayMode: 'pages',
        manualPagination: true,
        rowCount: apiInterceptor.query.data?.total || undefined,
        columns: columns,
        mantineSearchTextInputProps: {
            placeholder: t('بحث'),
            onInput: (e) => {
                apiInterceptor.setParam('search', e.currentTarget.value);
            }
        },
        state: {
            isLoading: apiInterceptor.query.isLoading || apiInterceptor.query.isFetching,
            sorting: sorting,
            columnVisibility,
            columnOrder,
            pagination,
        },
        onSortingChange: setSorting as any,
        onColumnFiltersChange: setFilters as any,
        enableColumnOrdering: true,
        onColumnOrderChange: (state: any) => {
            if (seconds < 3) return;
            setColumnOrder(state);
        },
        enableColumnDragging: false,
        localization: MRT_Localization_AR,
        initialState: {
            columnVisibility,
            columnOrder,
            pagination,
        },
        onColumnVisibilityChange: (state: any) => {
            if (typeof state !== 'function') return;
            setColumnVisibility(state);
        },
        onPaginationChange: setPagination,
        mantineTableBodyRowProps: ({ row }: any) => ({
            onClick: () => {
                if (onRowClick) {
                    onRowClick(row.original);
                }
                if (disableEdit) return;
                if (userCan(`edit_${interceptor}`)) {
                    modal.open({
                        item: row.original,
                        title: t('تعديل') + ' ' + singular,
                    });
                }
            },
        }),
        // styles
        mantinePaperProps: {
            style: {
                borderRadius: 0,
                border: 0,
                boxShadow: 'none',
            },
        },
        mantineTableBodyProps: {
            style: {
                cursor: 'pointer',
            },
        },
        sortDescFirst: true,
    });

    useEffect(() => {
        table.setColumnVisibility(columnVisibility);
    }, [columnVisibility]);

    // useEffect(() => {
    //     if (columnOrder.length === 0) return;
    //     table.setColumnOrder(columnOrder);
    // }, [columnOrder]);

    // on press shift + n open modal
    // modal.open({
    //     title: t('إضافة') + ' ' + singular,
    // })
    useHotkeys([
        ['shift+n', () => {
            if (userCan(`create_${interceptor}`)) {
                modal.open({
                    title: t('إضافة') + ' ' + singular,
                });
            }
        }],
    ]);

    return (
        <>
            <Flex justify="space-between" p="md">
                <Title order={4}>{plural}</Title>
                <Flex gap="xs">
                    {tableActions}
                    {
                        !disableAdd && userCan(`create_${interceptor}`) && (
                            <Tooltip
                                label={(
                                    <Box pb={5}><Kbd>Shift</Kbd> + <Kbd>N</Kbd></Box>
                                )}
                                style={{
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    direction: 'ltr',
                                }}
                            >
                                <Button
                                    size={'xs'}
                                    onClick={() => modal.open({
                                        title: t('إضافة') + ' ' + singular,
                                    })}
                                    leftSection={<IconPlus size={16} />}
                                >
                                    {t('إضافة')} {singular}
                                </Button>
                            </Tooltip>
                        )
                    }
                </Flex>
            </Flex>
            <Flex px="md" gap="xs" align="center">
                <Text size="xs" color="gray">
                    {t('تم العثور على')} {apiInterceptor.query.data?.total || 0} {t('نتيجة')}
                </Text>
                <Badge
                    size="md"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        exportInterceptor.startExport(apiInterceptor.params);
                    }}
                    color={"secondary"}
                >
                    {t('تصدير')}
                </Badge>
            </Flex>
            <Box px="md">
                {beforeTable}
            </Box>
            {
                enableGlobalSearch && (
                    <TextInput
                        placeholder={t('بحث')}
                        onChange={(e) => {
                            apiInterceptor.setParam('search', e.currentTarget.value);
                        }}
                        size="xs"
                        mx="md"
                        my="xs"
                    />
                )
            }
            <MantineReactTable
                table={table}
            />
            {modal.contextHolder}
            <LoadingOverlay
                visible={exportInterceptor.exportActive}
                overlayProps={{ radius: 'sm', blur: 2 }}
                loaderProps={{
                    children: (
                        <RingProgress
                            sections={[{ value: exportInterceptor.progress, color: 'secondary' }]}
                            label={
                                <Text c="secondary" fw={700} ta="center" size="xl">
                                    {exportInterceptor.progress}%
                                </Text>
                            }
                        />
                    )
                }}
            />
        </>
    )

}