import api from '../api';
import { ApiConfig, StudentInvoiceType } from '../types';

export const studentsunpaidinvoices = new api<StudentInvoiceType>('/tuitionfees/invoices/unpaid', true);

export default {
    list: (params?: any) => studentsunpaidinvoices.list(params),
    get: (id: number) => studentsunpaidinvoices.get(id),
    update: (id: number, data: any) => studentsunpaidinvoices.update(id, data),
    create: (data: any) => studentsunpaidinvoices.create(data),
    delete: (id: number) => studentsunpaidinvoices.delete(id),
} as ApiConfig<StudentInvoiceType>;