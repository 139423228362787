import { useMutation as useMutationRQ, useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { notifications } from "@mantine/notifications";

type mutationParams = {
    action: 'update' | 'create' | 'delete';
    id?: number;
    values?: any;
};

export default function useMutation({
    queryKey,
    updateFn,
    createFn,
    deleteFn,

    onDone,
    disableCache,
    disableNotification,
}: {
    queryKey: any;
    updateFn?: (id: number, values: any) => any;
    createFn?: (values: any) => any;
    deleteFn?: (id: number) => any;

    onDone?: ( action: mutationParams['action'], response: any ) => void;
    disableCache?: boolean;
    disableNotification?: boolean;
}) {
    const queryClient = useQueryClient()
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState<'update' | 'create' | 'delete' | null>(null);
    const id = useMemo(() => Math.random().toString(36).substring(2, 15), []);

    const mutation = useMutationRQ({
        mutationFn: async (values: mutationParams) => {
            let res = null;
            setLoading(true);
            switch (values.action) {
                case 'update':
                    if (values.id && updateFn) {
                        !disableNotification && notifications.show({
                            id: id,
                            title: t('جاري التحديث'),
                            message: '',
                            color: 'blue',
                            loading: true,
                            autoClose: false,
                        });
                        setAction('update');
                        res = updateFn(values.id, values.values);
                    }
                    break;
                case 'create':
                    if (createFn) {
                        !disableNotification && notifications.show({
                            id: id,
                            title: t('جاري الإنشاء'),
                            message: '',
                            color: 'blue',
                            loading: true,
                            autoClose: false,
                        });
                        setAction('create');
                        res = createFn(values.values);
                    }
                    break;
                case 'delete':
                    if (values.id && deleteFn) {
                        !disableNotification && notifications.show({
                            id: id,
                            title: t('جاري الحذف'),
                            message: '',
                            color: 'blue',
                            loading: true,
                            autoClose: false,
                        });
                        setAction('delete');
                        res = deleteFn(values.id);
                    }
                    break;
            }
            return res;
        },
        onSuccess: (data: any) => {            

            const key = Array.isArray(queryKey) ? queryKey[0] : queryKey;
            
            const queries = queryClient.getQueryCache().findAll(key).filter((query: any) => {
                const queryKey = Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey;
                return queryKey === key;
            });

            queries.forEach((query: any) => {
                
                if (disableCache) {
                    queryClient.invalidateQueries(query.queryKey);
                    return;
                }
                
                queryClient.setQueryData(query.queryKey, (oldData: any) => {
                    let old = oldData as any;
                    if (!old) {
                        queryClient.invalidateQueries(queryKey);
                        return old;
                    }

                    if (action === 'create') {
                        old.data.unshift(data);
                        // queryClient.invalidateQueries(queryKey);
                        // if ( Array.isArray(old.data) ) {
                        //     old.data.unshift(data);
                        // } else if ( Array.isArray(old) ) {
                        //     old.unshift(data);
                        // } else {
                        //     console.error('Invalid data type', old, data);
                        // }
                    } else if (action === 'update') {
                        if (!data.id) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        if (old.data === undefined) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        const index = old.data.findIndex((item: any) => item.id === data.id);
                        if (index === -1) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        old.data[index] = {
                            // This is comminted because the backend API is nut returning null values
                            // in the response, so when a field get set to null in the update
                            // it will not get returned in the response, so the old value will be 
                            // kept in the data instead of null
                            // ...old.data[index],
                            ...data,
                        };
                    } else if (action === 'delete') {
                        if (!data.id) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        if (old.data === undefined) {
                            queryClient.invalidateQueries(queryKey);
                            return old;
                        }
    
                        // filter the deleted item from the data
                        old.data = old.data.filter((item: any) => item.id !== data.id);
                        
                    }

                    // this is very important to set the data to empty array
                    // and the re assinn the new data to it
                    // if you don't do that the new content will not
                    // gre re rendered and updated unless you navigate away and back
                    // this is a bug in react-query and only this trick could solve it
                    // after 5 hours of debugging :(
                    // issue on git hub: https://github.com/apollographql/react-apollo/issues/3816
                    // there are other issue on theis topic also
                    queryClient.setQueryData(query.queryKey, {
                        ...old,
                        data: [],
                    });

                    return old;

                });

            });

            setLoading(false);
            !disableNotification && notifications.update({
                id: id,
                title: t('نجاح'),
                message: t('تمت العملية بنجاح'),
                color: 'green',
                loading: false,
                autoClose: true,
            });
            onDone && onDone(action as mutationParams['action'], data);

        },

        onError: (error: any) => {
            
            setLoading(false);
            !disableNotification && notifications.update({
                id: id,
                title: t('خطأ'),
                message: error.message,
                color: 'red',
                loading: false,
                autoClose: true,
            });
            onDone && onDone( action as mutationParams['action'], error );
        },
    });

    return {
        mutation,
        loading,

        create: (values: any) => mutation.mutate({ action: 'create', values }),
        createAsync: async (values: any) => await mutation.mutateAsync({ action: 'create', values }),

        update: (id: number, values: any) => mutation.mutate({ action: 'update', id, values }),
        updateAsync: async (id: number, values: any) => await mutation.mutateAsync({ action: 'update', id, values }),

        delete: (id: number) => mutation.mutate({ action: 'delete', id }),
        deleteAsync: async (id: number) => await mutation.mutateAsync({ action: 'delete', id }),
    };
}
