import { useTranslation } from "react-i18next";
import { Button, Card, Checkbox, Flex, LoadingOverlay, Title } from "@mantine/core";
import useUserCan from "../hooks/useUserCan";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { getRegistrationSettings, updateRegistrationSettings } from "../api/routes/settings";
import { notifications } from "@mantine/notifications";

export default () => {

    const { t } = useTranslation();
    const { userCan } = useUserCan();

    return (
        <Flex p={'md'} direction={'column'} gap={'xs'}>
            <Title order={4}>{t('خيارات النظام')}</Title>
            {userCan(['registration_admin', 'administrator']) && (<RegistrationOptions />)}
        </Flex>
    );

}

const RegistrationOptions = () => {

    const { t } = useTranslation();
    const form = useForm();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getRegistrationSettings().then((response) => {
            form.setValues(response);
        }).catch((err) => {
            notifications.show({
                title: t('حدث خطأ'),
                message: err.message,
                color: 'red',
            });
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <Card withBorder>
            <Title order={5}>{t('خيارات التسجيل')}</Title>
            <form
                onSubmit={form.onSubmit((values) => {
                    setLoading(true);
                    updateRegistrationSettings(values).then((res: any) => {
                        form.setValues(res);
                    }).catch((err) => {
                        notifications.show({
                            title: t('حدث خطأ'),
                            message: err.message,
                            color: 'red',
                        });
                    }).finally(() => {
                        setLoading(false);
                    });
                })}
            >
                <Flex direction={'column'} gap={'xs'} pt={'xs'}>
                    <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                    <Checkbox
                        label={t('الوضع المقيد')}
                        description={t('تفعيل هذا الخيار سيمنع الموظفين من تعديل حقول الطالب التالية: القسم، سنة القبول، المرحلة، بوابة القبول، الدراسة، قناة القبول، حالة الطالب.')}
                        checked = {(form.values.restricted_mode as any) || false}
                        onChange={(event) => form.setFieldValue('restricted_mode', event.currentTarget.checked)}
                    />
                    <Flex justify={'end'}>
                        <Button type={'submit'}>{t('حفظ')}</Button>
                    </Flex>
                </Flex>
            </form>
        </Card>
    )

}