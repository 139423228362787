import { apiWithInterceptor, apiWithInterceptorMultipart } from '.';
import api from '../api';
import { StudentType, ApiConfig } from '../types';

export const students = new api<StudentType>('/students', true);

export default {
    list: (params?: any) => students.list(params),
    get: (id: number) => students.get(id),
    update: (id: number, data: any) => students.update(id, data),
    create: (data: any) => students.create(data),
    delete: (id: number) => students.delete(id),
} as ApiConfig<StudentType>;

export const getStudents = async (data: any): Promise<any> =>
    apiWithInterceptor.get(`/students`, {
        params: data,
    });

export const createStudent = async (data: any): Promise<any> =>
    apiWithInterceptor.post(`/students`, data);

export const updateStudent = async (id:number, data: any): Promise<any> =>
    apiWithInterceptor.put(`/students/${id}`, data);

export const updateStudentPicture = async (data: {
    student_id: number,
    picture: any,
}): Promise<any> => apiWithInterceptorMultipart.post(`/students/${data.student_id}/picture`, { picture: data.picture });