import api from '../api';
import { ApiConfig, TimetableType } from '../types';

export const students = new api<TimetableType>('/timetable', true);

export default {
    list: (params?: any) => students.list(params),
    get: (id: number) => students.get(id),
    update: (id: number, data: any) => students.update(id, data),
    create: (data: any) => students.create(data),
    delete: (id: number) => students.delete(id),
} as ApiConfig<TimetableType>;