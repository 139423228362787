import React from "react";
import { useForm } from "@mantine/form"
import { IconAt } from '@tabler/icons-react';
import { Button, Flex, Input, LoadingOverlay, PasswordInput, Space, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import logo from '../assets/img/logo-main.png';
import { login, loginGoogle } from "../api/routes/auth";
import { useNavigate } from "react-router-dom";
import { notifications } from '@mantine/notifications';
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

export default () => {

    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            email: '',
            password: ''
        },
        validate: {
            email: (value: string) => (/^\S+@\S+$/.test(value) ? null : t('البريد الالكتروني غير صحيح')),
            password: (value: string) => value.length >= 8 ? null : t('كلمة المرور يجب أن تكون 8 أحرف على الأقل')
        }
    })

    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()

    return (
        <form
            onSubmit={form.onSubmit(() => {
                setLoading(true)
                login(form.values).then((res: any) => {
                    localStorage.setItem('token', res.token)
                    navigate('/app')
                }).catch((err: any) => {
                    notifications.show({
                        title: t('خطأ'),
                        message: err.message,
                        color: 'red',
                    })
                }).finally(() => {
                    setLoading(false)
                });
            })}
        >
            <Flex
                direction="column"
                gap="sm"
                w={300}
            >
                <Flex
                    direction="column"
                    w="100%"
                    gap="xs"
                >
                    <img src={logo} alt="logo" style={{ width: 100 }} />
                    <Title order={3}>{t('جامعة النور')}</Title>
                    <Text>{t('للمواصلة في إستخدام النظام يرجى تسجيل الدخول')}</Text>
                </Flex>
                <Space h="sm" />
                <Flex pos={"relative"} direction="column" gap="sm">
                    <LoadingOverlay visible={loading} overlayProps={{ blur: 1 }} />
                    <GoogleOAuthProvider clientId="613475412555-mbd6llli7rbmcu802rsv7dfjjopo16lb.apps.googleusercontent.com">
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                const token = credentialResponse.credential;
                                if (!token) {
                                    alert(t('حدث خطأ ما أثناء تسجيل الدخول بحساب جوجل'));
                                    return;
                                }
                                setLoading(true)
                                loginGoogle({ token }).then((res: any) => {
                                    localStorage.setItem('token', res.token)
                                    navigate('/app')
                                }).catch((err: any) => {
                                    notifications.show({
                                        title: t('خطأ'),
                                        message: err.message,
                                        color: 'red',
                                    })
                                }).finally(() => {
                                    setLoading(false)
                                });
                            }}
                            onError={() => {
                                alert(t('حدث خطأ ما أثناء تسجيل الدخول بحساب جوجل'));
                            }}
                        />
                    </GoogleOAuthProvider>
                    <Input
                        placeholder={t('البريد الالكتروني')}
                        leftSection={<IconAt size={16} />}
                        {...form.getInputProps('email')}
                    />
                    <PasswordInput
                        placeholder={t('كلمة المرور')}
                        {...form.getInputProps('password')}
                    />
                    <Button type="submit">{t('تسجيل الدخول')}</Button>
                </Flex>
            </Flex>
        </form>
    )

}
