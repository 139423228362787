import { useTranslation } from "react-i18next";
import { Table } from "../components";
import { Alert, Button, Flex, LoadingOverlay, Modal, NumberFormatter, NumberInput, Select, Text } from "@mantine/core";
import useApi from "../hooks/useApi";
import { IconExclamationCircle, IconHierarchy3 } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { checkBulkDiscounts, doBulkDiscounts } from "../api/routes/studentsdiscounts";
import { notifications } from "@mantine/notifications";
import { DateInput, DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import useUserCan from "../hooks/useUserCan";

export default () => {

    const { t } = useTranslation();
    const { userCan } = useUserCan();

    const studentsApiInterceptor = useApi<any>({
        key: 'students',
        params: {},
    });

    const academicYearsApiInterceptor = useApi<any>({
        key: 'academic_years',
        params: {},
    });

    return (
        <>
            <Table
                interceptor={'students_discounts'}
                singular={t('خصم')}
                plural={t('الخصومات')}
                drawerSize={'lg'}
                tableActions={(
                    userCan('create_students_discounts') && <BulkImport />
                )}
                columns={[
                    {
                        accessorKey: 'student_id',
                        accessorFn: ({ student }: any) => (
                            <Flex
                                direction={'column'}
                            >
                                <Text size={'sm'}>{student.first_name} {student.second_name} {student.third_name} {student.fourth_name}</Text>
                                <Text size={'xs'}>{student.department.college.name} / {student.department.name} / {student.study_type === 'morning' ? t('صباحي') : t('مسائي')} / {t('المرحلة')} {student.stage}</Text>
                            </Flex>
                        ),
                        header: t('الطالب'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (studentsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: `${item.first_name} ${item.second_name} ${item.third_name} ${item.fourth_name} / ${item.department.college.name} / ${item.department.name} / ${item.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${item.stage}`,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                studentsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'academic_year_id',
                        accessorFn: ({ academic_year }: { academic_year: any }) => academic_year.name,
                        header: t('العام الدراسي'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (academicYearsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                academicYearsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'amount',
                        accessorFn: ({ amount }: any) => (
                            <Flex direction={'column'}>
                                <NumberFormatter
                                    value={amount}
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 'bold',
                                    }}
                                    suffix={t('د.ع')}
                                    thousandSeparator
                                />
                            </Flex>
                        ),
                        header: t('المبلغ'),
                    },
                    {
                        accessorKey: 'amount_used',
                        accessorFn: ({ amount_used, amount }: any) => (
                            <Flex direction={'column'}>
                                <NumberFormatter
                                    value={amount_used}
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 'bold',
                                        color: (amount - amount_used) === 0 ? 'red' : 'teal',
                                    }}
                                    suffix={t('د.ع')}
                                    thousandSeparator
                                />
                                {
                                    (amount - amount_used) === 0 ? (
                                        <Text size={'xs'} color={'red'}>{t('الخصم مستخدم بالكامل')}</Text>
                                    ) : (
                                        <Text size={'xs'} color={'teal'}>{t('الخصم المتبقي')} <NumberFormatter value={amount - amount_used} suffix={t('د.ع')} thousandSeparator /></Text>
                                    )
                                }
                            </Flex>
                        ),
                        header: t('المبلغ المستخدم'),
                    },
                    {
                        accessorKey: 'author_id',
                        accessorFn: ({ author }: any) => (
                            <Flex direction={'column'}>
                                <Text size={'sm'}>{author.name}</Text>
                                <Text size={'sm'} style={{ color: 'gray' }}>{author.email}</Text>
                            </Flex>
                        ),
                        header: t('الموظف'),
                        // enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                    {
                        accessorKey: 'created_at',
                        accessorFn: ({ created_at }: any) => (
                            <Text size={'sm'}>{created_at}</Text>
                        ),
                        header: t('تاريخ الإضافة'),
                        // enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                ]}
                fields={[
                    {
                        type: 'select',
                        label: t('الطالب'),
                        name: 'student_id',
                        params: {
                            limit: 10,
                        },
                        labelAccessor(item) {
                            return `${item.first_name} ${item.second_name} ${item.third_name} ${item.fourth_name} / ${item.department.name} / ${item.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${item.stage}`;
                        },
                        initialValue: item => item?.student?.id,
                        required: true,
                        interceptor: 'students',
                    },
                    {
                        type: 'select',
                        label: t('العام الدراسي'),
                        name: 'academic_year_id',
                        params: {
                            limit: 10,
                        },
                        labelAccessor(item) {
                            return `${item.name}`;
                        },
                        initialValue: item => item?.academic_year?.id,
                        required: true,
                        interceptor: 'academic_years',
                    },
                    {
                        type: 'number',
                        label: t('المبلغ'),
                        name: 'amount',
                        initialValue: item => item?.amount,
                        required: true,
                    },
                ]}
            />
        </>
    );

}

const BulkImport = () => {

    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [effectedStudents, setEffectedStudents] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            registration_channel_id: 0,
            department_id: 0,
            academic_year_id: 0,
            amount: 0,
            created_at: "",
        },
    });

    const metaenumApiInterceptor = useApi<any>({
        key: 'students_metaenum',
        params: {
            meta_key: 'registration_channel',
            limit: 9999,
        },
    });

    const academicYearsApiInterceptor = useApi<any>({
        key: 'academic_years',
        params: {
            limit: 9999,
        },
    });

    const departmentsApiInterceptor = useApi<any>({
        key: 'departments',
        params: {
            limit: 9999,
        },
    });

    useEffect(() => {
        if (form.values.department_id && form.values.registration_channel_id && form.values.academic_year_id) {
            let args: any = {
                registration_channel_id: form.values.registration_channel_id,
                academic_year_id: form.values.academic_year_id,
                department_id: form.values.department_id,
            };
            if (form.values.created_at) {
                args['created_at'] = form.values.created_at;
            }
            checkBulkDiscounts(args).then((response) => {
                const students_count = response.students_count;
                setEffectedStudents(students_count);
            }).catch((error) => {
                notifications.show({
                    title: t('خطأ'),
                    message: error.message,
                    color: 'red',
                });
            });
        } else {
            setEffectedStudents(0);
        }
    }, [form.values]);

    return (
        <>
            <Button
                size="xs"
                onClick={() => {
                    setModalOpen(true);
                }}
                leftSection={<IconHierarchy3 size={16} />}
                variant="outline"
            >
                {t("إضافة خصم جماعي")}
            </Button>
            <Modal
                opened={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
                title={t("إضافة خصم جماعي")}
            >
                <Modal.Body>
                    <Flex direction="column" gap="xs">
                        <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                        <Select
                            label={t("القسم")}
                            placeholder={t("اختر القسم")}
                            required
                            withAsterisk
                            data={
                                (departmentsApiInterceptor.query.data?.data as any)
                                    ?.map((item: any) => ({
                                        value: String(item.id),
                                        label: item.name,
                                    })) || []
                            }
                            value={String(form.values.department_id)}
                            onChange={(value) => {
                                form.setFieldValue('department_id', Number(value));
                            }}
                            searchable
                        />
                        <Select
                            label={t("قناة القبول")}
                            placeholder={t("اختر قناة القبول")}
                            required
                            withAsterisk
                            data={
                                (metaenumApiInterceptor.query.data?.data as any)
                                    ?.filter((item: any) => item.meta_key === 'registration_channel')
                                    ?.map((item: any) => ({
                                        value: String(item.id),
                                        label: item.meta_value,
                                    })) || []
                            }
                            value={String(form.values.registration_channel_id)}
                            onChange={(value) => {
                                form.setFieldValue('registration_channel_id', Number(value));
                            }}
                        />
                        <Select
                            label={t("العام الدراسي")}
                            placeholder={t("اختر العام الدراسي")}
                            required
                            withAsterisk
                            data={
                                (academicYearsApiInterceptor.query.data?.data as any)
                                    ?.map((item: any) => ({
                                        value: String(item.id),
                                        label: item.name,
                                    })) || []
                            }
                            value={String(form.values.academic_year_id)}
                            onChange={(value) => {
                                form.setFieldValue('academic_year_id', Number(value));
                            }}
                        />
                        <NumberInput
                            label={t("المبلغ")}
                            placeholder={t("أدخل المبلغ")}
                            required
                            withAsterisk
                            value={String(form.values.amount)}
                            onChange={(value: any) => {
                                form.setFieldValue('amount', Number(value));
                            }}
                            thousandSeparator
                        />
                        <DateInput
                            label={t("إستثناء الطلاب الذين تمت إضافتهم قبل")}
                            placeholder={t("أختر التاريخ")}
                            clearable
                            value={form.values.created_at as any ? new Date(form.values.created_at as any) : null}
                            onChange={(value) => {
                                form.setFieldValue('created_at', value ? dayjs(value).format('YYYY-MM-DD') : "");
                            }}
                        />
                        {
                            Boolean(effectedStudents) && (
                                <Alert
                                    title={t("تنبيه")}
                                    color="orange"
                                    icon={<IconExclamationCircle size={16} />}
                                >
                                    {t("سيتم تطبيق الخصم على")} {effectedStudents} {t("طالب")}
                                </Alert>
                            )
                        }
                        <Flex gap={'xs'}>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                color="red"
                            >
                                {t("إغلاق")}
                            </Button>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setLoading(true);
                                    let args: any = {
                                        registration_channel_id: form.values.registration_channel_id,
                                        academic_year_id: form.values.academic_year_id,
                                        department_id: form.values.department_id,
                                        amount: form.values.amount,
                                    };
                                    if (form.values.created_at) {
                                        args['created_at'] = form.values.created_at;
                                    }
                                    doBulkDiscounts(args).then((response) => {
                                        setLoading(false);
                                        notifications.show({
                                            title: t('نجاح'),
                                            message: t('تمت العملية بنجاح'),
                                            color: 'teal',
                                        });
                                        setModalOpen(false);
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 1000);
                                    }).catch((error) => {
                                        setLoading(false);
                                        notifications.show({
                                            title: t('خطأ'),
                                            message: error.message,
                                            color: 'red',
                                        });
                                    });
                                }}
                            >
                                {t("إضافة")}
                            </Button>
                        </Flex>
                    </Flex>
                </Modal.Body>
            </Modal >
        </>
    );

}