import { Footnote, Tailwind, CSS } from "@onedoc/react-print";
import { useTranslation } from "react-i18next";
import logo from '../../assets/img/logo-main.png'
import ThemeProvider from "../../themeProvider";
import dayjs from "dayjs";
import { IconCheckbox, IconSquareX } from "@tabler/icons-react";

export default ({
    student,
    docs,
    max_date,
}: {
    student: any
    docs: any
    max_date: string
}) => {

    const { t } = useTranslation();
    // if national_id or civil_id taken
    const id_taken = docs.national_id.taken || docs.civil_id.taken;
    let missingDocs = Object.keys(docs).filter((doc: string) => !docs[doc].taken);
    let takenDocs = Object.keys(docs).filter((doc: string) => docs[doc].taken);

    // if id_taken, remove national_id and civil_id from missingDocs
    if (id_taken) {
        missingDocs = missingDocs.filter((doc: string) => doc !== 'national_id' && doc !== 'civil_id');
    }

    const names: any = {
        bechelor_certificate: 'وثيقة الدراسة الإعدادية (النسخة الأصلية)',
        medical_test: 'الفحص الطبي (النسخة الأصلية)',
        photos: 'صور شخصية (عدد 3)',
        national_id: 'البطاقة الوطنية الموحدة',
        civil_id: 'هوية الأحوال المدنية',
        iraqi_national_id: 'الجنسية العراقية',
        housing_card: 'بطاقة السكن',
        father_id: 'هوية الأحوال المدنية أو البطاقة الوطنية الموحدة للأب',
        mother_id: 'هوية الأحوال المدنية أو البطاقة الوطنية الموحدة للأم',
        student_promise: 'تعهد الطالب الدراسي',
        ration_card: 'البطاقة التموينية',
        instituteـcertificate: 'شهادة المعهد',
    };

    // if national_id is taken, remove civil_id from takenDocs and iraqi_national_id
    if (docs.national_id.taken) {
        takenDocs = takenDocs.filter((doc: string) => doc !== 'civil_id');
        missingDocs = missingDocs.filter((doc: string) => doc !== 'iraqi_national_id');
    }

    // if civil_id is taken, remove national_id from takenDocs
    if (docs.civil_id.taken) {
        takenDocs = takenDocs.filter((doc: string) => doc !== 'national_id');
    }

    // order docs in the order of the names
    const namesOrder = Object.keys(names);
    takenDocs = takenDocs.sort((a, b) => namesOrder.indexOf(a) - namesOrder.indexOf(b));
    missingDocs = missingDocs.sort((a, b) => namesOrder.indexOf(a) - namesOrder.indexOf(b));

    const stageName = [
        "المرحلة الأولى",
        "المرحلة الثانية",
        "المرحلة الثالثة",
        "المرحلة الرابعة",
        "المرحلة الخامسة",
        "المرحلة السادسة",
        "المرحلة السابعة",
    ];

    return <ThemeProvider>
        <Tailwind>
            <CSS>{`
                @import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');
                * {
                    font-family: "Readex Pro", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: <weight>;
                    font-style: normal;
                    font-variation-settings:
                      "HEXP" 0;
                    -webkit-print-color-adjust:exact;
                    -webkit-filter:opacity(1);
                  }
            `}</CSS>
            <div style={{ direction: 'rtl' }}>
                <div className="bg-gradient-to-r from-[#991b1d] to-[#1b2556] -z-10 absolute -left-[2cm] right-[25vw] -skew-y-12 h-[100vh] top-[-95vh]" />
                <div className="bg-gradient-to-r from-[#991b1d] to-[#1b2556] -z-20 absolute left-[75vw] -right-[2cm] -skew-y-12 h-[100vh] top-[-90vh]" />
                <div className="bg-slate-100 -rotate-12 -z-10 absolute -left-[200em] -right-[200em] h-[50vh] bottom-[0vh]" />
                <main className="text-slate-800 pt-12 h-[90vh] flex flex-col" style={{ margin: '0 25px' }}>
                    <img src={logo} alt="logo" className="mx-auto w-[15vw]" />
                    <h1 className="text-center text-slate-800 text-[2.8em] font-bold">
                        {t('جامعة النور')}
                    </h1>
                    <p className="pt-2 text-slate-400 text-center text-[1.8em] mb-0">
                        {t('قسم التسجيل وشؤون الطلبة')}
                    </p>
                    <p className="pt-2 pb-4 text-slate-800 text-center text-[1.8em] mb-0">
                        <strong>{t('مستند إستلام المستمسكات الثبوتية للطالب')}</strong>
                    </p>
                    <div className="p-12 flex-grow bg-white rounded-2xl rounded-t-none shadow-xl shadow-black/10">
                        <div className="flex align-center" style={{ alignItems: 'center', gap: '1rem' }}>
                            <img src={student.profile_picture?.sizes?.thumbnail || `https://eu.ui-avatars.com/api/?size=250&name=${student.first_name}+${student.second_name}`} alt="profile" className="w-20 h-20 rounded-full" />
                            <div className="flex flex-col gap-2">
                                <p className="text-slate-800 font-bold text-[1.5em]">{student.first_name} {student.second_name} {student.third_name} {student.fourth_name}</p>
                                <p className="text-slate-600 text-[1.2em]">
                                    {student.department.college.name} / {student.department.name}، {stageName[student.stage - 1]}، {student.study_type === 'morning' ? t('صباحي') : t('مسائي')} - {student.registration_channel.meta_value} - عام القبول: {student.registration_academic_year.name}
                                </p>
                            </div>
                        </div>
                        <div className="flex py-2" style={{ gap: '1em' }}>
                            {
                                Boolean(missingDocs.length) && (
                                    <div className="flex-grow">
                                        <h2 className="text-slate-600 font-bold text-[1.2em] py-4 pt-8 uppercase">
                                            {t('الوثائق غير المستلمة')}
                                        </h2>
                                        <div className="bg-slate-100 px-6 py-2 rounded-md">
                                            <table className="w-full text-[1.2em]">
                                                {
                                                    missingDocs.map((key, index) => (
                                                        <tr className="border-b text-red-500" key={index}>
                                                            <td className="py-2">
                                                                <div className="flex justify-between">
                                                                    {names[key] || key}
                                                                    <div className="flex gap-2">
                                                                        <IconSquareX color="red" />
                                                                        {t('غير مستلم')}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                Boolean(takenDocs.length) && (
                                    <div className="flex-grow">
                                        <h2 className="text-slate-600 font-bold text-[1.2em] py-4 pt-8 uppercase">
                                            {t('الوثائق المستلمة')}
                                        </h2>
                                        <div className="bg-slate-100 px-6 py-2 rounded-md">
                                            <table className="w-full text-[1.2em]">
                                                {
                                                    takenDocs.map((key, index) => (
                                                        <tr className="border-b text-green-500" key={index}>
                                                            <div className="flex justify-between">
                                                                {names[key] || key}
                                                                <div className="flex gap-2">
                                                                    <IconCheckbox color="teal" />
                                                                    {t('تم الإستلام')}
                                                                </div>
                                                            </div>
                                                        </tr>
                                                    ))
                                                }
                                            </table>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <hr className="my-6" />
                        <div className="flex flex-col gap-4">
                            <p className="text-slate-500 text-[1.4em]">
                                أتعد بتزويد الجامعة (قسم التسجيل وشؤون الطلبة) بالمستمسكات غير المستلمة في موعد أقصاه <br /><strong>{dayjs(max_date).format('YYYY-MM-DD')}</strong> وبخلاف ذلك أتحمل كافة التبعات القانونية، ولأجله وقعت:
                            </p>
                            {/* alert note */}
                            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">{`تنبيه! `}</strong>
                                <span className="block sm:inline">يرجى المحافظة على هذا المستند لإثبات حقك في تسليم المستمسكات المطلوبة لقبولك في جامعتنا</span>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex flex-grow">
                                    <div className="flex flex-col gap-2">
                                        <p className="text-slate-600 text-[1.2em] pb-4">
                                            {t('توقيع الطالب')}
                                        </p>
                                        <p className="text-slate-600 text-[1.2em]">
                                            {t('التاريخ')}
                                        </p>
                                    </div>
                                    <div className="flex flex-col" style={{ gap: "1em", padding: "1.3em 0.8em" }}>
                                        {/* <div className="border-b border-slate-600 w-[10em]"></div> */}
                                        <p className="pb-4"></p>
                                        <p className="text-slate-600 text-[1.2em]">
                                            {dayjs().format('YYYY-MM-DD')}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex flex-grow">
                                    <div className="flex flex-col gap-2">
                                        <p className="text-slate-600 text-[1.2em]">
                                            {t('ختم التسجيل')}
                                        </p>
                                    </div>
                                    <div className="flex flex-col" style={{ gap: "1em", padding: "1.3em 0.8em" }}>
                                        <p></p>
                                        {/* <div className="border-b border-slate-600 w-[10em]"></div> */}
                                    </div>
                                </div>
                            </div>
                            <Footnote>
                                {t('جامعة النور')} - {t('الموصل، نينوى، العراق، طريق الشلالات')}
                                <br />
                                info@alnoor.edu.iq - 770 566 3332 - 6267
                            </Footnote>
                        </div>
                    </div>
                </main>
            </div>
        </Tailwind >
    </ThemeProvider >;
}

