import { apiWithoutInterceptor } from '.';

export const revoke = async () : Promise<void> => {
    return await apiWithoutInterceptor.post('/auth/revoke');
}

export const login = async (data: {
    email: string;
    password: string;
}) : Promise<{
    token: string;
    user: any;
}> => {
    return await apiWithoutInterceptor.post('/auth/classic', data);
}

export const loginGoogle = async (data: {
    token: string;
}) : Promise<{
    token: string;
    user: any;
}> => {
    return await apiWithoutInterceptor.post('/auth/google', data);
}