import api from '../api';
import { ApiConfig, StudentMetaenumType } from '../types';

export const metaenum = new api<StudentMetaenumType>('/students/metaenum', true);

export default {
    list: (params?: any) => metaenum.list(params),
    get: (id: number) => metaenum.get(id),
    update: (id: number, data: any) => metaenum.update(id, data),
    create: (data: any) => metaenum.create(data),
    delete: (id: number) => metaenum.delete(id),
} as ApiConfig<StudentMetaenumType>;