import { Tailwind, CSS } from "@onedoc/react-print";
import { useTranslation } from "react-i18next";
import ThemeProvider from "../../themeProvider";
import dayjs from "dayjs";

export default ({
    student,
}: {
    student: any
}) => {

    const { t } = useTranslation();

    const stageName = [
        "المرحلة الأولى",
        "المرحلة الثانية",
        "المرحلة الثالثة",
        "المرحلة الرابعة",
        "المرحلة الخامسة",
        "المرحلة السادسة",
        "المرحلة السابعة",
    ];

    return <ThemeProvider>
        <Tailwind>
            <CSS>{`
                @import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');
                * {
                    font-family: "Readex Pro", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: <weight>;
                    font-style: normal;
                    font-variation-settings:
                      "HEXP" 0;
                    -webkit-print-color-adjust:exact;
                    -webkit-filter:opacity(1);
                  }
            `}</CSS>
            <div style={{ direction: 'rtl' }}>
                <div className="bg-slate-100 -rotate-12 -z-10 absolute -left-[200em] -right-[200em] h-[50vh] bottom-[0vh]" />
                <main className="text-slate-800 pt-12 h-[90vh] flex flex-col" style={{ margin: '0 25px' }}>
                    <div className="pt-50"></div>
                    <p className="pt-10 pb-4 text-slate-800 text-center text-[2.4em] mb-0">
                        <strong>{t('السيد رئيس القسم المحترم')}</strong>
                    </p>
                    <p className="pt-10 pb-4 text-slate-800 text-center text-[2.4em] mb-0">
                        <strong>{t('م/ إستضافة')}</strong>
                    </p>
                    <br/>
                    <p className="text-slate-500 text-[2em] px-20 pt-10">
                        أرجو التفضل بالموافقة على استضافتي من الدراسة المسائية إلى الدراسية الصباحية لمدة أربع سنوات علماً أنني مقبول في كليتكم للعام الدراسي ({student.registration_academic_year.name}) وذلك لظروفي الخاصة التي تعيق دوامي في الدراسة المسائية.
                    </p>
                    <br/>
                    <p className="text-slate-500 text-[2em] px-20 pt-10 text-center">
                        مع التقدير…
                    </p>
                    <br/>
                    <br/>
                    <table className="w-full mt-12">
                        <tbody>
                            <tr>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "50px 25px" }}>
                                    <strong>{t('توقيع الطالب')}</strong>
                                </td>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>

                                </td>
                            </tr>
                            <tr>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{t('اسم الطالب')}</strong>
                                </td>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{student.first_name} {student.second_name} {student.third_name} {student.fourth_name}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{t('القسم')}</strong>
                                </td>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{student.department.name}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{t('الدراسة')}</strong>
                                </td>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{student.study_type === 'morning' ? t('صباحي') : t('مسائي')}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{t('المرحلة')}</strong>
                                </td>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{stageName[student.stage - 1]}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{t('الموبايل')}</strong>
                                </td>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{student.phone}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{t('التاريخ')}</strong>
                                </td>
                                <td className="text-slate-500 text-[1.5em]" style={{ padding: "25px" }}>
                                    <strong>{dayjs().format('DD/MM/YYYY')}</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </main>
            </div>
        </Tailwind >
    </ThemeProvider >;
}

