import { useTranslation } from "react-i18next";
import { Table } from "../components";
import { CollegeType } from "../api/types";
import useApi from "../hooks/useApi";
import api from "../api"

export default () => {

    const { t } = useTranslation();

    const collegesApiInterceptor = useApi<any>({
        key: 'colleges',
        params: {},
        // api: api['colleges' as keyof typeof api],
    });

    return (
        <>
            <Table
                interceptor={'departments'}
                singular={t('قسم')}
                plural={t('الأقسام')}
                columns={[
                    {
                        id: 'name',
                        accessorKey: 'name',
                        header: t('الاسم'),
                    },
                    {
                        id: 'college_id',
                        accessorKey: 'college_id',
                        accessorFn: ({ college }: { college: CollegeType }) => college.name,
                        header: t('الكلية'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (collegesApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                collegesApiInterceptor.setParam('search', value);
                            },
                            // onChange: (value: string) => {
                            //     apiInterceptor.mutation.update( row.original.id, { college_id: value } );
                            // },
                        }),
                    },
                    {
                        id: 'study_strategy',
                        accessorKey: 'study_strategy',
                        accessorFn: (item: any) => item.study_strategy === 'course' ? t('كورسات') : t('فصلي'),
                        header: t('نظام الدراسة'),
                        enableEditing: true,
                    },
                    {
                        id: 'stages',
                        accessorKey: 'stages',
                        header: t('المراحل'),
                        enableEditing: false,
                    },
                ]}
                fields={[
                    {
                        type: 'text',
                        label: t('الاسم'),
                        name: 'name',
                    },
                    {
                        type: 'select',
                        label: t('الكلية'),
                        name: 'college_id',
                        interceptor: 'colleges',
                        initialValue: (item: any) => item?.college?.id,
                        initialData: (item: any) => (item?.college ? [
                            {
                                value: String(item.college.id),
                                label: item.college.name,
                            }
                        ] : []),
                    },
                    {
                        type: 'select',
                        label: t('نظام الدراسة'),
                        name: 'study_strategy',
                        initialData: () => ([
                            { value: 'course', label: t('كورسات') },
                            { value: 'semester', label: t('فصلي') },
                            { value: 'bolonga', label: t('بولونيا') },
                        ]),
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                        initialValue: (item: any) => item?.study_strategy ?? 'course',
                    },
                    {
                        type: 'number',
                        label: t('المراحل'),
                        name: 'stages',
                    }
                ]}
            />
        </>
    );

}