import { useEffect, useState } from "react"
import useApi from "./useApi";
import xlsx from "json-as-xlsx";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";

export default ({
    key,
    columns,
}: {
    key: string,
    columns?: any[],
}) => {

    const [progress, setProgress] = useState(0)
    const [params, setParams] = useState({})
    const [exportActive, setExportActive] = useState(false)
    const [exportData, setExportData] = useState<any[]>([])

    const apiInterceptor = useApi<any>({
        key,
        params: { ...params },
        disableQuery: Object.keys(params).length === 0,
        disableCache: true,
    });

    useEffect(() => {
        if (!exportActive) return;
        if (progress === 100) {

            if (!exportData.length) {
                return;
            }
            
            // export to csv
            // const keys = Object.keys(exportData[0]);
            // const csv = keys.join(',') + '\n' + exportData.map((item) => {
            //     const keys = Object.keys(item);
            //     let row: any = {};
            //     for (let i = 0; i < keys.length; i++) {
            //         let value = item[keys[i]];
            //         if (typeof value === typeof {}) {
            //             // check if there's a column with the same key
            //             const column = columns?.find(column => column.accessorKey === keys[i]);
            //             if (column && column.accessorFn) {
            //                 value = column.accessorFn(value);
            //             } else {
            //                 if (value?.name) {
            //                     value = value.name;
            //                 } else if (value?.first_name && value?.second_name && value?.third_name && value?.fourth_name) {
            //                     value = value.first_name + ' ' + value.second_name + ' ' + value.third_name + ' ' + value.fourth_name;
            //                 } else if (value?.label) {
            //                     value = value.label;
            //                 } else if (value?.meta_value) {
            //                     value = value.meta_value;
            //                 } else if (value?.value) {
            //                     value = value.value;
            //                 }
            //             }
            //         }
            //         row[keys[i]] = value;
            //     }
            //     return Object.values(row).join(',');
            // }).join('\n')
            // const blob = new Blob([csv], { type: 'text/csv' });
            // const url = window.URL.createObjectURL(blob);
            // const a = document.createElement('a');
            // a.setAttribute('href', url);
            // a.setAttribute('download', `${key}.csv`);
            // a.click();
            // window.URL.revokeObjectURL(url);

            const headers = Object.keys(exportData[0]);

            const content = exportData.map((item) => {
                const row: any = {};
                for (let key of headers) {
                    let value = item[key];
                    if (typeof value === 'object') {
                        const column = columns?.find(column => column.accessorKey === key);
                        if (column && column.accessorFn) {
                            value = column.accessorFn(value);
                        } else {
                            if (value?.name) {
                                value = value.name;
                            } else if (value?.first_name && value?.second_name && value?.third_name && value?.fourth_name) {
                                value = `${value.first_name} ${value.second_name} ${value.third_name} ${value.fourth_name}`;
                            } else if (value?.label) {
                                value = value.label;
                            } else if (value?.meta_value) {
                                value = value.meta_value;
                            } else if (value?.value) {
                                value = value.value;
                            }
                        }
                    }
                    row[key] = value;
                }
                return row;
            });
            
            xlsx(
                [
                    {
                        sheet: "Sheet 1",
                        columns: headers.map(header => ({ label: header, value: header })),
                        content: content
                    }
                ],
                {
                    fileName: "exported-data-" + dayjs().format('YYYY-MM-DD-HH-mm-ss'),
                    extraLength: 3,
                    writeMode: "writeFile",
                    writeOptions: {},
                    RTL: true,
                },
                () => {
                    notifications.show({
                        title: 'Download Complete',
                        message: 'The file has been downloaded successfully.',
                        color: 'green',
                    });
                }
            );

            setProgress(0)
            setExportData([])
            setExportActive(false)
            return;
        }

        const newParams = {
            ...params,
            offset: exportData.length,
        }

        apiInterceptor.setParams(newParams);

    }, [exportActive, progress]);

    useEffect(() => {
        if (!exportActive) return;
        if (apiInterceptor.query.isLoading) return;

        if (apiInterceptor.query.data?.data) {
            setExportData([
                ...exportData,
                ...apiInterceptor.query.data.data,
            ]);
            const _progress = Math.round((exportData.length / apiInterceptor.query.data.total) * 100);
            setProgress(_progress > 100 ? 100 : _progress);
        }

    }, [apiInterceptor.query.data]);

    return {
        exportActive,
        progress,
        startExport: (_params: { [key: string]: string | number }) => {
            setExportData([])
            setParams({
                ..._params,
                limit: 100,
                offset: 0,
            })
            setProgress(1)
            setExportActive(true)
        },
    }

}