import { Button, Combobox, Drawer, Flex, Grid, Loader, NumberInput, Select, TextInput, Textarea, Text, Divider, MantineSize, Switch, InputBase, Input, CloseButton, useCombobox, ScrollArea, MultiSelect, PasswordInput, FocusTrap } from "@mantine/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { DateInput } from "@mantine/dates";
import { FieldType } from "./fieldType";

import api from "../../api"
import useApi from "../../hooks/useApi";
import dayjs from "dayjs";
import { IconWashDrycleanOff } from "@tabler/icons-react";

export default ({
    title,
    size,
    intercaptor,
    fields = [],
    customFields,
    item,
    disableCache,
    onCreateItem,
    onEditItem,
    fieldsFooter,
}: {
    title?: string,
    size?: MantineSize | (string & {}) | number,
    intercaptor: string,
    fields?: FieldType[],
    customFields?: (form: any, itemData: any) => React.ReactNode,
    fieldsFooter?: (form: any, itemData: any) => React.ReactNode,
    item?: { id: number, [key: string]: any },
    disableCache?: boolean,
    onCreateItem?: (item: any) => void
    onEditItem?: (item: any) => void
}) => {

    const { t } = useTranslation();

    const [opened, setOpened] = useState<boolean>(false);
    const [itemData, setItemData] = useState<any>(item);
    const [drawerTitle, setDrawerTitle] = useState<string>(title || '');

    const form = useForm({});
    
    const apiInterceptor = useApi<any>({
        key: intercaptor,
        disableQuery: true,
        params: {},
        onDone: (action, response) => {
            const status = response?.data?.status;

            if (status && status !== 200) {

                const params = response?.data?.params || {};

                Object.keys(params).forEach(key => {
                    const msg = params[key];
                    form.setFieldError(key, msg);
                });

                return;
            };

            if (action === 'create' || action === 'delete') {
                setOpened(false);
            }

            if (action === 'create') {
                onCreateItem && onCreateItem(response);
            }

            if (action === 'update') {
                onEditItem && onEditItem(response);
            }
        },
        disableCache,
    });

    const purpose = useMemo(() => (itemData && itemData.id) ? 'update' : 'create', [itemData]);

    useEffect(() => {
        if (itemData) {

            Object.keys(itemData).forEach(key => {
                let value = itemData[key];
                let field = fields?.find(field => field.name === key);
                if (!field) {
                    field = fields?.find(field => field.name.replace('_id', '') === key);
                    if (field) {
                        key = field.name;
                    }
                }
                if (field?.initialValue) {
                    value = field.initialValue(itemData);
                }

                // if value is string or numeric 
                if (typeof value === 'string' || typeof value === 'number') {
                    if (value !== undefined && value !== null) {
                        form.setFieldValue(key, String(value));
                    }
                } else if (typeof value === typeof {} || Array.isArray(value)) {
                    form.setFieldValue(key, value);
                }
            });
        } else {
            fields.forEach(field => {
                if (field.initialValue) {
                    form.setFieldValue(field.name, field.initialValue(null));
                }
            });
        }
    }, [itemData]);

    const customFieldsNode = useMemo(() => {
        return customFields ? customFields(form, itemData) : null;
    }, [customFields, form]);

    const fieldsFooterNode = useMemo(() => {
        return fieldsFooter ? fieldsFooter(form, itemData) : null;
    }, [fieldsFooter, form]);

    const modal = <Drawer
        opened={opened}
        onClose={() => {
            setOpened(false);
            form.reset();
            setItemData(null);
        }}
        title={drawerTitle}
        size={size || 'md'}
        padding={'md'}
        position={'right'}
    >
        <form
            onSubmit={form.onSubmit(() => {
                if (itemData && itemData.id) {
                    apiInterceptor.mutation.update(itemData.id, form.values);
                } else {
                    apiInterceptor.mutation.create(form.values);
                }
            })}
        >
            {customFields ? customFieldsNode : null}
            <Grid>
                <FocusTrap active={true}>
                    {fields?.map((field, index) => (
                        field.hideOn?.includes(purpose) 
                        || (field.visibleOn && field.visibleOn.field && field.visibleOn.value !== form.values[field.visibleOn.field])
                    ) ? null :
                        <Grid.Col
                            key={index}
                            span={Math.floor(12 * (field.widthRatio || 1))}
                        >
                            <InputField
                                name={field.name}
                                type={field.type}
                                label={field.label}
                                placeholder={field.label}
                                required={field.required}
                                interceptor={field.interceptor}
                                params={field.params}
                                labelAccessor={field.labelAccessor}
                                // if item is select then the object should be passed as an initailData to the select field
                                initialData={field.initialData ? field.initialData(itemData ? itemData : null) : undefined}
                                // onChange={(value) => {
                                //     form.setFieldValue(field.name, value);
                                // }}
                                minNumber={field.minNumber}
                                maxNumber={field.maxNumber}
                                disableQuery={field.disableQuery}
                                // @ts-ignore
                                autoFocus={index === 0}
                                error={form.errors[field.name] ?? false}
                                {...form.getInputProps(field.name)}
                            // onChange={(value) => {
                            //     form.setFieldValue(field.name, value);
                            //     field.onChange && field.onChange(value);
                            // }}
                            />
                        </Grid.Col>
                    )}
                </FocusTrap>
                <Grid.Col span={12}>
                    {fieldsFooter ? fieldsFooterNode : null}
                </Grid.Col>
                <Grid.Col span={12}>
                    <Flex w="100%" justify={'space-between'}>
                        <Button
                            type="submit"
                            size="xs"
                        >
                            {t('حفظ')}
                        </Button>
                        {
                            Boolean(itemData) && <Button
                                size="xs"
                                color="red"
                                variant="outline"
                                onClick={() => {
                                    // open confirm modal
                                    modals.openConfirmModal({
                                        title: t('تأكيد الحذف'),
                                        children: (
                                            <Text size="sm">
                                                {t('عملية الحذف هي عملية غير قابلة للإسترجاع هل أنت متأكد من الحذف؟')}
                                            </Text>
                                        ),
                                        labels: { confirm: t('حذف'), cancel: t('إلغاء') },
                                        onConfirm: () => {
                                            if (itemData && itemData.id) {
                                                apiInterceptor.mutation.delete(itemData.id);
                                            }
                                        },
                                        confirmProps: {
                                            color: 'red',
                                        }
                                    });
                                }}
                            >
                                {t('حذف')}
                            </Button>
                        }
                    </Flex>
                </Grid.Col>
            </Grid>
        </form>
    </Drawer>

    return {
        contextHolder: modal,
        opened,
        open: (props?: any) => {
            setOpened(true);
            if (props?.item) setItemData(props.item)
            else setItemData(null);
            if (props?.title) setDrawerTitle(props.title);
        },
        close: () => setOpened(false),
    }

}

const InputField = (config: FieldType) => {

    const commonProps = useMemo(() => ({
        size: 'xs',
        // @ts-ignore
        'data-autofocus': config.autoFocus ? 'true' : 'false',
    }), []);

    const { i18n } = useTranslation();

    if (config.type === 'text' || config.type === 'hidden') {
        return <TextInput
            {...commonProps}
            {...config}
            onChange={(e) => config.onChange && config.onChange(e.currentTarget.value)}
            style={{ display: config.type === 'hidden' ? 'none' : 'block' }}
        />
    }

    if (config.type === 'number') {

        let newConfig: any = {};
        // same as config but without type
        Object.keys(config).forEach(key => {
            if (key !== 'type') {
                // @ts-ignore
                newConfig[key] = config[key];
            }
        });

        if (config.minNumber) {
            newConfig['min'] = config.minNumber;
        }

        if (config.maxNumber) {
            newConfig['max'] = config.maxNumber;
        }

        return <NumberInput {...commonProps} {...newConfig as any} />
    }

    if (config.type === 'select') {
        return <SelectClearable {...commonProps} {...config} />
    }

    if (config.type === 'textarea') {
        return <Textarea {...commonProps} {...config} resize="vertical" />
    }

    if (config.type === 'date') {
        // check if date is undefined or false or in format 0000-00-00
        let invalidDate = !config.value || config.value === '0000-00-00';

        // TODO: make the field in seperated component and and load set 
        // the value of the form to format yyyy-mm-dd hh:mm:ss
        // IDEA: on load of the value is set, then re set it again to the same field to fire the onChange event
        return <DateInput
            {...commonProps}
            // {...config as any}
            label={config.label}
            placeholder={config.placeholder}
            size="xs"
            locale={i18n.language}
            onChange={(value) => config.onChange && config.onChange(value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : undefined)}
            value={!invalidDate ? new Date(config.value) : undefined}
            valueFormat="YYYY/MM/DD"
            withAsterisk={config.required}
            error={config.error}
        />
    }

    if (config.type === 'switch') {
        return <Switch
            {...commonProps}
            {...config}
            onChange={(value) => console.log(value)}
        />
    }

    if (config.type === 'divider') {
        return <Divider
            label={config.label}
            labelPosition={'left'}
            pt={'sm'}
        />;
    }

    if (config.type === 'multiselect') {
        return <MultiSelectField {...config} />
    }

    // passwrod field
    if (config.type === 'password') {
        return <PasswordInput
            {...commonProps}
            {...config}
            onChange={(e) => config.onChange && config.onChange(e.currentTarget.value)}
        />
    }

    return <></>;

}

const MultiSelectField = (props: FieldType) => {

    const [data, setData] = useState<any>(props.initialData);
    const apiParams = useMemo(() => (props.params ? props.params : {}), [props.params]);
    const apiInterceptor = useApi<any>({
        key: props.interceptor as string,
        params: {
            search: '',
            ...apiParams,
        },
        api: api[props.interceptor as keyof typeof api],
        disableQuery: props.disableQuery,
    });

    useEffect(() => {
        if (props.disableQuery) return;
        const apiData = apiInterceptor.query.data?.data as any;

        if (!apiData) return;

        setData(apiData.map((item: any) => ({ value: String(item.id), label: props.labelAccessor ? (typeof props.labelAccessor === 'string' ? item[props.labelAccessor] : props.labelAccessor(item)) : (item.name || '-') })));
    }, [apiInterceptor?.query?.data]);

    const commonProps = useMemo(() => ({
        size: 'xs',
    }), []);

    const ref = useRef<React.ElementRef<typeof Select>>(null);
    const [value, setValue] = useState<any[]>([]);

    /**
 * Do not fuck with state before reading this issue:
 * @see https://github.com/mantinedev/mantine/pull/4915
 */
    useEffect(() => {
        if (props.value) {
            if (Array.isArray(props.value)) {
                // console.log(props.value);
                const newValue = props.value?.map(item => String(typeof item === typeof {} ? item.id : item));
                setValue(newValue);

                // setValue(props.value.map(item => String(item.id)));
                // let additionalData = props.value.map((item: any) => ({ value: String(item.id), label: props.labelAccessor ? (typeof props.labelAccessor === 'string' ? item[props.labelAccessor] : props.labelAccessor(item)) : (item.name || '-') }));
                // filter out ids that are already exist in data
                // additionalData.filter(item =>
                // setData([
                //     ...data,
                //     // ...additionalData,
                // ])
            }
        }
    }, [props.value]);

    return (
        <MultiSelect
            {...commonProps}
            ref={ref}
            label={props.label}
            placeholder={props.placeholder}
            required={props.required || false}
            data={data || []}
            onChange={(value) => {
                props.onChange && props.onChange(value);
                setValue(value);
            }}
            searchable
            onSearchChange={(value: string) => {
                // setValue(undefined);
                apiInterceptor.setParam('search', value);
                // if (!value) {
                //     props.onChange && props.onChange(value);
                // }
            }}
            rightSection={apiInterceptor.query.isFetching ? <Loader size={14} /> : <Combobox.Chevron />}
            leftSection={value && (<IconWashDrycleanOff size={14} />)}
            value={value || []}
        // onDropdownClose={() => {
        //     if (props.value) {
        //         setValue(props.value);
        //     }
        // }}
        />
    )

}

const SelectField = (props: FieldType) => {

    const [data, setData] = useState<any>(props.initialData);
    const apiParams = useMemo(() => (props.params ? props.params : {}), [props.params]);
    const apiInterceptor = useApi<any>({
        key: props.interceptor as string,
        params: {
            search: '',
            ...apiParams,
        },
        api: api[props.interceptor as keyof typeof api],
        disableQuery: props.disableQuery,
    });

    useEffect(() => {
        if (props.disableQuery) return;
        const apiData = apiInterceptor.query.data?.data as any;

        if (!apiData) return;

        setData(apiData.map((item: any) => ({ value: String(item.id), label: props.labelAccessor ? (typeof props.labelAccessor === 'string' ? item[props.labelAccessor] : props.labelAccessor(item)) : (item.name || '-') })));
    }, [apiInterceptor?.query?.data]);

    const commonProps = useMemo(() => ({
        size: 'xs',
    }), []);


    const ref = useRef<React.ElementRef<typeof Select>>(null);
    const [value, setValue] = useState(props.value);

    /**
     * Do not fuck with state before reading this issue:
     * @see https://github.com/mantinedev/mantine/pull/4915
     */
    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        console.log(props.label, value);
    }, [value])

    return <Select
        {...commonProps}
        ref={ref}
        label={props.label}
        placeholder={props.placeholder}
        required={props.required || false}
        data={data || []}
        onChange={(value) => {
            props.onChange && props.onChange(value);
            setValue(value);
        }}
        searchable
        onSearchChange={(value: string) => {
            setValue(undefined);
            apiInterceptor.setParam('search', value);
            if (!value) {
                props.onChange && props.onChange(value);
            }
        }}
        rightSection={apiInterceptor.query.isFetching ? <Loader size={14} /> : <Combobox.Chevron />}
        leftSection={value && (<IconWashDrycleanOff size={14} />)}
        value={value}
        onDropdownClose={() => {
            if (props.value) {
                setValue(props.value);
            }
        }}
    />

}

export function SelectClearable(props: any) {

    const [data, setData] = useState<any>(props.initialData);
    const apiParams = useMemo(() => (props.params ? props.params : {}), [props.params]);
    const apiInterceptor = useApi<any>({
        key: props.interceptor as string,
        params: {
            search: '',
            ...apiParams,
        },
        api: api[props.interceptor as keyof typeof api],
        disableQuery: props.disableQuery,
    });

    useEffect(() => {
        if (props.disableQuery) return;
        const apiData = apiInterceptor.query.data?.data as any;

        if (!apiData) return;

        setData(apiData.map((item: any) => ({ value: String(item.id), label: props.labelAccessor ? (typeof props.labelAccessor === 'string' ? item[props.labelAccessor] : props.labelAccessor(item)) : (item.name || '-') })));

        // if value is not set and then check in the data of interceptor query if there's an item with key of is_default and it's value = 'yes'
        if (!props.value) {
            const defaultItem = apiData.find((item: any) => item.is_default === 'yes');
            if (defaultItem) {
                props.onChange && props.onChange(String(defaultItem.id));
                setValue(String(defaultItem.id));
            }
        }
    }, [apiInterceptor?.query?.data]);




    const [search, setSearch] = useState('');

    useEffect(() => {
        apiInterceptor.setParam('search', search);
    }, [search]);

    const combobox = useCombobox({
        // onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownClose: () => {
            combobox.resetSelectedOption();
            combobox.focusTarget();
            setSearch('');
        },

        onDropdownOpen: () => {
            combobox.focusSearchInput();
        },
    });

    const [value, setValue] = useState<string | null>(props.value || null);
    useEffect(() => {
        if (props.value) {
            setValue(props.value);
        }
    }, [props.value]);

    // const options = groceries.map((item) => (
    //     <Combobox.Option value={item} key={item}>
    //         {item}
    //     </Combobox.Option>
    // ));
    const options = useMemo(() => {
        if (!data) return [];

        props.value && setValue(props.value);

        return data.map((item: any) => (
            <Combobox.Option value={item.value} key={item.value} style={{ fontSize: '.8rem' }}>
                {item.label}
            </Combobox.Option>
        ));
    }, [data]);

    const getValueLabel = useMemo(() => function (value: any) {
        if (!value) return null;
        return data?.find((item: any) => item.value === value)?.label;
    }, [data]);

    const { t } = useTranslation();

    return (
        <Combobox
            store={combobox}
            withinPortal={false}
            onOptionSubmit={(val) => {
                setValue(val);
                props.onChange && props.onChange(val);
                combobox.closeDropdown();
            }}
        >
            <Text size="xs" mb={3} mt={5} fw={500}>
                {props.label}
                {
                    props.required && <Text component="span" color="red" ms={3} fw={700} style={{ fontSize: 10 }}>*</Text>
                }
            </Text>
            <Combobox.Target>
                <InputBase
                    component="button"
                    type="button"
                    pointer
                    size="xs"
                    rightSection={
                        value !== null ? (
                            <CloseButton
                                size="sm"
                                onMouseDown={(event) => event.preventDefault()}
                                onClick={() => {
                                    setValue(null);
                                    props.onChange && props.onChange(null);
                                }}
                                aria-label={t('إزالة القيمة')}
                            />
                        ) : (
                            <Combobox.Chevron />
                        )
                    }
                    onClick={() => combobox.toggleDropdown()}
                    rightSectionPointerEvents={value === null ? 'none' : 'all'}
                >
                    {getValueLabel(value) || <Input.Placeholder>{props.label}</Input.Placeholder>}
                </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Search
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                    placeholder={t('البحث')}
                    size={'xs'}
                />
                <Combobox.Options>
                    <ScrollArea.Autosize mah={200} type="scroll">
                        {
                            options
                        }
                        {
                            apiInterceptor.query.isFetching && <Combobox.Empty>{t('جار التحميل')}</Combobox.Empty>
                        }
                    </ScrollArea.Autosize>
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox >
    );
}