import api from '../api';
import { TuitionFeeType, ApiConfig } from '../types';

export const tuitionfees = new api<TuitionFeeType>('/tuitionfees', true);

export default {
    list: (params?: any) => tuitionfees.list(params),
    get: (id: number) => tuitionfees.get(id),
    update: (id: number, data: any) => tuitionfees.update(id, data),
    create: (data: any) => tuitionfees.create(data),
    delete: (id: number) => tuitionfees.delete(id),
} as ApiConfig<TuitionFeeType>;