import api from '../api';
import { ApiConfig } from '../types';

export const externalinvoices = new api<any>('/invoices', true);

export default {
    list: (params?: any) => externalinvoices.list(params),
    get: (id: number) => externalinvoices.get(id),
    update: (id: number, data: any) => externalinvoices.update(id, data),
    create: (data: any) => externalinvoices.create(data),
    delete: (id: number) => externalinvoices.delete(id),
} as ApiConfig<any>;