import { useTranslation } from "react-i18next";
// @ts-ignore
import { AnimatedTree } from 'react-tree-graph';
import 'react-tree-graph/dist/style.css'

import useApi from "../hooks/useApi";
import { useMemo } from "react";
import { log } from "console";
import { Box } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

export default () => {

    const { t } = useTranslation();

    const collegesApiInterceptor = useApi<any>({
        key: 'colleges',
        params: {
            limit: 99999,
        },
    });

    const departmentsApiInterceptor = useApi<any>({
        key: 'departments',
        params: {
            limit: 99999,
        },
    });

    // const data = {
    //     name: 'Parent',
    //     children: [{
    //         name: 'Child One'
    //     }, {
    //         name: 'Child Two'
    //     }]
    // };
    const data = useMemo(() => {
        // each department has a college which is the parent, so we need to group by college
        const newData: any = {
            name: 'جامعة النور',
            children: [],
        };

        if (!departmentsApiInterceptor.query.data || !collegesApiInterceptor.query.data) return newData;

        collegesApiInterceptor.query.data.data.forEach((college: any) => {
            newData.children.push({
                name: college.name,
                children: [],
            });
        });

        departmentsApiInterceptor.query.data.data.forEach((department: any) => {
            const collegeIndex = newData.children.findIndex((college: any) => college.name === department.college.name);
            if (collegeIndex === -1) return;

            newData.children[collegeIndex].children.push({
                name: department.name,
            });
        });

        return newData
    }, [departmentsApiInterceptor.query.data]);

    const { height, width } = useViewportSize();

    return (
        <>
            <Box>
                <AnimatedTree
                    data={data || {}}
                    height={height - 59}
                    width={width - 400}
                    direction={'rtl'}
                    textProps={{ x: -5, y: 0 }}
                    // styles={{
                    //     width: '100%',
                    //     height: 700,
                    //     maxHeight: 'calc(100vh - 59px)',
                    // }}
                />
            </Box>
        </>
    );

}