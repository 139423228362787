import axios, { AxiosError, AxiosResponse } from 'axios'

export const CONTENT_TYPE = "application/json";
export const DOMAIN = {
    production: "https://sisapi.alnoor.edu.iq/api/app/v1",
    development: "http://localhost:10070/wp-json/app/v1/",
    // development: "http://sis.local/api/app/v1/",
    // development: "https://sisapi.alnoor.edu.iq/api/app/v1",
}

const apiWithoutInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? DOMAIN.production
        : DOMAIN.development
        }`,
    headers: {
        "Accept": CONTENT_TYPE,
    },
});

const apiWithInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? DOMAIN.production
        : DOMAIN.development
        }`,
    headers: {
        "Accept": CONTENT_TYPE,
    },
});

const apiWithInterceptorMultipart = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? DOMAIN.production
        : DOMAIN.development
        }`,
    headers: {
        "Accept": CONTENT_TYPE,
        "Content-Type": "multipart/form-data",
    },
});

apiWithInterceptor.interceptors.request.use(async (config) => {
    let token = localStorage?.getItem("token")?.replace(/['"]+/g, "");

    if (token) {
        if (!config.headers) config.headers = {} as any;
        config.headers.Authorization = `Bearer ${token}`;
    }

    // filters[0][id]: created_at
    // filters[0][value]: <value>
    // whenever the user send filters then check if value is exist with the id if not then remove the filter
    if (config.params) {
        Object.keys(config.params).forEach((key) => {
            if (key.startsWith("filters") && Array.isArray(config.params[key])) {
                config.params[key].forEach((filter: any) => {
                    if (!filter.value) {
                        delete config.params[key];
                    }
                });
            }
        });
    }
    return config;
});

apiWithInterceptorMultipart.interceptors.request.use(async (config) => {
    let token = localStorage?.getItem("token")?.replace(/['"]+/g, "");

    if (token) {
        if (!config.headers) config.headers = {} as any;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

interface SuccessResponse<T> {
    code: string;
    message: string;
    status: number;
    data: T;
}

interface ErrorResponse {
    code: string;
    data: {
        status: number;
    };
    message: string;
}

// filter all responses and check if the status is 200 and the key success is true then return the data
[apiWithInterceptor, apiWithoutInterceptor, apiWithInterceptorMultipart].forEach((api) => {
    api.interceptors.response.use((response: AxiosResponse<any, SuccessResponse<any>>) => {
        const data = response.data;
        if (data.status !== 200) {
            return Promise.reject({
                code: "unknown_error",
                message: "The host returned a non-200 status code on a successful response",
                data: { status: 500 }
            });
        }
        return data.data;
    }, (error: AxiosError<ErrorResponse>) => {

        // 401 means unauthorized
        if (error.response?.status === 401) {
            localStorage.removeItem("token");
            window.location.href = "/";
        }

        const data = error.response?.data || {
            code: "unknown_error",
            message: "Unknown error",
            data: { status: 500 }
        };

        return Promise.reject(data);
    });
});


// on json payload remove any null values
[apiWithInterceptor, apiWithoutInterceptor].forEach((api) => {
    api.interceptors.request.use((config) => {
        if (config.data) {
            Object.keys(config.data).forEach((key) => {
                if (config.data[key] === null) {
                    delete config.data[key];
                }
            });
        }
        return config;
    });
});


export { apiWithInterceptor, apiWithoutInterceptor, apiWithInterceptorMultipart };