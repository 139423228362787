import api from '../api';
import { CollegeType, ApiConfig } from '../types';

export const users = new api<any>('/users', true);

export default {
    list: (params?: any) => users.list(params),
    get: (id: number) => users.get(id),
    update: (id: number, data: any) => users.update(id, data),
    create: (data: any) => users.create(data),
    delete: (id: number) => users.delete(id),
} as ApiConfig<any>;