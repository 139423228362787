import { useTranslation } from "react-i18next";
import { Table } from "../components";
import useApi from "../hooks/useApi";
import dayjs from "dayjs";

export default () => {

    const { t, i18n } = useTranslation();

    const departmentsApiInterceptor = useApi<any>({
        key: 'departments',
        params: {},
    });

    const academicYearsApiInterceptor = useApi<any>({
        key: 'academic_years',
        params: {},
    });

    return (
        <>
            <Table
                interceptor={'tuition_fees'}
                singular={t('رسم دراسي')}
                plural={t('الرسوم الدراسية')}
                columns={[
                    {
                        accessorKey: 'department_id',
                        accessorFn: ({ department }: { department: any }) => `${department.college.name} / ${department.name}`,
                        header: t('القسم'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (departmentsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                departmentsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'academic_year_id',
                        accessorFn: ({ academic_year }: { academic_year: any }) => academic_year.name,
                        header: t('العام الدراسي'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (academicYearsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: item.name,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                academicYearsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'study_type',
                        accessorFn: ({ study_type }: { study_type: string }) => study_type === 'morning' ? t('صباحي') : study_type === 'evening' ? t('مسائي') : study_type,
                        header: t('الدراسة'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: [
                                {
                                    label: t('صباحي'),
                                    value: '$morning'
                                },
                                {
                                    label: t('مسائي'),
                                    value: '$evening'
                                }
                            ]
                        }),
                    },
                    {
                        accessorKey: 'amount',
                        header: t('المبلغ'),
                        accessorFn: ({ amount }: { amount: number }) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    },
                    {
                        accessorKey: 'due_date',
                        header: t('تاريخ الاستحقاق'),
                        filterVariant: 'date',
                        mantineFilterDateInputProps: {
                            valueFormat: 'YYYY-MM-DD',
                            locale: i18n.language,
                            clearable: true,
                            dateParser: (value: string) => dayjs(value).toDate(),
                        }
                    },
                ]}
                fields={[
                    {
                        type: 'select',
                        label: t('القسم'),
                        name: 'department_id',
                        params: {
                            limit: 9999,
                        },
                        labelAccessor(item) {
                            return `${item.college.name} / ${item.name}`;
                        },
                        initialValue: item => item?.department?.id,
                        required: true,
                        interceptor: 'departments',
                    },
                    {
                        type: 'select',
                        label: t('العام الدراسي'),
                        name: 'academic_year_id',
                        required: true,
                        interceptor: 'academic_years',
                        initialValue: item => item?.academic_year?.id,
                        widthRatio: .5,
                    },
                    {
                        type: 'select',
                        label: t('الدراسة'),
                        name: 'study_type',
                        required: true,
                        initialValue: (item) => item?.study_type,
                        initialData(item) {
                            return [
                                {
                                    value: 'morning',
                                    label: t('صباحي'),
                                },
                                {
                                    value: 'evening',
                                    label: t('مسائي'),
                                },
                            ];
                        },
                        widthRatio: .5,
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                    },
                    {
                        type: 'number',
                        label: t('المبلغ'),
                        name: 'amount',
                        required: true,
                    },
                    {
                        type: 'date',
                        label: t('تاريخ الاستحقاق'),
                        name: 'due_date',
                        required: true,
                    },
                ]}
            />
        </>
    );

}