import { Avatar, Button, Checkbox, Divider, Flex, LoadingOverlay, Modal, Title, Text, Tooltip, Box, useMantineTheme } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStudentDocs, setStudentDocs } from "../api/routes/students_docs";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import { compile } from "@onedoc/react-print";
import { StudentDocs } from "../components/print";
import { DateInput } from "@mantine/dates";

export default function useTakeDocs() {

    const [student, setStudent] = useState<any>(null);
    const [opened, setOpened] = useState(false);

    return {
        takeDocs: (student: any) => {
            setStudent(student);
            setOpened(true);
        },
        takeContext: (
            <TakeModal
                student={student}
                opened={opened}
                setOpened={setOpened}
            />
        )
    }

}

const TakeModal = ({
    student,
    opened,
    setOpened
}: {
    student: any
    opened: boolean
    setOpened: any
}) => {

    const form = useForm({
        initialValues: {
            national_id: false,
            civil_id: false,
            iraqi_national_id: false,
            housing_card: false,
            ration_card: false,
            father_id: false,
            mother_id: false,

            bechelor_certificate: false,
            medical_test: false,
            photos: false,
            student_promise: false,

            max_date: new Date(new Date().setDate(new Date().getDate() + 30)),
        },
    });
    const { t } = useTranslation();
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!student) return;
        form.reset();
        getStudentDocs(student.id).then((data) => {
            setData(data);
        }).catch((error) => {
            notifications.show({
                title: t('خطأ'),
                message: error.message,
                color: 'red',
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [student]);

    useEffect(() => {
        if (!data) return;
        if (typeof data !== typeof {}) return;
        const keys = Object.keys(data);
        keys.forEach((key) => form.setFieldValue(key, data[key].taken == 1));
    }, [data]);

    // national_id and civil_id, on select one of them deselect the other
    useEffect(() => {
        if (form.values.national_id) {
            form.setFieldValue('civil_id', false);
            form.setFieldValue('iraqi_national_id', false);
        }
    }, [form.values.national_id]);

    useEffect(() => {
        if (form.values.civil_id) {
            form.setFieldValue('national_id', false);
        }
    }, [form.values.civil_id]);

    const theme = useMantineTheme();

    if (!student) return null;

    const studentName = student.first_name + ' ' + student.second_name + ' ' + student.third_name + ' ' + student.fourth_name;

    return (
        <Modal opened={opened} onClose={() => setOpened(false)} title={'استلام الوثائق القانونية'}>
            <Flex align={'center'} gap={'xs'}>
                <Avatar src={student?.profile_picture?.sizes?.thumbnail} size={'xl'} radius={'xs'} />
                <Flex direction={'column'} gap={'xs'}>
                    <Title order={4} style={{ textAlign: 'right' }}>{studentName}</Title>
                    <Text size={'sm'} style={{ color: theme.colors.gray[6] }}>{student?.department?.name} - {student?.study_type == 'morning' ? 'صباحي' : 'مسائي'} - المرحلة {student?.stage}</Text>
                </Flex>
            </Flex>
            <form
                onSubmit={form.onSubmit((values: any) => {
                    setLoading(true);
                    let data: any = {};
                    Object.keys(values).forEach((key: any) => {
                        data[key] = values[key] ? 1 : 0;
                    });
                    setStudentDocs(student.id, data).then((res) => {
                        notifications.show({
                            title: t('تم'),
                            message: t('تم تحديث الوثائق بنجاح'),
                            color: 'teal',
                        });
                        setData(res);
                        compile(<StudentDocs
                            student={student}
                            docs={res}
                            max_date={values.max_date}
                        />).then((html) => {
                            const newWindow = window.open();
                            if (!newWindow) return;
                            newWindow.document.write(html);
                            newWindow.document.title = `وثائق الطالب ${studentName}`;
                            newWindow.document.close();
                            newWindow.focus();
                            setTimeout(() => {
                                newWindow.print();
                                setTimeout(() => {
                                    newWindow.close();
                                }, 500);
                            }, 100);
                        })
                    }).catch((error) => {
                        notifications.show({
                            title: t('خطأ'),
                            message: error.message,
                            color: 'red',
                        });
                    }).finally(() => {
                        setLoading(false);
                    });
                })}
            >
                <Flex direction={'column'} gap={'xs'} pt={'md'}>
                    <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                    <Divider label={t('الوثائق الحكومية')} />

                    {[
                        { name: 'national_id', label: 'البطاقة الوطنية الموحدة', disabled: form.values.civil_id },
                        { name: 'civil_id', label: 'هوية الأحوال المدنية', disabled: form.values.national_id },
                        { name: 'iraqi_national_id', label: 'الجنسية العراقية', disabled: form.values.national_id },
                        { name: 'housing_card', label: 'بطاقة السكن' },
                        { name: 'ration_card', label: 'البطاقة التموينية' },
                        { name: 'father_id', label: 'الهوية أو الموحدة للأب' },
                        { name: 'mother_id', label: 'الهوية أو الموحدة للأم' },
                    ].map((doc) => (
                        <Tooltip
                            key={doc.name}
                            label={data?.[doc.name]?.author ? `آخر تعديل كان من قبل ${data?.[doc.name]?.author?.name} في ${dayjs(data?.[doc.name]?.updated_at).format('YYYY-MM-DD الساعة hh:mm A')}` : ''}
                            withArrow
                        >
                            <Box style={{ cursor: 'pointer' }} w={'fit-content'}>
                                <Checkbox
                                    label={t(doc.label)}
                                    checked={(form.values as any)?.[doc.name]}
                                    onChange={(e) => form.setFieldValue(doc.name, e.currentTarget.checked)}
                                    disabled={doc.disabled || false}
                                />
                            </Box>
                        </Tooltip>
                    ))}

                    <Divider label={t('الوثائق الاخرى')} mt={'md'} />

                    {[
                        { name: 'bechelor_certificate', label: 'وثيقة الدراسة الإعدادية (النسخة الأصلية)' },
                        { name: 'medical_test', label: 'الفحص الطبي (النسخة الأصلية)' },
                        { name: 'photos', label: 'صور شخصية (عدد 3)' },
                        { name: 'student_promise', label: 'تعهد الطالب الدراسي' },
                        { name: 'instituteـcertificate', label: 'شهادة المعهد' },
                    ].map((doc) => {
                        // if student.instituteـstudent === 'no' skip instituteـcertificate
                        if (student.instituteـstudent === 'no' && doc.name === 'instituteـcertificate') {
                            return null;
                        }
                        return (
                            <Tooltip
                                key={doc.name}
                                label={data?.[doc.name]?.author ? `آخر تعديل كان من قبل ${data?.[doc.name]?.author?.name} في ${dayjs(data?.[doc.name]?.updated_at).format('YYYY-MM-DD الساعة hh:mm A')}` : ''}
                                withArrow
                            >
                                <Box style={{ cursor: 'pointer' }} w={'fit-content'}>
                                    <Checkbox
                                        label={t(doc.label)}
                                        checked={(form.values as any)?.[doc.name]}
                                        onChange={(e) => form.setFieldValue(doc.name, e.currentTarget.checked)}
                                    />
                                </Box>
                            </Tooltip>
                        )
                    })}

                    {
                        Object.keys(data || {}).filter((key) => ![
                            'national_id',
                            'civil_id',
                            'iraqi_national_id',
                            'housing_card',
                            'ration_card',
                            'father_id',
                            'mother_id',
                            'bechelor_certificate',
                            'medical_test',
                            'photos',
                            'student_promise',
                            'instituteـcertificate',
                        ].includes(key)).length > 0 && (
                            <Divider label={t('وثائق القناة')} mt={'md'} />
                        )
                    }
                    {Object.keys(data || {}).filter((key) => ![
                        'national_id',
                        'civil_id',
                        'iraqi_national_id',
                        'housing_card',
                        'ration_card',
                        'father_id',
                        'mother_id',
                        'bechelor_certificate',
                        'medical_test',
                        'photos',
                        'student_promise',
                        'instituteـcertificate',
                    ].includes(key)).map((doc) => (
                        <Tooltip
                            key={doc}
                            label={data?.[doc]?.author ? `آخر تعديل كان من قبل ${data?.[doc]?.author?.name} في ${dayjs(data?.[doc]?.updated_at).format('YYYY-MM-DD الساعة hh:mm A')}` : ''}
                            withArrow
                        >
                            <Box style={{ cursor: 'pointer' }} w={'fit-content'}>
                                <Checkbox
                                    label={t(doc)}
                                    checked={(form.values as any)?.[doc]}
                                    onChange={(e) => form.setFieldValue(doc, e.currentTarget.checked)}
                                />
                            </Box>
                        </Tooltip>
                    ))}

                    <Divider label={t('أقصى تاريخ للتسليم')} mt={'md'} />
                    <DateInput
                        placeholder={t('أقصى تاريخ للتسليم')}
                        value={form.values.max_date}
                        valueFormat="YYYY-MM-DD"
                        onChange={(date: any) => {
                            // date can't be past 
                            if (date < new Date()) {
                                notifications.show({
                                    title: t('خطأ'),
                                    message: t('لا يمكن أن يكون التاريخ في الماضي'),
                                    color: 'red',
                                });
                                return;
                            }
                            form.setFieldValue('max_date', date || new Date(new Date().setDate(new Date().getDate() + 30)));
                        }}

                    />

                    <Button type="submit" variant="outline" mt={'md'}>
                        {t('إستلام')}
                    </Button>
                </Flex>
            </form>
        </Modal>
    )

}