import './App.css';
import { Box } from '@mantine/core';
import Router from './router';
import { useColorScheme, useDocumentTitle } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

function App() {

    const colorScheme = useColorScheme( "light" );
    const { t } = useTranslation();
    useDocumentTitle( t( "جامعة النور | نظام الطالب" ) );

    return (
        <Box>
            <Router />
        </Box>
    );
}

export default App;
