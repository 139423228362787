import dayjs from 'dayjs';
import { apiWithInterceptor, apiWithoutInterceptor } from './routes';
import { ListResponse } from './types';

class api<T> {

    private endpoint: string
    private interceptor: any

    constructor(endpoint: string, interceptor: boolean) {
        this.endpoint = endpoint
        this.interceptor = interceptor ? apiWithInterceptor : apiWithoutInterceptor
    }

    public get(id?: number): Promise<T> {
        return this.interceptor.get(`${this.endpoint}${id ? `/${id}` : ''}`)
    }

    public list(params?: any): Promise<ListResponse<T[]>> {
        return this.interceptor.get(`${this.endpoint}`, { params })
    }

    public create(data: any): Promise<T> {
        return this.interceptor.post(`${this.endpoint}`, data)
    }

    public update(id: number, data: any): Promise<T> {
        Object.keys(data).forEach(key => {
            if (data[key] === "") {
                data[key] = null
            }
        });
        return this.interceptor.put(`${this.endpoint}/${id}`, data)
    }

    public delete(id: number): Promise<T> {
        return this.interceptor.delete(`${this.endpoint}/${id}`)
    }

}

export default api