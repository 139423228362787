import academic_years from './routes/academicYears';
import account from './routes/account';
import colleges from './routes/colleges';
import departments from './routes/departments';
import students from './routes/students';
import students_metaenum from './routes/students_metaenum';
import tuition_fees from './routes/tuitionfees';
import students_invoices from './routes/studentsinvoices';
import timetable from './routes/timetable';
import students_unpaid_invoices from './routes/studentsunpaidinvoices';
import students_analytics from './routes/studentsanalytics';
import finance_analytics from './routes/financeanalytics';
import students_discounts from './routes/studentsdiscounts';
import users from './routes/users';
import administrative_statements from './routes/administrative_statements';
import external_invoices from './routes/externalinvoices';

export default {
    account,
    colleges,
    departments,
    academic_years,
    students,
    students_metaenum,
    tuition_fees,
    students_invoices,
    students_unpaid_invoices,
    timetable,
    students_analytics,
    finance_analytics,
    students_discounts,
    users,
    administrative_statements,
    external_invoices,
}