import React from "react"
import { AccountType } from "../../api/types"
import useApi from "../../hooks/useApi"
import api from "../../api"
import { AppShell, Avatar, Box, Text, Flex, ScrollArea, Title, Collapse, Button, NavLink, useMantineTheme, alpha, Space } from "@mantine/core"
import logo from '../../assets/img/logo-main.png'
import { useTranslation } from "react-i18next"
import { useHover } from "@mantine/hooks"
import { IconHome2, IconChevronLeft, IconCalendar, IconSchool, IconCurrencyDollar, IconTournament, IconFileDollar, IconReportAnalytics, IconTable, IconDiscount, IconBellPlus, IconUsers, IconFileInvoice, IconDatabaseEdit, IconCalendarEvent } from '@tabler/icons-react';
import { useLocation, useNavigate } from "react-router-dom";
// import './style.css';
import classes from './style.module.css';
import { revoke } from "../../api/routes/auth"

export default () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useMantineTheme();

    const accountApi = useApi<AccountType>({
        key: 'account',
        params: {},
        api: api.account,
    });

    const [capabilities, setCapabilities] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (accountApi.self.data?.capabilities) {
            setCapabilities(accountApi.self.data.capabilities);
        }
    }, [accountApi.self.data]);

    const location = useLocation();

    const menuItems = React.useMemo(() => {
        return [
            {
                label: t('الرئيسية'),
                icon: IconHome2,
                to: '/app/home',
                capability: 'read',
            },
            {
                label: t('الهيكلية'),
                icon: IconTournament,
                to: '/app/structure',
                capability: 'read_colleges',
                children: [
                    {
                        label: t('الكليات'),
                        to: '/app/structure/colleges',
                        capability: 'read_colleges',
                    },
                    {
                        label: t('الأقسام'),
                        to: '/app/structure/departments',
                        capability: 'read_departments',
                    },
                    {
                        label: t('شجرة الأقسام'),
                        to: '/app/structure/tree',
                        capability: 'read_departments',
                    },
                ]
            },
            {
                label: t('جداول الحصص'),
                icon: IconTable,
                to: '/app/timetable',
                capability: 'read_timetable',
            },
            {
                label: t('الأعوام الدراسية'),
                icon: IconCalendar,
                to: '/app/years',
                capability: 'read_academic_years',
            },
            {
                label: t('الطلاب'),
                icon: IconSchool,
                to: '/app/students',
                capability: 'read_students',
                children: [
                    {
                        label: t('جميع الطلبة'),
                        to: '/app/students/list',
                        capability: 'read_students',
                    },
                    {
                        label: t('الطلبة المستمرين'),
                        to: '/app/students/continuing',
                        capability: 'read_students',
                    },
                    {
                        label: t('الطلبة غير المستمرين'),
                        to: '/app/students/suspended',
                        capability: 'read_students',
                    },
                    {
                        label: t('الطلبة المتخرجين'),
                        to: '/app/students/graduated',
                        capability: 'read_students',
                    },
                    {
                        label: t('الطلبة المنسحبين'),
                        to: '/app/students/dropout',
                        capability: 'read_students',
                    },
                    {
                        label: t('الطلبة المستحق تفعيلهم'),
                        to: '/app/students/eligible',
                        capability: 'read_students',
                    },
                    {
                        label: t('الطلبة المعلقين'),
                        to: '/app/students/pending',
                        capability: 'read_students',
                    },
                ]
            },
            {
                label: t('البيانات الوصفية'),
                icon: IconDatabaseEdit,
                to: '/app/metaenum',
                capability: 'read_students',
            },
            {
                label: t('تحديث نسب الغياب'),
                icon: IconCalendarEvent,
                to: '/app/attendance',
                capability: 'edit_attendance',
            },
            {
                label: t('الإشعارات'),
                icon: IconBellPlus,
                to: '/app/notifications',
                capability: 'read_students_notifications',
            },
            {
                label: t('النتائج'),
                icon: IconReportAnalytics,
                to: '/app/results',
                capability: 'read_students_results',
                children: [
                    {
                        label: t('رفع النتائج'),
                        to: '/app/results/upload',
                        capability: 'create_students_results',
                    },
                    {
                        label: t('نتائج الطلبة'),
                        to: '/app/results/list',
                        capability: 'read_students_results',
                    },
                ]
            },
            {
                label: t('الرسوم الدراسية'),
                icon: IconCurrencyDollar,
                to: '/app/tuitionfees',
                capability: 'read_tuition_fees',
            },
            {
                label: t('الخصومات'),
                icon: IconDiscount,
                to: '/app/discounts',
                capability: 'read_students_discounts',
            },
            {
                label: t('الوصولات'),
                icon: IconFileDollar,
                to: '/app/invoices',
                capability: 'read_students_invoices',
                children: [
                    {
                        label: t('الوصولات'),
                        to: '/app/invoices/list',
                        capability: 'read_students_invoices',
                    },
                    {
                        label: t('غير المدفوعة'),
                        to: '/app/invoices/unpaid',
                        capability: 'read_students_invoices',
                    },
                    {
                        label: t('الوصولات الخارجية'),
                        to: '/app/invoices/external',
                        capability: 'read_external_invoices',
                    },
                ]
            },
            {
                label: t('المستخدمين'),
                icon: IconUsers,
                to: '/app/users',
                capability: 'read_users',
            },
        ]
    }, []);

    return (
        <Flex
            direction="column"
            h="100%"
            style={{
                backgroundColor: theme.colors.secondary[9],
            }}
        >
            <AppShell.Section>
                <Flex align="center" justify="center" gap="sm" p="lg" mt="md" direction="column">
                    <img src={logo} alt="logo" style={{ width: 100 }} />
                    <Flex direction="column" align="center">
                        <Title style={theme => ({ color: theme.colors.secondary[0] })} fw={700} order={4}>
                            {t('جامعة النور')}
                        </Title>
                        <Text style={theme => ({ color: theme.colors.gray[6] })} size="sm">
                            {t('نظام معلومات الطالب')}
                        </Text>
                    </Flex>
                </Flex>
            </AppShell.Section>
            <AppShell.Section grow my="md" component={ScrollArea}>
                {
                    menuItems.map((item, index) => {
                        if (item.children) {
                            return (
                                <Collapse key={index} in={capabilities.includes(item.capability)}>
                                    <Box>
                                        <NavLink
                                            label={item.label}
                                            leftSection={<item.icon size="1rem" stroke={1.5} />}
                                            rightSection={<IconChevronLeft size="1rem" stroke={1.5} />}
                                            active={location.pathname.startsWith(item.to)}
                                            variant={location.pathname.startsWith(item.to) ? 'filled' : 'link'}
                                            defaultOpened={location.pathname.startsWith(item.to)}
                                            // className={`navbar-link ${location.pathname.startsWith(item.to) ? 'active' : ''}`}
                                            // className={`${classes['navbar-link']} ${location.pathname.startsWith(item.to) ? 'active' : ''}`}
                                            // autoContrast
                                            // color={theme.colors.secondary[0]}
                                            style={{
                                                color: theme.colors.secondary[0],
                                                backgroundColor: location.pathname.startsWith(item.to) ? theme.colors.secondary[7] : 'transparent',
                                            }}
                                        >
                                            {item.children.map((child, index) => (
                                                <NavLink
                                                    key={index}
                                                    onClick={() => navigate(child.to)}
                                                    label={child.label}
                                                    active={location.pathname == child.to}
                                                    // className={`navbar-link sub-navbar-link ${location.pathname == child.to ? 'active' : ''}`}
                                                    style={{
                                                        color: theme.colors.secondary[0],
                                                        backgroundColor: location.pathname.startsWith(child.to) ? theme.colors.secondary[8] : 'transparent',
                                                        borderRight: `2px solid ${theme.colors.secondary[7]}`,
                                                    }}
                                                />
                                            ))}
                                            <Space h="xs" />
                                        </NavLink>
                                    </Box>
                                </Collapse>
                            )
                        } else {
                            return (
                                <Collapse key={index} in={capabilities.includes(item.capability)}>
                                    <NavLink
                                        onClick={() => navigate(item.to)}
                                        label={item.label}
                                        leftSection={<item.icon size="1rem" stroke={1.5} />}
                                        active={location.pathname == item.to}
                                        // className={`navbar-link ${location.pathname.startsWith(item.to) ? 'active' : ''}`}
                                        style={{
                                            color: theme.colors.secondary[0],
                                            backgroundColor: location.pathname.startsWith(item.to) ? theme.colors.secondary[7] : 'transparent',
                                        }}
                                    />
                                </Collapse>
                            )
                        }
                    })
                }
            </AppShell.Section>
            <AppShell.Section>
                <ProfileCard />
            </AppShell.Section>
        </Flex>
    )

}

const ProfileCard = () => {

    const accountApi = useApi<AccountType>({
        key: 'account',
        params: {},
        api: api.account,
    });

    const { t } = useTranslation();
    const { hovered, ref } = useHover();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    return (
        <Box w="100%" ref={ref}
            style={(theme) => ({
                backgroundColor: alpha(theme.colors.secondary[7], 0.25),
            })}
        >
            <Flex align="center" gap="sm" p="md">
                <Avatar
                    color={theme.colors.primary[9]}
                    variant="filled"
                    style={{
                        border: `2px solid ${theme.colors.secondary[0]}`,
                    }}
                    src={accountApi.self?.data?.profile_picture?.sizes?.thumbnail}
                >
                    {accountApi.self.data?.name ? accountApi.self.data.name[0]?.toUpperCase() : 'A'}
                </Avatar>
                <Flex direction="column" align="start"
                    style={{
                        color: 'white',
                    }}
                >
                    <strong>{accountApi.self.data?.name}</strong>
                    <small>{accountApi.self.data?.email}</small>
                </Flex>
            </Flex>
            <Collapse in={hovered}>
                <Flex align="center" justify="center" gap="xs" p="sm" pt="0" direction="column">
                    <Button fullWidth size="xs"
                        onClick={() => navigate('/app/profile')}
                    >
                        {t('الملف الشخصي')}
                    </Button>
                    <Button
                        fullWidth
                        size="xs"
                        color="red"
                        variant="outline"
                        onClick={() => {
                            revoke();
                            localStorage.removeItem('token');
                            window.location.href = '/';
                        }}
                    >
                        {t('تسجيل الخروج')}
                    </Button>
                </Flex>
            </Collapse>
        </Box>
    )

}