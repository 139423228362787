import { useTranslation } from "react-i18next";
import { Table } from "../../components";
import { Box, Button, Card, FileInput, Flex, TextInput, Table as MantineTable, Alert, LoadingOverlay, Title, Text, NumberFormatter, Pagination } from "@mantine/core";
import { IconFileDownload, IconFileSpreadsheet, IconTable } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { apiWithInterceptor } from "../../api/routes";
import { notifications } from "@mantine/notifications";
import xlsx from "json-as-xlsx"
import dayjs from "dayjs";
import { usePagination, useThrottledValue } from "@mantine/hooks";
import { modals } from "@mantine/modals";

export default () => {

    const { t } = useTranslation();
    const [sending, setSending] = useState(false);
    const [invalidResults, setInvalidResults] = useState([] as any[]);
    const form = useForm({
        initialValues: {
            name: '',
            file: null,
        },
        validate: {
            name: (value) => value.length < 3 && t('يجب أن يكون الاسم أكبر من 3 أحرف'),
            file: (value) => !value && t('يجب إدخال ملف النتائج'),
        },
    });

    return (
        <>
            <Flex p={'md'} direction={'column'} gap={'md'}>
                <Flex gap={'md'}>
                    <Card shadow={'xs'} style={{ cursor: 'pointer' }}
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = '/results.xlsx';
                            link.download = 'results-sample.xlsx';
                            link.click();
                        }}
                    >
                        <Flex direction={'column'} gap={'md'} justify={'center'} align={'center'}>
                            <IconTable size={34} />
                            {t('تحميل ملف النموذج')}
                        </Flex>
                    </Card>
                    <Card shadow={'xs'} style={{ cursor: 'pointer' }}
                        onClick={() => {
                            const link = document.createElement('a');
                            link.href = '/results-multicolumn.xlsx';
                            link.download = 'results-multicolumn-sample.xlsx';
                            link.click();
                        }}
                    >
                        <Flex direction={'column'} gap={'md'} justify={'center'} align={'center'}>
                            <IconTable size={34} />
                            {t('تحميل ملف نموذج متعدد الأعمدة')}
                        </Flex>
                    </Card>
                </Flex>
                <form
                    onSubmit={form.onSubmit((values) => {
                        const id = Math.random().toString(36).substring(2, 15);
                        setSending(true);
                        notifications.show({
                            id: id,
                            title: t('جاري الإرسال'),
                            message: '',
                            color: 'blue',
                            loading: true,
                            autoClose: false,
                        });
                        apiWithInterceptor.post('/students/results/import', values, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                            .then((response) => {
                                let msg = t('تمت العملية بنجاح');
                                form.reset();
                                // @ts-ignore
                                let invalidResults = response.invalid_results || [];
                                if (invalidResults.length) {
                                    setInvalidResults(invalidResults);
                                    msg = t('تمت العملية بنجاح ولكن هناك بعض النتائج غير صحيحة');
                                }

                                notifications.update({
                                    id: id,
                                    title: t('نجاح'),
                                    message: msg,
                                    color: 'green',
                                    loading: false,
                                    autoClose: true,
                                });
                            }).catch((error) => {
                                const msg = error?.message || t('حدث خطأ ما');
                                notifications.update({
                                    id: id,
                                    title: t('حدث خطأ'),
                                    message: msg,
                                    color: 'red',
                                    loading: false,
                                    autoClose: true,
                                });
                            }).finally(() => {
                                setSending(false);
                            });

                    })}
                    style={{ position: 'relative' }}
                >
                    <LoadingOverlay visible={sending} overlayProps={{ blur: 1, radius: "sm", color: '#eee' }} />
                    <Flex direction={'column'} gap={'md'}>
                        <TextInput label={t('عنوان النتيجة')} placeholder={t('نتيحة إالامتحان الفصلي لقسم الحاسوب المرحلة الأولى')} required {...form.getInputProps('name')} />
                        <FileInput label={t('ملف النتائج')} placeholder={t('اختر ملف النتائج')} required accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" leftSection={<IconFileSpreadsheet />} {...form.getInputProps('file')} />
                        <Box>
                            <Button type={'submit'} size={'xs'}>{t('إرسال')}</Button>
                        </Box>
                    </Flex>
                </form>
                <>
                    {
                        Boolean(invalidResults.length) && (
                            <>
                                <Alert color={'red'} title={t('النتائج أدناه غير صحيحة')}>
                                    {t('الرجاء التأكد من صحة النتائج التالية، قد يكون السبب هو بريد الطالب المسجل في النتيجة غير موجود في قاعدة البيانات')}
                                </Alert>
                                <Alert color={'orange'} title={t('تنبيه')}>
                                    {t('يجى إعادة تحميل النتائج التي ظهرت بها مشكلة فقط لتجنب إرسال النتيجة مرتين لنفس الطالب')}
                                </Alert>
                                <Box>
                                    <Button
                                        variant={'outline'}
                                        size={'xs'}
                                        color={'red'}
                                        onClick={() => {
                                            xlsx([
                                                {
                                                    sheet: "Sheet 1",
                                                    columns: [
                                                        // first row in invalid results is the header
                                                        ...invalidResults[0].map((header: any) => ({ label: header, value: header })),
                                                    ],
                                                    content: [
                                                        // first row in invalid results is the header
                                                        ...invalidResults.slice(1).map((result: any) => result.reduce((acc: any, value: any, index: any) => {
                                                            acc[invalidResults[0][index]] = value;
                                                            return acc;
                                                        }, {})),
                                                    ],
                                                }
                                            ], {
                                                fileName: "invalid-results-" + dayjs().format('YYYY-MM-DD-HH-mm-ss') + ".xlsx",
                                                extraLength: 3,
                                                writeMode: "writeFile",
                                                writeOptions: {},
                                                RTL: true,
                                            }, (sheet: any) => {
                                                notifications.show({
                                                    title: t('تم التحميل'),
                                                    message: t('تم تحميل النتائج بنجاح'),
                                                    color: 'green',
                                                });
                                            });
                                        }}
                                    >
                                        <IconFileDownload />
                                        {t('تحميل النتائج غير الصحيحة')}
                                    </Button>
                                </Box>
                                <MantineTable>
                                    <MantineTable.Tbody>
                                        {
                                            invalidResults.map((result, index) => (
                                                <MantineTable.Tr key={index}>
                                                    {
                                                        result.map((value: any, index: any) => (
                                                            <MantineTable.Td key={index}>{value}</MantineTable.Td>
                                                        ))
                                                    }
                                                </MantineTable.Tr>
                                            ))
                                        }
                                    </MantineTable.Tbody>
                                </MantineTable>
                            </>
                        )
                    }
                </>
                <RecentImports />
            </Flex>
            {/* <Table
                interceptor={'colleges'}
                singular={t('كلية')}
                plural={t('الكليات')}
                columns={[
                    {
                        accessorKey: 'name',
                        header: t('الاسم'),
                    },
                    {
                        accessorKey: 'departments_count',
                        header: t('عدد الأقسام'),
                    },
                ]}
                fields={[
                    {
                        type: 'text',
                        label: t('الاسم'),
                        name: 'name',
                        required: true,
                    },
                ]}
            /> */}
        </>
    );

}

const RecentImports = () => {

    const { t } = useTranslation();
    const [total, setTotal] = useState(0);
    const [imports, setImports] = useState([] as any[]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [search, setSearch] = useState('');
    // const searchValue = useThrottledValue(search, 1000);

    // recent imports: /students/results/import
    // delete import: /students/results/import/ with import_id in the body

    const getImports = async (offset: number, limit: number, search?: string) => {
        return apiWithInterceptor.get('/students/results/import', {
            params: {
                offset,
                limit,
                search,
            },
        });
    }

    const deleteImport = async (importId: string) => {
        return apiWithInterceptor.delete('/students/results/import', {
            data: {
                import_id: importId,
            },
        });
    }

    useEffect(() => {
        setLoading(true);
        const limit = perPage;
        const offset = (page - 1) * limit;
        getImports(offset, limit, search)
            .then((response: any) => {
                setTotal(response.total);
                setImports(response.data);
            }).finally(() => {
                setLoading(false);
            });
    }, [page, search]);

    return (
        <Flex direction={'column'} gap={'md'} style={{ position: 'relative' }}>
            <Title order={4}>{t('عمليات الرفع السابقة')}</Title>
            <TextInput
                placeholder={t('بحث')}
                value={search}
                onChange={(event) => {
                    setSearch(event.currentTarget.value);
                }}
                size={'xs'}
            />
            <MantineTable>
                <MantineTable.Thead>
                    <MantineTable.Tr>
                        <MantineTable.Th>{t('الاسم')}</MantineTable.Th>
                        <MantineTable.Th>{t('التاريخ')}</MantineTable.Th>
                        <MantineTable.Th>{t('عدد النتائج')}</MantineTable.Th>
                        <MantineTable.Th></MantineTable.Th>
                    </MantineTable.Tr>
                </MantineTable.Thead>
                <MantineTable.Tbody>
                    {
                        loading && (
                            <MantineTable.Tr>
                                <MantineTable.Td colSpan={4}>
                                    <LoadingOverlay visible={loading} />
                                </MantineTable.Td>
                            </MantineTable.Tr>
                        )
                    }
                    {
                        imports.map((importData: any, index: number) => (
                            <MantineTable.Tr key={index}>
                                <MantineTable.Td>{importData.name}</MantineTable.Td>
                                <MantineTable.Td>{dayjs(importData.created_at).format('YYYY-MM-DD HH:mm:ss')}</MantineTable.Td>
                                <MantineTable.Td><NumberFormatter value={importData.count} thousandSeparator /></MantineTable.Td>
                                <MantineTable.Td>
                                    <Button
                                        size={'xs'}
                                        color={'red'}
                                        onClick={() => {
                                            // confirm modal
                                            modals.openConfirmModal({
                                                title: t('تأكيد الحذف'),
                                                children: t('هل أنت متأكد من حذف هذا الرفع؟'),
                                                onConfirm: () => {
                                                    deleteImport(importData.import_id)
                                                        .then(() => {
                                                            setImports(imports.filter((importDataItem) => importDataItem.import_id !== importData.import_id));
                                                            notifications.show({
                                                                title: t('نجاح'),
                                                                message: t('تم الحذف بنجاح'),
                                                                color: 'green',
                                                            });
                                                        });
                                                },
                                                labels: {
                                                    cancel: t('إلغاء'),
                                                    confirm: t('حذف'),
                                                },
                                            });
                                        }}
                                    >
                                        {t('حذف')}
                                    </Button>
                                </MantineTable.Td>
                            </MantineTable.Tr>
                        ))
                    }
                </MantineTable.Tbody>
            </MantineTable>
            <Pagination
                total={Math.ceil(total / perPage)}
                onChange={(page: number) => {
                    setPage(page);
                }}
                value={page}
            />
        </Flex>
    )

}