import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "../components";
import { ActionIcon, Card, Divider, Flex, LoadingOverlay, Select, Text, TextInput } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { getChannelDocs, setChannelDocs } from "../api/routes/students_docs";

export default () => {

    const { t } = useTranslation();
    const [metaKey, setMetaKey] = useState<string>('registration_channel');

    const enumFields = useMemo(() => ([
        // { value: 'study_type', label: t('نوع الدراسة') },
        { value: 'registration_channel', label: t('قناة القبول') },
        { value: 'registration_gate', label: t('بوابة القبول') },
        { value: 'religion', label: t('الديانة') },
        { value: 'legal_guardian', label: t('ولي الامر') },
        { value: 'nationality', label: t('الجنسية') },
        { value: 'work', label: t('وظيفة الطالب') },
        { value: 'province', label: t('المحافظة') },
        { value: 'current_result', label: t('نتيجة الطالب للعام الحالي') },
    ]), []);

    return (
        <>
            <Table
                interceptor={'students_metaenum'}
                singular={t('عنصر وصفي')}
                plural={t('البيانات الوصفية')}
                params={{
                    meta_key: metaKey,
                }}
                disableCache={true}
                columns={[
                    {
                        accessorKey: 'meta_value',
                        header: t('القيمة'),
                    },
                ]}
                fields={[
                    {
                        type: 'select',
                        label: t('العنوان'),
                        name: 'meta_key',
                        initialValue: () => metaKey,
                        initialData: () => enumFields,
                        interceptor: 'departments',
                        disableQuery: true,
                        hideOn: ['update'],
                    },
                    {
                        type: 'text',
                        label: t('الاسم'),
                        name: 'meta_value',
                        required: true,
                    },
                    {
                        type: 'select',
                        label: t('القيمة الافتراضية'),
                        name: 'is_default',
                        required: false,
                        initialData: () => ([
                            {
                                label: t('نعم'),
                                value: 'yes',
                            },
                            {
                                label: t('كلا'),
                                value: 'no',
                            },
                        ]),
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                    },
                ]}
                beforeTable={(
                    <Select
                        data={enumFields}
                        value={metaKey}
                        onChange={(value) => setMetaKey(value as string)}
                        size="xs"
                        label={t('فئة البيانات')}
                    />
                )}
                fieldsFooter={(form, itemData) => {

                    if (metaKey !== 'registration_channel') return null;
                    if (!itemData) return null;
                    return <RequiredDocs itemData={itemData} />;

                }}
            />
        </>
    );

}

const RequiredDocs = ({
    itemData,
}: {
    itemData: any,
}) => {

    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const actoinRef = useRef<HTMLButtonElement>(null);
    const [requiredDocs, setRequiredDocs] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    useEffect(() => {
        setLoading(true);
        setChannelDocs(itemData.id, { docs: requiredDocs }).then(() => {
            setLoading(false);
        });
    }, [requiredDocs]);

    useEffect(() => {
        getChannelDocs(itemData.id).then((res) => {
            setRequiredDocs(res);
            setLoading(false);
        });
    }, []);

    // on press enter in input field act as click on the action button
    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                e.preventDefault();
                e.stopPropagation();
                actoinRef.current?.click();
            };
        };
        inputRef.current?.addEventListener('keypress', handleKeyPress);
        return () => {
            inputRef.current?.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    return (
        <Flex pt={'xs'}>
            <Card withBorder padding="xs" style={{ width: '100%' }}>
                <Flex gap={'xs'} direction={'column'}>
                    <LoadingOverlay visible={loading} />
                    <Text size={'sm'} fw={'bold'}>{t('الوثائق المطلوبة')}</Text>
                    <Flex gap={'xs'} direction={'column'}>
                        {
                            requiredDocs.map((doc, index) => (
                                <Card padding={'xs'} bg={'gray.1'} key={index}>
                                    <Flex gap={'xs'} align={'center'} justify={'space-between'}>
                                        <Text size={'sm'}>{doc}</Text>
                                        <ActionIcon
                                            color="red"
                                            size="sm"
                                            onClick={() => {
                                                setRequiredDocs(requiredDocs.filter((_, i) => i !== index));
                                            }}
                                        >
                                            <IconTrash size={14} />
                                        </ActionIcon>
                                    </Flex>
                                </Card>
                            ))
                        }
                    </Flex>
                    <Flex gap={'xs'}>
                        <TextInput
                            ref={inputRef}
                            size="xs"
                            placeholder={t('إضافة وثيقة مطلوبة')}
                            w={'100%'}
                        />
                        <ActionIcon
                            ref={actoinRef}
                            onClick={() => {
                                if (!inputRef.current?.value) return;
                                setRequiredDocs([...requiredDocs, inputRef.current.value]);
                                inputRef.current.value = '';
                            }}
                        >
                            <IconPlus size={16} />
                        </ActionIcon>
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    )

};