import { useEffect, useState, useTransition } from "react";
import useApi from "../../hooks/useApi"
import { Button, Divider, Flex, LoadingOverlay, Modal, Select, Text, TextInput, Textarea, Title, useMantineTheme } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconPlus } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import dayjs from "dayjs";
import { modals } from "@mantine/modals";
import { DateInput } from "@mantine/dates";

export default ({
    student_id,
}: {
    student_id: number
}) => {

    const { t } = useTranslation();

    const statementsInterceptor = useApi<any>({
        key: 'administrative_statements',
        params: { student_id },
    });

    const [statements, setStatements] = useState([]);
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        setStatements((statementsInterceptor?.query?.data?.data as any) || []);
    }, [statementsInterceptor.query.data]);

    return (
        <Flex direction={'column'} gap={'xs'} py={'md'}>
            <Divider />
            <StatementModal statement={null} opened={opened} setOpened={setOpened} studentId={student_id} />
            <Flex align={'center'} gap={'xs'} justify={'space-between'}>
                <Title order={6}>{t('الأوامر الإدارية')}</Title>
                <Flex align={'center'} gap={'xs'} justify={'end'}>
                    <Button
                        size={'xs'}
                        onClick={() => {
                            setOpened(true);
                        }}
                        variant={'light'}
                        leftSection={<IconPlus size={16} />}
                    >
                        {t('إضافة أمر إداري')}
                    </Button>
                </Flex>
            </Flex>
            {statements.map((statement: any) => (
                <Statement key={statement.id} statement={statement} studentId={student_id} />
            ))}
        </Flex>
    )

}

const Statement = ({
    statement,
    studentId,
}: {
    statement: any,
    studentId: number,
}) => {

    const [opened, setOpened] = useState(false);
    const { t } = useTranslation();
    const theme = useMantineTheme();

    const statementsInterceptor = useApi<any>({
        key: 'administrative_statements',
        disableQuery: true,
        onDone: () => {
            setOpened && setOpened(false);
        },
    });

    return (
        <Flex key={statement.id}
            align={'center'}
            gap={'xs'}
            justify={'space-between'}
            py={'xs'}
            px={'sm'}
            style={{
                backgroundColor: theme.colors.gray[0],
                borderRadius: theme.radius.sm,
            }}
        >
            <Flex gap={'md'}>
                <Flex direction={'column'} gap={2} w={100}>
                    <Text size={'xs'} style={{ fontWeight: 'bold' }}>#{statement.number}</Text>
                    <Text size={'xs'} style={{ color: theme.colors.gray[6] }}>{dayjs(statement.date).format('YYYY-MM-DD')}</Text>
                    <Text size={'xs'} style={{ color: theme.colors.gray[6] }}>{statement.academic_year.name}</Text>
                </Flex>
                <Text>{statement.description}</Text>
            </Flex>
            <Flex align={'center'} gap={'xs'}>
                <Button
                    size={'xs'}
                    onClick={() => {
                        setOpened(true);
                    }}
                    variant={'light'}
                >
                    {t('تعديل')}
                </Button>
                <Button
                    size={'xs'}
                    onClick={() => {
                        // show confirmation modal
                        modals.openConfirmModal({
                            title: t('هل أنت متأكد من حذف الأمر الإداري؟'),
                            labels: { confirm: t('تأكيد'), cancel: t('إلغاء') },
                            onConfirm: () => {
                                statementsInterceptor.mutation.delete(statement.id);
                            },
                        });
                    }}
                    variant={'light'}
                    color={'red'}
                >
                    {t('حذف')}
                </Button>
                <StatementModal
                    statement={statement}
                    opened={opened}
                    setOpened={setOpened}
                    studentId={studentId}
                />
            </Flex>
        </Flex>
    )

}

const StatementModal = ({
    studentId,
    statement,
    opened,
    setOpened,
}: {
    studentId: number,
    statement?: any,
    opened: boolean,
    setOpened?: (opened: boolean) => void,
}) => {

    const form = useForm();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const statementsInterceptor = useApi<any>({
        key: 'administrative_statements',
        disableQuery: true,
        onDone: () => {
            setLoading(false);
            setOpened && setOpened(false);
        },
    });

    useEffect(() => {
        form.setFieldValue('student_id', studentId);
    }, [studentId]);

    const academicYearsApiInterceptor = useApi<any>({
        key: 'academic_years',
        params: {},
    });

    const [yearsSelect, setYearsSelect] = useState<any[]>(
        statement ? [{
            value: String(statement.academic_year.id),
            label: statement.academic_year.name,
        }] : []
    );

    const [selectedYearId, setSelectedYearId] = useState(
        statement ? String(statement.academic_year.id) : undefined
    );

    useEffect(() => {
        if (statement) {
            form.setFieldValue('id', statement.id);
            form.setFieldValue('description', statement.description);
            form.setFieldValue('body', statement.body);
            form.setFieldValue('date', statement.date);
            form.setFieldValue('number', statement.number);
            form.setFieldValue('academic_year_id', statement.academic_year.id);
            setSelectedYearId(String(statement.academic_year.id));
            form.setFieldValue('student_id', statement.student.id);
        }
    }, [statement]);

    useEffect(() => {
        if (academicYearsApiInterceptor.query.data) {
            let data = academicYearsApiInterceptor.query.data.data?.map((item: any) => {
                return {
                    value: String(item.id),
                    label: item.name,
                };
            });

            // check if student is already inside the data, if so don'y add it
            if (statement && !data.find((item) => item.value === String(statement.academic_year.id))) {
                data = [{
                    value: String(statement.academic_year.id),
                    label: statement.academic_year.name,
                }, ...data];
            }
            setYearsSelect(data);
        }
    }, [academicYearsApiInterceptor.query.data]);

    return (
        <Modal opened={opened} onClose={() => setOpened && setOpened(false)}>
            <Modal.Body>
                <form>
                    <Flex direction={'column'} gap={'xs'} pt={'xs'}>
                        <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                        <Textarea
                            label={t('مختصر الأمر الإداري')}
                            placeholder={t('مختصر الأمر الإداري')}
                            resize="vertical"
                            onChange={(event) => {
                                form.setFieldValue('description', event.currentTarget.value);
                            }}
                            value={form.values.description as any || ''}
                        />
                        <Textarea
                            label={t('الأمر الإداري')}
                            placeholder={t('الأمر الإداري')}
                            resize="vertical"
                            onChange={(event) => {
                                form.setFieldValue('body', event.currentTarget.value);
                            }}
                            value={form.values.body as any || ''}
                        />
                        <Select
                            label={t('العام الدراسي')}
                            placeholder={t('اختر العام الدراسي')}
                            data={yearsSelect}
                            size={'xs'}
                            searchable
                            onSearchChange={(value) => {
                                setYearsSelect([]);
                                setSelectedYearId(undefined);
                                academicYearsApiInterceptor.setParam('search', value);
                            }}
                            onChange={(value) => {
                                form.setFieldValue('academic_year_id', value);
                            }}
                            value={selectedYearId}
                        />
                        <TextInput
                            label={t('رقم الأمر الإداري')}
                            placeholder={t('رقم الأمر الإداري')}
                            onChange={(event) => {
                                form.setFieldValue('number', event.currentTarget.value);
                            }}
                            value={form.values.number as any || ''}
                        />
                        <DateInput
                            label={t('تاريخ الأمر الإداري')}
                            placeholder={t('تاريخ الأمر الإداري')}
                            value={form.values.date ? dayjs(form.values.date as any).toDate() : undefined}
                            onChange={(date) => {
                                form.setFieldValue('date', dayjs(date).format('YYYY-MM-DD HH:mm:ss'));
                            }}
                            valueFormat="YYYY-MM-DD"
                        />
                        <Flex justify={'end'}>
                            <Button
                                // type={'submit'}
                                onClick={() => {
                                    setLoading(true);
                                    if (form.values.id) {
                                        statementsInterceptor.mutation.update(form.values.id as any, form.values);
                                    } else {
                                        statementsInterceptor.mutation.create(form.values);
                                    }
                                }}
                            >
                                {statement ? 'حفظ' : 'إضافة'}
                            </Button>
                        </Flex>
                    </Flex>
                </form>
            </Modal.Body>
        </Modal>
    )

}