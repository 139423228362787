import { apiWithInterceptor } from '.';
import api from '../api';
import { CollegeType, ApiConfig } from '../types';

export const administrativeStatements = new api<any>('/administrative-statements', true);

export default {
    list: (params?: any) => administrativeStatements.list(params),
    get: (id: number) => administrativeStatements.get(id),
    update: (id: number, data: any) => administrativeStatements.update(id, data),
    create: (data: any) => administrativeStatements.create(data),
    delete: (id: number) => administrativeStatements.delete(id),
} as ApiConfig<any>;

export const createStatement = async (data: any): Promise<any> =>
    apiWithInterceptor.post(`/administrative-statements`, data);