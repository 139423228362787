import { Navigate, useRoutes } from 'react-router-dom';

import {
    DashboardLayout,
    HalfScreenLayout,
} from './layout';

import {
    AcademicYearsView,
    CollegesView,
    DepartmentsView,
    LoginView,
    StudentsMetaenumView,
    StudentsView,
    TuitionFeesView,
    StudentsInvoicesView,
    StudentsResultsView,
    TimetableView,
    StudentsUnpaidInvoicesView,
    HomeView,
    DepartmentsTreeView,
    StudentsDiscountsView,
    NotificationView,
    ProfileView,
    UsersView,
    StudentsPendingView,
    StudentsGraduatedView,
    StudentsDropoutView,
    StudentsEligibleView,
    SystemOptions,
    AdministrativeStatementsView,
    ListStudentsResultsView,
    StudentsSuspendedView,
    StudentsAllView,
    StudentsAttendanceView,
    ExternalInvoicesView,
} from './views';

export default function Router() {

    return useRoutes([
        {
            path: '/',
            element: <HalfScreenLayout />,
            children: [
                {
                    path: '',
                    element: <LoginView />
                },
            ]
        },
        {
            path: '/app',
            element: <DashboardLayout />,
            children: [
                {
                    path: '',
                    element: <Navigate to="/app/home" />
                },
                {
                    path: 'home',
                    element: <HomeView />
                },
                {
                    path: 'options',
                    element: <SystemOptions />
                },
                {
                    path: 'structure',
                    children: [
                        {
                            path: 'colleges',
                            element: <CollegesView />
                        },
                        {
                            path: 'departments',
                            element: <DepartmentsView />
                        },
                        {
                            path: 'tree',
                            element: <DepartmentsTreeView />
                        },
                    ]
                },
                {
                    path: 'years',
                    element: <AcademicYearsView />,
                },
                {
                    path: 'administrative-statements',
                    element: <AdministrativeStatementsView />,
                },
                {
                    path: 'students',
                    children: [
                        {
                            path: 'list',
                            element: <StudentsAllView />
                        },
                        {
                            path: 'continuing',
                            element: <StudentsView />
                        },
                        {
                            path: 'suspended',
                            element: <StudentsSuspendedView />
                        },
                        {
                            path: 'dropout',
                            element: <StudentsDropoutView />
                        },
                        {
                            path: 'graduated',
                            element: <StudentsGraduatedView />
                        },
                        {
                            path: 'pending',
                            element: <StudentsPendingView />
                        },
                        {
                            path: 'eligible',
                            element: <StudentsEligibleView />
                        },
                    ],
                },
                {
                    path: 'metaenum',
                    element: <StudentsMetaenumView />
                },
                {
                    path: 'tuitionfees',
                    element: <TuitionFeesView />,
                },
                {
                    path: 'notifications',
                    element: <NotificationView />,
                },
                {
                    path: 'profile',
                    element: <ProfileView />,
                },
                {
                    path: 'users',
                    element: <UsersView />,
                },
                {
                    path: 'discounts',
                    element: <StudentsDiscountsView />,
                },
                {
                    path: 'timetable',
                    element: <TimetableView />,
                },
                {
                    path: 'attendance',
                    element: <StudentsAttendanceView />,
                },
                {
                    path: 'results',
                    children: [
                        {
                            path: 'list',
                            element: <ListStudentsResultsView />
                        },
                        {
                            path: 'upload',
                            element: <StudentsResultsView />
                        }
                    ],
                },
                {
                    path: 'invoices',
                    children: [
                        {
                            path: 'list',
                            element: <StudentsInvoicesView />
                        },
                        {
                            path: 'unpaid',
                            element: <StudentsUnpaidInvoicesView />
                        },
                        {
                            path: 'external',
                            element: <ExternalInvoicesView />
                        },
                    ]
                },
            ]
        },
        {
            path: '*',
            element: "404"
        },
    ]);

}


