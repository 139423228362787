import { useTranslation } from "react-i18next";
import { Accordion, ActionIcon, Alert, Button, Card, Flex, Loader, Table, Text, TextInput } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import AsyncSelect from 'react-select/async';
import { getStudents } from "../../api/routes/students";
import { getStudentResults, updateStudentResult } from "../../api/routes/students_results";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import useUserCan from "../../hooks/useUserCan";
import { apiWithInterceptor } from "../../api/routes";
import { notifications } from "@mantine/notifications";


export default () => {

    const { t } = useTranslation();
    const [studentId, setStudentId] = useState<any>(null);
    const [results, setResults] = useState<any[]>([]);
    const [loadingResults, setLoadingResults] = useState<boolean>(false);

    const loadOptions = useMemo(() => (inputValue: string, callback: (options: any[]) => void) => {
        getStudents({ search: inputValue }).then((response) => {
            if (response.data) {
                let data = response.data?.map((item: any) => {
                    return {
                        value: String(item.id),
                        label: `${item.first_name} ${item.second_name} ${item.third_name} ${item.fourth_name} / ${item.department.college.name} / ${item.department.name} / ${item.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${item.stage}`,
                    };
                });
                callback(data);
            }
        });
    }, []);

    useEffect(() => {
        setResults([]);
        if (studentId) {
            setLoadingResults(true);
            getStudentResults(studentId).then((response) => {
                if (response.data) {
                    setResults(response.data);
                }
                setLoadingResults(false);
            });
        }
    }, [studentId]);


    return (
        <Flex
            direction={"column"}
            gap={"md"}
            p={"md"}
        >
            <Flex w={'100%'}>
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions
                    isClearable
                    isSearchable
                    isRtl
                    styles={{
                        container: (styles: any) => ({
                            ...styles,
                            width: '100%',
                        }),
                    } as any}
                    onChange={(selected: any) => {
                        if (selected && selected.value) {
                            setStudentId(selected.value);
                        } else {
                            setStudentId(null);
                        }
                    }}
                />
            </Flex>
            {
                loadingResults && (
                    <Loader />
                )
            }
            {
                !studentId && (
                    <Alert color="yellow" title={t('اختر الطالب لعرض النتائج')} />
                )
            }
            {
                !loadingResults && !results.length && studentId && (
                    <Alert color="red" title={t('لا توجد نتائج لهذا الطالب')} />
                )
            }
            {
                Boolean(studentId && results.length) && (
                    <Accordion
                        defaultValue="0"
                        variant="contained"
                    >
                        {
                            results.map((result: any, index: number) => (
                                <ResultItem
                                    key={index}
                                    index={index}
                                    result={result}
                                />
                            ))
                        }
                    </Accordion>
                )
            }
        </Flex>
    );

}

const ResultItem = ({
    index,
    result
}: {
    index?: number,
    result: any
}) => {

    const { t } = useTranslation();
    const [resultObject, setResultObject] = useState<any>(result);
    const [updateData, setUpdateData] = useState<any>({});
    const [editing, setEditing] = useState<boolean>(false);
    useEffect(() => {
        setResultObject(result);
        setUpdateData(result.data);
    }, [result]);

    const { userCan } = useUserCan();

    if (!resultObject) {
        return <></>;
    }

    return (
        <Accordion.Item value={String(typeof index !== typeof undefined ? index : resultObject.name)} px={'md'}>
            <Accordion.Control>
                <Flex gap={'md'}>
                    <Text size="md" fw={'bold'}>
                        {resultObject.name}
                    </Text>
                    {
                        userCan('edit_students_results') && (
                            <ActionIcon
                                size="sm"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setEditing(!editing);
                                    setUpdateData(resultObject.data);
                                }}
                            >
                                <IconEdit size={16} />
                            </ActionIcon>
                        )
                    }
                    {
                        userCan('delete_students_results') && (
                            <ActionIcon
                                size="sm"
                                color="red"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // delete students/results/(?P<id>\d+)
                                    apiWithInterceptor.delete(`/students/results/${resultObject.id}`).then(() => {
                                        setResultObject(null);
                                        notifications.show({
                                            title: t('تم حذف النتيجة بنجاح'),
                                            message: t('تم حذف النتيجة بنجاح'),
                                            color: 'blue',
                                        });
                                    });
                                }}
                            >
                                <IconTrash size={16} />
                            </ActionIcon>
                        )
                    }
                </Flex>
            </Accordion.Control>
            <Accordion.Panel>
                <Table>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>{t('المادة')}</Table.Th>
                            <Table.Th>{t('الدرجة')}</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {
                            Object.keys(resultObject.data).map((key) => (
                                <Table.Tr>
                                    <Table.Td>{key}</Table.Td>
                                    <Table.Td>
                                        {/* {
                                            editing ? (
                                                <TextInput
                                                    defaultValue={resultObject.data[key]}
                                                    onChange={(e) => {
                                                        setUpdateData({
                                                            ...updateData,
                                                            [key]: e.currentTarget.value,
                                                        });
                                                    }}
                                                />
                                            ) : resultObject.data[key]
                                        } */}
                                        {
                                            // results can be either a value or object with many values key => value
                                            typeof resultObject.data[key] !== 'object' ? (
                                                editing ? (
                                                    <TextInput
                                                        defaultValue={resultObject.data[key]}
                                                        onChange={(e) => {
                                                            setUpdateData({
                                                                ...updateData,
                                                                [key]: e.currentTarget.value,
                                                            });
                                                        }}
                                                    />
                                                ) : resultObject.data[key]
                                            ) : (
                                                Object.keys(resultObject.data[key]).map((subKey) => (
                                                    <Flex gap={'md'}>
                                                        <Text>{subKey}</Text>
                                                        {
                                                            editing ? (
                                                                <TextInput
                                                                    defaultValue={resultObject.data[key][subKey]}
                                                                    onChange={(e) => {
                                                                        setUpdateData({
                                                                            ...updateData,
                                                                            [key]: {
                                                                                ...updateData[key],
                                                                                [subKey]: e.currentTarget.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                />
                                                            ) : resultObject.data[key][subKey]
                                                        }
                                                    </Flex>
                                                ))
                                            )
                                        }
                                    </Table.Td>
                                </Table.Tr>
                            ))
                        }
                    </Table.Tbody>
                </Table>
                {
                    editing && (
                        <Flex w={'100%'} justify={'flex-end'} gap={'sm'} px={'sm'}>
                            <Button
                                size="xs"
                                onClick={() => {
                                    updateStudentResult(resultObject.id, updateData).then((res) => {
                                        setEditing(false);
                                        setResultObject(res);
                                    });
                                }}
                            >
                                {t('حفظ')}
                            </Button>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setEditing(false);
                                    setResultObject(result);
                                }}
                                color={'red'}
                                variant={'outline'}
                            >
                                {t('إلغاء')}
                            </Button>
                        </Flex>
                    )
                }
            </Accordion.Panel>
        </Accordion.Item>
    )

}