import { useTranslation } from "react-i18next";
import StudentsList from "./studentsList";
import { Progress } from "@mantine/core";

export default () => {

    const { t } = useTranslation();

    return (
        <>
            <Progress value={100} w={'100%'} radius={0} color="orange" />
            <StudentsList
                studentStatus="pending"
                singular={t('طالب')}
                plural={t('الطلبة المعلقين')}
            />
        </>
    );

};