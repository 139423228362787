import { apiWithInterceptorMultipart } from '.';
import api from '../api';
import { AccountType, ApiConfig } from '../types';

export const account = new api<AccountType>('/account', true);

export default {
    list: (params?: any) => account.list(params),
    update: (id: number, data: any) => account.update(id, data)
} as ApiConfig<AccountType>;

export const updateAccountPicture = async (data: {
    picture: any,
}) : Promise<any> => apiWithInterceptorMultipart.post(`/account/1/picture`, {picture: data.picture});