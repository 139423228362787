import { useTranslation } from "react-i18next";
import { Table } from "../components";
import dayjs from "dayjs";

export default () => {

    const { t } = useTranslation();

    return (
        <>
            <Table
                interceptor={'academic_years'}
                singular={t('عام دراسي')}
                plural={t('الأعوام الدراسية')}
                columns={[
                    {
                        accessorKey: 'name',
                        header: t('الاسم'),
                    },
                    {
                        accessorFn: (row: any) => dayjs(row.start_date).format('YYYY-MM-DD'),
                        header: t('اليوم الأول'),
                    },
                    {
                        accessorFn: (row: any) => dayjs(row.end_date).format('YYYY-MM-DD'),
                        header: t('اليوم الأخير'),
                    },
                    {
                        accessorFn: (row: any) => dayjs(row.end_date).diff(dayjs(row.start_date), 'days') + ' ' + t('يوم'),
                        header: t('المدة'),
                        enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                ]}
                fields={[
                    {
                        type: 'text',
                        label: t('الاسم'),
                        name: 'name',
                        required: true,
                    },
                    {
                        type: 'date',
                        label: t('اليوم الأول'),
                        name: 'start_date',
                        required: true,
                    },
                    {
                        type: 'date',
                        label: t('اليوم الأخير'),
                        name: 'end_date',
                        required: true,
                    },
                    {
                        type: 'select',
                        label: t('القيمة الافتراضية'),
                        name: 'is_default',
                        required: false,
                        initialData: () => ([
                            {
                                label: t('نعم'),
                                value: 'yes',
                            },
                            {
                                label: t('كلا'),
                                value: 'no',
                            },
                        ]),
                        disableQuery: true,
                        interceptor: 'students_metaenum',
                        hideOn: ['create'],
                    },
                ]}
            />
        </>
    );

}