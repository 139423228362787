import { Alert, Button, Checkbox, Drawer, Flex, Loader, Modal, Popover, Progress, ScrollArea, Table, Text, ThemeIcon, useMantineTheme } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import useApi from "../../hooks/useApi";
import { Result } from "react-spreadsheet-import/types/types";
import { IconCheck, IconDatabaseImport, IconX } from "@tabler/icons-react";
import xlsx from "json-as-xlsx";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";
import { createStudent, updateStudent } from "../../api/routes/students";
import { useSetState } from "@mantine/hooks";
import { createStatement } from "../../api/routes/administrative_statements";

export default ({
    onSubmit,
}: {
    onSubmit?: (data: any, file: File) => void;
}) => {

    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useSetState({
        update_by_number: false,
    });

    const academicYearsApiInterceptor = useApi<any>({ key: 'academic_years', params: { limit: 99999 } });

    const fields = useMemo(() => ([
        {
            label: t("معرف الطالب (ID)"),
            key: "student_id",
            alternateMatches: ["المعرف", "ID"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("رقم الهوية"),
            key: "id_number",
            alternateMatches: ["رقم الهوية"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("البريد الالكتروني"),
            key: "email",
            alternateMatches: ["البريد الالكتروني", "البريد"],
            fieldType: {
                type: "input",
            },
        },
        {
            label: t("مختصر الامر الإداري"),
            key: "description",
            alternateMatches: ["مختصر الامر الإداري"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    value: true,
                    errorMessage: t("الرجاء إدخال مختصر الامر الإداري"),
                    level: "error",
                },
            ],
        },
        {
            label: t("الامر الإداري"),
            key: "body",
            alternateMatches: ["الامر الإداري", "الا وامر الإدارية"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    value: true,
                    errorMessage: t("الرجاء إدخال الامر الإداري"),
                    level: "error",
                },
            ],
        },
        {
            label: t("عدد الامر"),
            key: "number",
            alternateMatches: ["عدد الامر"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "required",
                    value: true,
                    errorMessage: t("الرجاء إدخال عدد الامر"),
                    level: "error",
                },
            ],
        },
        {
            label: t("تاريخ الامر"),
            key: "date",
            alternateMatches: ["تاريخ الامر"],
            fieldType: {
                type: "input",
            },
            validations: [
                {
                    rule: "regex",
                    value: "^$|^\\d{4}[-/]\\d{1,2}[-/]\\d{1,2}$",
                    errorMessage: t("الرجاء إدخال التاريخ بالصيغة الصحيحة YYYY-MM-DD"),
                    level: "error",
                },
                {
                    rule: "required",
                    value: true,
                    errorMessage: t("الرجاء إدخال تاريخ الامر"),
                    level: "error",
                },
            ],
        },
        {
            label: t("عام الامر"),
            key: "academic_year_id",
            alternateMatches: ["عام الامر"],
            fieldType: {
                type: "select",
                options: academicYearsApiInterceptor.query.data?.data?.map((item: any) => {
                    let name = item.name;
                    let parts = name.split("-");
                    // reverse
                    parts = parts.reverse();
                    // implode
                    name = parts.join("-");
                    return {
                        label: name,
                        value: item.id,
                    };
                }) || [],
            },
            validations: [
                {
                    rule: "required",
                    value: true,
                    errorMessage: t("الرجاء إدخال عام الامر"),
                    level: "error",
                },
            ],
        },
    ]), [
        academicYearsApiInterceptor.query.data,
    ]);

    const [data, setData] = useState<any>(null);

    return (
        <>
            <Button
                size="xs"
                onClick={() => {
                    // setIsOpen(true);
                    setModalOpen(true);
                }}
                leftSection={<IconDatabaseImport size={16} />}
                variant="outline"
            >
                {t("إستيراد الأوامر الإدارية")}
            </Button>
            <Modal opened={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Title>{t("خيارات الاستيراد")}</Modal.Title>
                <Modal.Body>
                    <Flex direction="column" gap="xs" pt={'md'}>
                        <Alert color="orange" title={t("تنبيه")}>
                            {t("سيتم رفع الأوامر الإدارية بالإعتماد إما على المعرف (id) أو البريد الإلكتروني أو رقم الهوية، يجب إرفاق واحد أو أكثر من هذه الحقول")}
                        </Alert>
                        <Checkbox
                            label={t("قم بتحديث الأمر الإداري في حال تطابق رقم الأمر الإداري")}
                            checked={options.update_by_number ?? false}
                            onChange={(event) => {
                                setOptions({ update_by_number: event.currentTarget.checked });
                            }}
                        />
                        <Flex gap={'xs'}>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                color="red"
                            >
                                {t("إغلاق")}
                            </Button>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setModalOpen(false);
                                    setIsOpen(true);
                                }}
                            >
                                {t("التالي")}
                            </Button>
                        </Flex>
                    </Flex>
                </Modal.Body>
            </Modal>
            <ReactSpreadsheetImport
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                autoMapSelectValues={true}
                onSubmit={(data: Result<any>, file: File) => {
                    if (data.invalidData.length > 0) {
                        console.log("invalidData", data.invalidData);

                        alert(t("تم العثور على بيانات غير صحيحة في الجدول"));
                        return;
                    }

                    const validData = data.validData || [];

                    if (!validData.length) {
                        alert(t("لم يتم العثور على بيانات صحيحة في الجدول"));
                        return;
                    }

                    setData(validData);
                    onSubmit && onSubmit(validData, file);

                }}
                fields={fields}
            />
            <ImportDrawer data={data} options={options} />
        </>
    )

}

export const ImportDrawer = ({
    data,
    options,
}: {
    data: any;
    options?: any;
}) => {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [importedData, setImportedData] = useState<any>(null);
    const [invalidImports, setInavlidImports] = useState<any>([]);
    const [cursor, setCursor] = useState(-1);
    const [completed, setCompleted] = useState(false);

    const [progress, setProgress] = useState(0);

    const studentsApiInterceptor = useApi<any>({
        key: 'students',
        disableNotification: true,
        // disableQuery: true,
        onDone: (action, response) => {
            const item = {
                row: data[cursor],
                action,
                success: !Boolean(response.message),
                response,
            };
            importedData.push(item);
            if (!item.success) {
                invalidImports.push(item);
            }
            setCursor((prev) => prev + 1);

            const total = data.length;
            const current = importedData.length;
            const prg = (current / total) * 100;
            setProgress(Number(prg).toFixed(2) as any);
        }
    });

    useEffect(() => {
        if (data) {
            setImportedData([]);
            setInavlidImports([]);
            setCompleted(false);
            setIsOpen(true);
            setCursor(0);
        }
    }, [data]);

    const handleResponse = (response: any) => {
        console.log(response);

        const item = {
            row: data[cursor],
            success: !Boolean(response.message),
            response,
        };
        importedData.push(item);
        if (!item.success) {
            invalidImports.push(item);
        }
        setCursor((prev) => prev + 1);

        const total = data.length;
        const current = importedData.length;
        const prg = (current / total) * 100;
        setProgress(Number(prg).toFixed(2) as any);
    };

    useEffect(() => {
        if (!data || cursor === data?.length) {
            setCompleted(true);
            return;
        }

        const row = data[cursor];
        const newRow: any = {};
        Object.keys(row).forEach((key: string) => {
            if (row[key]) {
                newRow[key] = row[key];
            }
        });

        createStatement({
            ...newRow,
            options,
        }).then((response) => {
            handleResponse(response);
        }).catch((error) => {
            handleResponse(error);
        });
    }, [cursor]);

    return (
        <Drawer
            title={t("استيراد الأوامر الإدارية")}
            opened={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            closeOnClickOutside={false}
            position="right"
            size={'calc(100vw - 320px)'}
        >
            <Flex w={'100%'} p={'md'} gap={'xs'}>
                <Flex gap={'xs'}>
                    {
                        completed ? (
                            <ThemeIcon radius="xl" color={theme.colors.teal[6]} variant="filled">
                                <IconCheck size={20} />
                            </ThemeIcon>
                        ) : (
                            <Loader />
                        )
                    }
                    <Text fw={'bold'}>
                        {
                            completed ? t("اكتمل الاستيراد") : (t("جاري الاستيراد") + "...")
                        }
                    </Text>
                </Flex>
                <Flex direction={"column"} w={'100%'}>
                    <Progress.Root radius="xs" size="xl" w={'100%'}>
                        <Progress.Section value={progress} striped animated={!completed}>
                            <Progress.Label>{progress}%</Progress.Label>
                        </Progress.Section>
                    </Progress.Root>
                    <Text size={'xs'} color={'gray'}>{importedData?.length} / {data?.length}</Text>
                </Flex>
            </Flex>
            {
                (Boolean(invalidImports.length) || completed) && (
                    <Flex justify="center" align="center" direction="column" style={{ height: 200 }}>
                        <Alert title={t("تنبيه")} color={invalidImports.length ? 'red' : 'teal'} w={'100%'}>
                            <Flex direction={'column'} gap={'xs'}>
                                <Text fs={'sm'}>{invalidImports.length ? t("لديك بعض الصفوف لم يتم إستيرادها بنجاح") : t("تم استيراد البيانات بنجاح")}</Text>
                                {
                                    invalidImports.length > 0 && (
                                        <Button
                                            color={'red'}
                                            size={'xs'}
                                            onClick={() => {
                                                const headers = [
                                                    ...Object.keys(invalidImports[0].row),
                                                    "success",
                                                    "message",
                                                ];
                                                xlsx([
                                                    {
                                                        sheet: "Sheet 1",
                                                        columns: [
                                                            ...headers.map((header: any) => ({ label: header, value: header })),
                                                        ],
                                                        content: [
                                                            ...invalidImports.map((item: any) => Object.keys(headers).reduce((acc: any, key: any) => {
                                                                const realKey = headers[key];
                                                                if (realKey === 'success') {
                                                                    return {
                                                                        ...acc,
                                                                        [realKey]: item.success ? 'نعم' : 'لا',
                                                                    }
                                                                } else if (realKey === 'message') {
                                                                    return {
                                                                        ...acc,
                                                                        [realKey]: item.response?.message || '',
                                                                    }
                                                                }
                                                                return {
                                                                    ...acc,
                                                                    [realKey]: item.row[realKey] || '',
                                                                }
                                                            }, {})),
                                                        ],
                                                    }
                                                ], {
                                                    fileName: "invalid-results-" + dayjs().format('Invalid students import YYYY-MM-DD-HH-mm-ss') + ".xlsx",
                                                    extraLength: 3,
                                                    writeMode: "writeFile",
                                                    writeOptions: {},
                                                    RTL: true,
                                                }, (sheet: any) => {
                                                    notifications.show({
                                                        title: t('تم التحميل'),
                                                        message: t('تم تحميل النتائج بنجاح'),
                                                        color: 'green',
                                                    });
                                                });
                                            }}
                                        >
                                            {t("تحميل الصفوف غير المستوردة")}
                                        </Button>
                                    )
                                }
                            </Flex>
                        </Alert>
                    </Flex>
                )
            }
            {
                Boolean(invalidImports.length) /*&& Boolean(data) && data.length > 0*/ && (
                    <Flex direction="column">
                        <Text size="sm" fw="bold">{t("آخر 50 صف غير مستورد من أصل")} {invalidImports.length} {t("صف غير مستورد")}</Text>
                        <ScrollArea>
                            <Table>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th>{t("الحالة")}</Table.Th>
                                        {Object.keys(data[0]).map((key: string) => (
                                            <Table.Th key={key}>{key}</Table.Th>
                                        ))}
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {data.slice(-50).map((row: any, index: number) => (importedData[index]?.success || cursor <= index) ? null : (
                                        <Table.Tr key={index} style={Boolean(importedData.length) ? { backgroundColor: importedData[index]?.success ? theme.colors.teal[0] : theme.colors.red[0] } : {}}>
                                            <Table.Td>
                                                {
                                                    cursor <= index ? (
                                                        <Loader size="sm" />
                                                    ) : (
                                                        importedData[index]?.success ? (
                                                            <ThemeIcon radius="xl" color={theme.colors.teal[6]} variant="filled">
                                                                <IconCheck size={13} />
                                                            </ThemeIcon>
                                                        ) : (
                                                            <Popover width={200} position="bottom" withArrow shadow="md">
                                                                <Popover.Target>
                                                                    <ThemeIcon radius="xl" color={theme.colors.red[6]} variant="filled" style={{ cursor: 'pointer' }}>
                                                                        <IconX size={13} />
                                                                    </ThemeIcon>
                                                                </Popover.Target>
                                                                <Popover.Dropdown>
                                                                    <Text size="xs">{importedData[index]?.response?.message || t("حدث خطأ أثناء الاستيراد")}</Text>
                                                                </Popover.Dropdown>
                                                            </Popover>
                                                        )
                                                    )
                                                }
                                            </Table.Td>
                                            {Object.keys(row).map((key: string) => (
                                                <Table.Td key={key}>{row[key]}</Table.Td>
                                            ))}
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                            </Table>
                        </ScrollArea>
                    </Flex>
                )
            }
        </Drawer>
    )

}