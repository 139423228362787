import { useTranslation } from "react-i18next";
import { Table } from "../components";
import { Text } from "@mantine/core";

export default () => {

    const { t } = useTranslation();

    return (
        <>
            <Table
                interceptor={'users'}
                singular={t('مستخدم')}
                plural={t('المستخدمين')}
                columns={[
                    {
                        accessorKey: 'name',
                        header: t('الإسم'),
                    },
                    {
                        accessorKey: 'email',
                        header: t('البريد الإلكتروني'),
                    },
                    {
                        accessorKey: 'role',
                        header: t('الدور'),
                    },
                    {
                        accessorKey: 'registered',
                        header: t('تاريخ الإنشاء'),
                        accessorFn: ({ registered }: any) => (
                            <Text size={'sm'}>{registered}</Text>
                        ),
                        // enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                ]}
                fields={[
                    {
                        type: 'text',
                        label: t('الاسم'),
                        name: 'name',
                        required: true,
                    },
                    {
                        type: 'text',
                        label: t('البريد الإلكتروني'),
                        name: 'email',
                        required: true,
                    },
                    {
                        type: 'password',
                        label: t('كلمة المرور'),
                        name: 'password',
                        required: false,
                        disableQuery: true,
                    },
                    {
                        type: 'select',
                        label: t('الدور'),
                        name: 'role',
                        required: true,
                        initialValue: (item) => item?.role,
                        initialData(item) {
                            return [
                                { label: t('مدير النظام'), value: 'system_admin' },
                                { label: t('مسؤول التسجيل'), value: 'registration_admin' },
                                { label: t('موظف تسجيل'), value: 'registration_employee' },
                                { label: t('المسؤول المالي'), value: 'financial_admin' },
                                { label: t('موظف مالي'), value: 'financial_employee' },
                                { label: t('موقوف'), value: 'suspended' },
                                { label: t('مشاهد'), value: 'viwer' },
                                { label: t('اللجنة الامتحانية'), value: 'examination_committee' },
                                { label: t('مدير قسم'), value: 'department_manager' },
                            ];
                        },
                        disableQuery: true,
                        interceptor: 'users',
                    },
                    {
                        type: 'multiselect',
                        label: t('تحديد وصول الأقسام'),
                        name: 'limit_department',
                        visibleOn: {
                            field: 'role',
                            value: 'department_manager',
                        },
                        params: {
                            limit: 9999,
                        },
                        labelAccessor(item) {
                            return `${item.college.name} / ${item.name}`;
                        },
                        initialValue: item => item?.limit_department?.map((item: any) => item.id),
                        interceptor: 'departments',
                    },
                ]}
            />
        </>
    );

}