import { Alert, Button, Drawer, Flex, Loader, Modal, Popover, Progress, ScrollArea, Table, Text, ThemeIcon, useMantineTheme } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { Result } from "react-spreadsheet-import/types/types";
import { IconCheck, IconDatabaseImport, IconX } from "@tabler/icons-react";
import xlsx from "json-as-xlsx";
import dayjs from "dayjs";
import { notifications } from "@mantine/notifications";
import { getAllChannelsDocs, setStudentDocs, setStudentDocsByKey } from "../../api/routes/students_docs";

export default ({
    onSubmit,
}: {
    onSubmit?: (data: any, file: File) => void;
}) => {

    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<any>(null);
    const [channelsDocs, setChannelsDocs] = useState<any>(null);

    const fields = useMemo(() => {

        let docs = [
            {
                label: t("المعرف (ID)"),
                key: "id",
                alternateMatches: ["المعرف", "ID"],
                fieldType: {
                    type: "input",
                },
            },
            {
                label: t("البريد الإلكتروني"),
                key: "email",
                alternateMatches: ["البريد", "الإلكتروني", "email"],
                fieldType: {
                    type: "input",
                },
            },
            {
                label: t("رقم الهوية"),
                key: "id_number",
                alternateMatches: ["رقم الهوية", "id_number"],
                fieldType: {
                    type: "input",
                },
            },
            {
                label: t("هوية الأحوال المدنية"),
                key: "civil_id",
                alternateMatches: ["هوية"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("الهوية الوطنية الموحدة"),
                key: "national_id",
                alternateMatches: ["موحدة"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("الجنسية العراقية"),
                key: "iraqi_national_id",
                alternateMatches: ["جنسية"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("بطاقة السكن"),
                key: "housing_card",
                alternateMatches: ["بطاقة سكن"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("البطاقة التموينية"),
                key: "ration_card",
                alternateMatches: ["تموينية"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("هوية الأب"),
                key: "father_id",
                alternateMatches: ["هوية الاب"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("هوية الأم"),
                key: "mother_id",
                alternateMatches: ["هوية الام"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("وثيقة التخرج"),
                key: "bechelor_certificate",
                alternateMatches: ["وثيقة اصلية"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("الفحص الطبي"),
                key: "medical_test",
                alternateMatches: ["فحص طبي"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("صور"),
                key: "photos",
                alternateMatches: ["صور"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("التعهد الدراسي"),
                key: "student_promise",
                alternateMatches: ["تعهد دراسي"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
            {
                label: t("شهادة المعهد"),
                key: "instituteـcertificate",
                alternateMatches: ["تاييد معهد"],
                fieldType: {
                    type: "select",
                    options: [
                        { label: t("تم الاستلام"), value: 'yes' },
                        { label: t("غير مستلم"), value: 'no' },
                    ],
                },
            },
        ];

        if (channelsDocs) {

            let otherDocs: any = [];
            channelsDocs.forEach((channel: any) => {
                if (channel.docs && channel.docs.length) {
                    otherDocs = [
                        ...otherDocs,
                        ...channel.docs.map((doc: any) => ({
                            label: doc,
                            key: doc,
                            alternateMatches: [doc],
                            fieldType: {
                                type: "select",
                                options: [
                                    { label: t("تم الاستلام"), value: 'yes' },
                                    { label: t("غير مستلم"), value: 'no' },
                                ],
                            },
                        })),
                    ];
                }
            });

            docs = [
                ...docs,
                ...otherDocs,
            ];
        }

        return docs;

    }, [channelsDocs]);

    useEffect(() => {
        getAllChannelsDocs().then((response) => {
            setChannelsDocs(response);
        }).catch((error) => {
            notifications.show({
                title: t('حدث خطأ أثناء تحميل بيانات القنوات'),
                message: error.message,
                color: 'red',
            });
        });
    }, []);

    return (
        <>
            <Button
                size="xs"
                onClick={() => {
                    // setIsOpen(true);
                    setModalOpen(true);
                }}
                leftSection={<IconDatabaseImport size={16} />}
                variant="outline"
            >
                {t("إستيراد المستمسكات")}
            </Button>
            <Modal opened={modalOpen} onClose={() => setModalOpen(false)} title={t("تحديث المستمسكات")}>
                <Modal.Body>
                    <Flex direction="column" gap="xs">
                        <Alert color="orange" title={t("تنبيه")}>
                            {t("سيتم تحديث قائمة المستمسكات بالإعتماد إما على المعرف (id) أو البريد الإلكتروني أو رقم الهوية، يجب إرفاق واحد أو أكثر من هذه الحقول")}
                        </Alert>
                        <Flex gap={'xs'}>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setModalOpen(false);
                                }}
                                color="red"
                            >
                                {t("إغلاق")}
                            </Button>
                            <Button
                                size="xs"
                                onClick={() => {
                                    setModalOpen(false);
                                    setIsOpen(true);
                                }}
                            >
                                {t("التالي")}
                            </Button>
                        </Flex>
                    </Flex>
                </Modal.Body>
            </Modal>
            <ReactSpreadsheetImport
                isOpen={isOpen}
                onClose={() => {
                    setIsOpen(false);
                }}
                autoMapSelectValues={true}
                onSubmit={(data: Result<any>, file: File) => {
                    if (data.invalidData.length > 0) {
                        console.log("invalidData", data.invalidData);

                        alert(t("تم العثور على بيانات غير صحيحة في الجدول"));
                        return;
                    }

                    const validData = data.validData || [];

                    if (!validData.length) {
                        alert(t("لم يتم العثور على بيانات صحيحة في الجدول"));
                        return;
                    }

                    setData(validData);
                    onSubmit && onSubmit(validData, file);

                }}
                fields={fields}
            />
            <ImportDrawer data={data} />
        </>
    )

}

export const ImportDrawer = ({
    data,
    options,
}: {
    data: any;
    options?: any;
}) => {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [importedData, setImportedData] = useState<any>(null);
    const [invalidImports, setInavlidImports] = useState<any>([]);
    const [cursor, setCursor] = useState(-1);
    const [completed, setCompleted] = useState(false);

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (data) {
            setImportedData([]);
            setInavlidImports([]);
            setCompleted(false);
            setIsOpen(true);
            setCursor(0);
        }
    }, [data]);

    const handleResponse = (response: any) => {
        const item = {
            row: data[cursor],
            success: !Boolean(response.message),
            response,
        };
        importedData.push(item);
        if (!item.success) {
            invalidImports.push(item);
        }
        setCursor((prev) => prev + 1);

        const total = data.length;
        const current = importedData.length;
        const prg = (current / total) * 100;
        setProgress(Number(prg).toFixed(2) as any);
    };

    useEffect(() => {
        if (!data || cursor === data?.length) {
            setCompleted(true);
            return;
        }

        const row = data[cursor];

        const newRow: any = {};
        Object.keys(row).forEach((key: string) => {
            if (row[key] === 'yes' || row[key] === 'no') {
                newRow[key] = row[key] == 'yes' ? true : false;
            } else if (key === 'email' || key === 'id_number') {
                newRow[key] = row[key];
            }
        });


        if (newRow.id) {
            // studentsApiInterceptor.mutation.update(newRow.id, newRow);
            setStudentDocs(newRow.id, {
                ...newRow,
                options,
            }).then((response) => {
                handleResponse(response);
            }).catch((error) => {
                handleResponse(error);
            });
        } else if (newRow.email || newRow.id_number) {
            // studentsApiInterceptor.mutation.create(newRow);
            setStudentDocsByKey({
                ...newRow,
                options,
            }).then((response) => {
                handleResponse(response);
            }).catch((error) => {
                handleResponse(error);
            });
        } else {
            handleResponse({
                message: t("لا يوجد معرف أو بريد إلكتروني أو رقم هوية"),
            });
        }
    }, [cursor]);

    return (
        <Drawer
            title={t("استيراد بيانات الطلاب")}
            opened={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}
            closeOnClickOutside={false}
            position="right"
            size={'calc(100vw - 320px)'}
        >
            <Flex w={'100%'} p={'md'} gap={'xs'}>
                <Flex gap={'xs'}>
                    {
                        completed ? (
                            <ThemeIcon radius="xl" color={theme.colors.teal[6]} variant="filled">
                                <IconCheck size={20} />
                            </ThemeIcon>
                        ) : (
                            <Loader />
                        )
                    }
                    <Text fw={'bold'}>
                        {
                            completed ? t("اكتمل الاستيراد") : (t("جاري الاستيراد") + "...")
                        }
                    </Text>
                </Flex>
                <Flex direction={"column"} w={'100%'}>
                    <Progress.Root radius="xs" size="xl" w={'100%'}>
                        <Progress.Section value={progress} striped animated={!completed}>
                            <Progress.Label>{progress}%</Progress.Label>
                        </Progress.Section>
                    </Progress.Root>
                    <Text size={'xs'} color={'gray'}>{importedData?.length} / {data?.length}</Text>
                </Flex>
            </Flex>
            {
                (Boolean(invalidImports.length) || completed) && (
                    <Flex justify="center" align="center" direction="column" style={{ height: 200 }}>
                        <Alert title={t("تنبيه")} color={invalidImports.length ? 'red' : 'teal'} w={'100%'}>
                            <Flex direction={'column'} gap={'xs'}>
                                <Text fs={'sm'}>{invalidImports.length ? t("لديك بعض الصفوف لم يتم إستيرادها بنجاح") : t("تم استيراد البيانات بنجاح")}</Text>
                                {
                                    invalidImports.length > 0 && (
                                        <Button
                                            color={'red'}
                                            size={'xs'}
                                            onClick={() => {
                                                const headers = [
                                                    ...Object.keys(invalidImports[0].row),
                                                    "success",
                                                    "message",
                                                ];
                                                xlsx([
                                                    {
                                                        sheet: "Sheet 1",
                                                        columns: [
                                                            ...headers.map((header: any) => ({ label: header, value: header })),
                                                        ],
                                                        content: [
                                                            ...invalidImports.map((item: any) => Object.keys(headers).reduce((acc: any, key: any) => {
                                                                const realKey = headers[key];
                                                                if (realKey === 'success') {
                                                                    return {
                                                                        ...acc,
                                                                        [realKey]: item.success ? 'نعم' : 'لا',
                                                                    }
                                                                } else if (realKey === 'message') {
                                                                    return {
                                                                        ...acc,
                                                                        [realKey]: item.response?.message || '',
                                                                    }
                                                                }
                                                                return {
                                                                    ...acc,
                                                                    [realKey]: item.row[realKey] || '',
                                                                }
                                                            }, {})),
                                                        ],
                                                    }
                                                ], {
                                                    fileName: "invalid-results-" + dayjs().format('Invalid students import YYYY-MM-DD-HH-mm-ss') + ".xlsx",
                                                    extraLength: 3,
                                                    writeMode: "writeFile",
                                                    writeOptions: {},
                                                    RTL: true,
                                                }, (sheet: any) => {
                                                    notifications.show({
                                                        title: t('تم التحميل'),
                                                        message: t('تم تحميل النتائج بنجاح'),
                                                        color: 'green',
                                                    });
                                                });
                                            }}
                                        >
                                            {t("تحميل الصفوف غير المستوردة")}
                                        </Button>
                                    )
                                }
                            </Flex>
                        </Alert>
                    </Flex>
                )
            }
            {
                Boolean(invalidImports.length) /*&& Boolean(data) && data.length > 0*/ && (
                    <Flex direction="column">
                        <Text size="sm" fw="bold">{t("آخر 50 صف غير مستورد من أصل")} {invalidImports.length} {t("صف غير مستورد")}</Text>
                        <ScrollArea>
                            <Table>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th>{t("الحالة")}</Table.Th>
                                        {Object.keys(data[0]).map((key: string) => (
                                            <Table.Th key={key}>{key}</Table.Th>
                                        ))}
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>
                                    {data.slice(-50).map((row: any, index: number) => importedData[index]?.success || cursor <= index ? null : (
                                        <Table.Tr key={index} style={Boolean(importedData.length) ? { backgroundColor: importedData[index]?.success ? theme.colors.teal[0] : theme.colors.red[0] } : {}}>
                                            <Table.Td>
                                                {
                                                    cursor <= index ? (
                                                        <Loader size="sm" />
                                                    ) : (
                                                        importedData[index]?.success ? (
                                                            <ThemeIcon radius="xl" color={theme.colors.teal[6]} variant="filled">
                                                                <IconCheck size={13} />
                                                            </ThemeIcon>
                                                        ) : (
                                                            <Popover width={200} position="bottom" withArrow shadow="md">
                                                                <Popover.Target>
                                                                    <ThemeIcon radius="xl" color={theme.colors.red[6]} variant="filled" style={{ cursor: 'pointer' }}>
                                                                        <IconX size={13} />
                                                                    </ThemeIcon>
                                                                </Popover.Target>
                                                                <Popover.Dropdown>
                                                                    <Text size="xs">{importedData[index]?.response?.message || t("حدث خطأ أثناء الاستيراد")}</Text>
                                                                </Popover.Dropdown>
                                                            </Popover>
                                                        )
                                                    )
                                                }
                                            </Table.Td>
                                            {Object.keys(row).map((key: string) => (
                                                <Table.Td key={key}>{row[key]}</Table.Td>
                                            ))}
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                            </Table>
                        </ScrollArea>
                    </Flex>
                )
            }
        </Drawer>
    )

}