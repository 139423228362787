import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi from "../hooks/useApi";
import { Box, Card, Divider, Flex, Grid, NumberFormatter, Text, Title, useMantineTheme } from "@mantine/core";
import { IconBooks, IconExclamationCircle, IconGenderBigender, IconHaze, IconPray, IconSchool, IconTrendingUp } from "@tabler/icons-react";
import { Pie } from '@ant-design/plots';
import { Area } from '@ant-design/plots';
import { Bar } from '@ant-design/plots';
import { useHover } from "@mantine/hooks";
import useUserCan from "../hooks/useUserCan";

export default () => {

    const { t } = useTranslation();
    const { userCan } = useUserCan();

    const studentsAnalyticsInterceptor = useApi({
        'key': 'students_analytics',
    });

    const [studentsAnalytics, setStudentsAnalytics] = useState<any>([]);

    useEffect(() => {
        if (studentsAnalyticsInterceptor?.query?.data) {
            setStudentsAnalytics(studentsAnalyticsInterceptor?.query?.data);
        }
    }, [studentsAnalyticsInterceptor?.query?.data]);

    return (
        <>
            <Grid columns={12} gutter="md" p="md">
                <Grid.Col span={12}>
                    <Title order={2}>{t('الإحصائيات')}</Title>
                </Grid.Col>
                {
                    studentsAnalytics?.total && <Grid.Col span={{ base: 12, md: 4 }}>
                        <DashboardCard>
                            <Flex align="center" gap={'xs'} p={'md'} pb={0}>
                                <IconSchool size={30} />
                                <Flex direction={'column'} gap={0}>
                                    <Text>{t('عدد الطلبة')}</Text>
                                </Flex>
                            </Flex>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize: 70,
                                    fontWeight: 'bold',
                                    fontFamily: useMantineTheme().fontFamily,
                                    height: 205,
                                }}
                            >
                                <NumberFormatter value={studentsAnalytics?.total} thousandSeparator />
                            </Box>
                        </DashboardCard>
                    </Grid.Col>
                }
                {
                    studentsAnalytics?.gender && <Grid.Col span={{ base: 12, md: 4 }}>
                        <PieChartCard
                            title={t('الجنس')}
                            icon={IconGenderBigender}
                            data={studentsAnalytics.gender}
                        />
                    </Grid.Col>
                }
                {
                    studentsAnalytics?.study_type && <Grid.Col span={{ base: 12, md: 4 }}>
                        <PieChartCard
                            title={t('نوع الدراسة')}
                            icon={IconHaze}
                            data={studentsAnalytics.study_type}
                        />
                    </Grid.Col>
                }
                {
                    studentsAnalytics?.student_status && <Grid.Col span={{ base: 12, md: 5 }}>
                        <PieChartCard
                            title={t('حالة الطالب')}
                            icon={IconExclamationCircle}
                            data={studentsAnalytics.student_status}
                        />
                    </Grid.Col>
                }
                {
                    studentsAnalytics?.registration_academic_year && <Grid.Col span={{ base: 12, md: 7 }}>
                        <AreaChartCard
                            title={t('نمو الطلبة حسب العام الدراسي')}
                            icon={IconTrendingUp}
                            data={studentsAnalytics.registration_academic_year}
                        />
                    </Grid.Col>
                }
                {
                    studentsAnalytics?.religion && <Grid.Col span={{ base: 12, md: 6 }}>
                        <BarChartCard
                            title={t('الديانة')}
                            icon={IconPray}
                            data={studentsAnalytics.religion}
                        />
                    </Grid.Col>
                }
                {
                    studentsAnalytics?.stage && <Grid.Col span={{ base: 12, md: 6 }}>
                        <BarChartCard
                            title={t('المرحلة الدراسية')}
                            icon={IconBooks}
                            data={studentsAnalytics.stage}
                        />
                    </Grid.Col>
                }
            </Grid>
            {
                userCan('create_students_invoices') && <FinanceAnalytics />
            }
        </>
    );

}

const FinanceAnalytics = () => {

    const { t } = useTranslation();
    const { userCan } = useUserCan();
    const theme = useMantineTheme();

    const financeAnalyticsInterceptor = useApi({
        'key': 'finance_analytics',
    });

    const [financeAnalytics, setFinanceAnalytics] = useState<any>([]);

    useEffect(() => {
        if (financeAnalyticsInterceptor?.query?.data) {
            setFinanceAnalytics(financeAnalyticsInterceptor?.query?.data);
        }
    }, [financeAnalyticsInterceptor?.query?.data]);

    return (
        <Grid columns={12} gutter="md" p="md">
            <Grid.Col span={12} mt={30}>
                <Title order={2}>{t('الحسابات')}</Title>
            </Grid.Col>

            {
                financeAnalytics?.unpaid_departments && <Grid.Col span={{ base: 12, md: 6 }}>
                    <BarChartCard
                        title={t('الوصولات المستحقة للأقسام')}
                        icon={IconSchool}
                        data={financeAnalytics.unpaid_departments}
                    />
                </Grid.Col>
            }
            {
                financeAnalytics?.unpaid_students && <Grid.Col span={{ base: 12, md: 3 }}>
                    <DashboardCard>
                        <Flex align="center" gap={'xs'} p={'md'} pb={0}>
                            <IconExclamationCircle size={30} />
                            <Flex direction={'column'} gap={0}>
                                <Text>{t('الطلبة المستحقين')}</Text>
                            </Flex>
                        </Flex>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 70,
                                fontWeight: 'bold',
                                fontFamily: theme.fontFamily,
                                height: 205,
                            }}
                        >
                            <NumberFormatter value={financeAnalytics?.unpaid_students} thousandSeparator />
                        </Box>
                    </DashboardCard>
                </Grid.Col>
            }
            {
                financeAnalytics?.unpaid_invoices && <Grid.Col span={{ base: 12, md: 3 }}>
                    <DashboardCard>
                        <Flex align="center" gap={'xs'} p={'md'} pb={0}>
                            <IconExclamationCircle size={30} />
                            <Flex direction={'column'} gap={0}>
                                <Text>{t('الفواتير غير المسددة')}</Text>
                            </Flex>
                        </Flex>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 70,
                                fontWeight: 'bold',
                                fontFamily: theme.fontFamily,
                                height: 205,
                            }}
                        >
                            <NumberFormatter value={financeAnalytics?.unpaid_invoices} thousandSeparator />
                        </Box>
                    </DashboardCard>
                </Grid.Col>
            }
            {
                financeAnalytics?.unpaid_money && <Grid.Col span={{ base: 12, md: 3 }}>
                    <DashboardCard>
                        <Flex align="center" gap={'xs'} p={'md'} pb={0}>
                            <IconExclamationCircle size={30} />
                            <Flex direction={'column'} gap={0}>
                                <Text>{t('المبلغ غير المسدد')}</Text>
                            </Flex>
                        </Flex>
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 25,
                                fontWeight: 'bold',
                                fontFamily: theme.fontFamily,
                                height: 205,
                            }}
                        >
                            <NumberFormatter value={financeAnalytics?.unpaid_money} thousandSeparator />
                        </Box>
                    </DashboardCard>
                </Grid.Col>
            }
            {
                financeAnalytics?.daily_income && <Grid.Col span={{ base: 12, md: 6 }}>
                    <AreaChartCard
                        title={t('الدخل اليومي')}
                        icon={IconTrendingUp}
                        data={financeAnalytics.daily_income}
                    />
                </Grid.Col>
            }
        </Grid>
    )

}

const DashboardCard = (props: any) => {

    const { hovered, ref } = useHover();

    return (
        <Card withBorder p={0} ref={ref} shadow={hovered ? 'xl' : '0'} style={{ transition: 'all 0.3s' }}>
            {props.children}
        </Card>
    );
}

const PieChartCard = ({
    title,
    icon,
    data,
}: {
    title?: string;
    icon?: any;
    data: any;
}) => {

    const theme = useMantineTheme();

    const config = useMemo(() => {
        return {
            data,
            angleField: 'value',
            colorField: 'name',
            // legend: false,
            legend: {
                color: {
                    title: false,
                    position: 'right',
                    rowPadding: 5,
                },
            },
            innerRadius: 0.6,
            // labels: [
            //     { text: 'name', style: { fontSize: 10, fontWeight: 'bold', fontFamily: theme.fontFamily } },
            // ],
            label: {
                text: 'value',
                position: 'outside',
            },
            style: {
                stroke: '#fff',
                inset: 1,
                radius: 10,
            },
            scale: {
                color: {
                    // start with blues and end with reds
                    // palette: ["#001219", "#005f73", "#0a9396", "#94d2bd", "#e9d8a6", "#ee9b00", "#ca6702", "#bb3e03", "#ae2012", "#9b2226"],
                    palette: ["#1b2556", "#991b1d", "#3e4a84", "#6c3351", "#a03749", "#991b1d"],
                    // offset: (t: any) => t * 2 + 0.5,
                },
            },
        };
    }, [data]);

    const Icon = icon;

    return (
        <DashboardCard>
            <Flex align="center" gap={'xs'} p={'md'} pb={0}>
                {
                    icon && (
                        <Flex align="center" justify="center">
                            <Icon size={30} />
                        </Flex>
                    )
                }
                <Flex direction={'column'} gap={0}>
                    <Text>{title}</Text>
                </Flex>
            </Flex>
            <Box style={{ direction: 'ltr' }}>
                <Pie {...config} height={200} />
            </Box>
        </DashboardCard>
    );
}

const AreaChartCard = ({
    title,
    icon,
    data,
}: {
    title?: string;
    icon?: any;
    data: any;
}) => {

    const theme = useMantineTheme();

    const config = useMemo(() => {
        return {
            data,
            xField: 'name',
            yField: 'value',
            style: {
                fill: 'linear-gradient(-90deg, #1b255600 0%, #1b2556 100%)',
            },
            line: {
                style: {
                    stroke: '#1b2556',
                    strokeWidth: 2,
                },
            },
        };
    }, [data]);

    const Icon = icon;

    return (
        <DashboardCard>
            <Flex align="center" gap={'xs'} p={'md'} pb={0}>
                {
                    icon && (
                        <Flex align="center" justify="center">
                            <Icon size={30} />
                        </Flex>
                    )
                }
                <Flex direction={'column'} gap={0}>
                    <Text>{title}</Text>
                </Flex>
            </Flex>
            <Box style={{ direction: 'ltr' }}>
                <Area
                    {...config}
                    margin={0}
                    height={200}
                    axis={{
                        // hide scales
                        x: false,
                        y: false,
                    }}
                />
            </Box>
        </DashboardCard>
    );
}

const BarChartCard = ({
    title,
    icon,
    data,
}: {
    title?: string;
    icon?: any;
    data: any;
}) => {

    const theme = useMantineTheme();

    const config = useMemo(() => {
        return {
            data,
            xField: 'name',
            yField: 'value',
            colorField: 'name',
            legend: false,
            label: {
                text: 'value',
                style: {
                    fontFamily: theme.fontFamily,
                    textAnchor: (d: any) => (+d.value > 0.008 ? 'right' : 'start'),
                    fill: (d: any) => (+d.value > 0.008 ? '#fff' : '#000'),
                    dx: (d: any) => (+d.value > 0.008 ? -10 : 10),
                },
            },
            axis: {
                x: {
                    labelFontFamily: theme.fontFamily,
                },
                y: {
                    labelFontFamily: theme.fontFamily,
                },
            },
            scale: {
                color: {
                    palette: ["#1b2556"],
                },
            },
        };
    }, [data]);

    const Icon = icon;

    return (
        <DashboardCard>
            <Flex align="center" gap={'xs'} p={'md'} pb={0}>
                {
                    icon && (
                        <Flex align="center" justify="center">
                            <Icon size={30} />
                        </Flex>
                    )
                }
                <Flex direction={'column'} gap={0}>
                    <Text>{title}</Text>
                </Flex>
            </Flex>
            <Box style={{ direction: 'ltr' }}>
                <Bar
                    {...config}
                    height={200}
                />
            </Box>
        </DashboardCard>
    );
}