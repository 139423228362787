import { useEffect, useState } from "react";
import { AccountType } from "../api/types";
import useApi from "./useApi";
import api from "../api";

export default function useUserCan() {

    const accountApi = useApi<AccountType>({
        key: 'account',
        params: {},
        api: api.account,
    });

    const [capabilities, setCapabilities] = useState<string[]>([]);

    useEffect(() => {
        if (accountApi.self.data?.capabilities) {
            setCapabilities(accountApi.self.data.capabilities);
        }
    }, [accountApi.self.data]);

    return {
        userCan: (capability: string | string[], relation: 'or' | 'and' = 'or') => {
            if (typeof capability === 'string') {
                return capabilities.includes(capability);
            }
            
            if (relation === 'or') {
                return capability.some(c => capabilities.includes(c));
            }

            if (relation === 'and') {
                return capability.every(c => capabilities.includes(c));
            }
        },
    }

}