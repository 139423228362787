import { useTranslation } from "react-i18next";
import { Box, Button, Card, FileInput, Flex, TextInput, Table as MantineTable, Alert, LoadingOverlay } from "@mantine/core";
import { IconFileDownload, IconFileSpreadsheet, IconTable } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { apiWithInterceptor } from "../api/routes";
import { notifications } from "@mantine/notifications";
import xlsx from "json-as-xlsx"
import dayjs from "dayjs";

export default () => {

    const { t } = useTranslation();
    const [sending, setSending] = useState(false);
    const [invalidResults, setInvalidResults] = useState([] as any[]);
    const form = useForm({
        initialValues: {
            file: null,
        },
        validate: {
            file: (value) => !value && t('يجب إدخال ملف نسبة الغياب'),
        },
    });

    return (
        <>
            <Flex p={'md'} direction={'column'} gap={'md'}>
                <Card shadow={'xs'} style={{ cursor: 'pointer' }}
                    onClick={() => {
                        const link = document.createElement('a');
                        link.href = '/absence.xlsx';
                        link.download = 'absence-sample.xlsx';
                        link.click();
                    }}
                >
                    <Flex direction={'column'} gap={'md'} justify={'center'} align={'center'}>
                        <IconTable size={34} />
                        {t('تحميل ملف النموذج')}
                    </Flex>
                </Card>
                <form
                    onSubmit={form.onSubmit((values) => {
                        const id = Math.random().toString(36).substring(2, 15);
                        setSending(true);
                        notifications.show({
                            id: id,
                            title: t('جاري الإرسال'),
                            message: '',
                            color: 'blue',
                            loading: true,
                            autoClose: false,
                        });
                        apiWithInterceptor.post('/students/attendance/import', values, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        })
                            .then((response) => {
                                let msg = t('تمت العملية بنجاح');
                                form.reset();
                                // @ts-ignore
                                let invalidResults = response.invalid_results || [];
                                if (invalidResults.length) {
                                    setInvalidResults(invalidResults);
                                    msg = t('تمت العملية بنجاح ولكن هناك بعض الصفوف غير صحيحة');
                                }

                                notifications.update({
                                    id: id,
                                    title: t('نجاح'),
                                    message: msg,
                                    color: 'green',
                                    loading: false,
                                    autoClose: true,
                                });
                            }).catch((error) => {
                                const msg = error?.message || t('حدث خطأ ما');
                                notifications.update({
                                    id: id,
                                    title: t('حدث خطأ'),
                                    message: msg,
                                    color: 'red',
                                    loading: false,
                                    autoClose: true,
                                });
                            }).finally(() => {
                                setSending(false);
                            });

                    })}
                    style={{ position: 'relative' }}
                >
                    <LoadingOverlay visible={sending} overlayProps={{ blur: 1, radius: "sm", color: '#eee' }} />
                    <Flex direction={'column'} gap={'md'}>
                        <FileInput label={t('ملف نسب الغياب')} placeholder={t('اختر ملف نسب الغياب')} required accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" leftSection={<IconFileSpreadsheet />} {...form.getInputProps('file')} />
                        <Box>
                            <Button type={'submit'} size={'xs'}>{t('إرسال')}</Button>
                        </Box>
                    </Flex>
                </form>
                <>
                    {
                        Boolean(invalidResults.length) && (
                            <>
                                <Alert color={'red'} title={t('الصفوف أدناه غير صحيحة')}>
                                    {t('الرجاء التأكد من صحة الصفوف التالية، قد يكون السبب هو بريد الطالب المسجل في النتيجة غير موجود في قاعدة البيانات')}
                                </Alert>
                                <Alert color={'orange'} title={t('تنبيه')}>
                                    {t('يجى إعادة تحميل نسب الغياب التي ظهرت بها مشكلة فقط.')}
                                </Alert>
                                <Box>
                                    <Button
                                        variant={'outline'}
                                        size={'xs'}
                                        color={'red'}
                                        onClick={() => {
                                            xlsx([
                                                {
                                                    sheet: "Sheet 1",
                                                    columns: [
                                                        // first row in invalid results is the header
                                                        ...invalidResults[0].map((header: any) => ({ label: header, value: header })),
                                                    ],
                                                    content: [
                                                        // first row in invalid results is the header
                                                        ...invalidResults.slice(1).map((result: any) => result.reduce((acc: any, value: any, index: any) => {
                                                            acc[invalidResults[0][index]] = value;
                                                            return acc;
                                                        }, {})),
                                                    ],
                                                }
                                            ], {
                                                fileName: "invalid-results-" + dayjs().format('YYYY-MM-DD-HH-mm-ss') + ".xlsx",
                                                extraLength: 3,
                                                writeMode: "writeFile",
                                                writeOptions: {},
                                                RTL: true,
                                            }, (sheet: any) => {
                                                notifications.show({
                                                    title: t('تم التحميل'),
                                                    message: t('تم تحميل الغيابات بنجاح'),
                                                    color: 'green',
                                                });
                                            });
                                        }}
                                    >
                                        <IconFileDownload />
                                        {t('تحميل الصفوف غير الصحيحة')}
                                    </Button>
                                </Box>
                                <MantineTable>
                                    <MantineTable.Tbody>
                                        {
                                            invalidResults.map((result, index) => (
                                                <MantineTable.Tr key={index}>
                                                    {
                                                        result.map((value: any, index: any) => (
                                                            <MantineTable.Td key={index}>{value}</MantineTable.Td>
                                                        ))
                                                    }
                                                </MantineTable.Tr>
                                            ))
                                        }
                                    </MantineTable.Tbody>
                                </MantineTable>
                            </>
                        )
                    }
                </>
            </Flex>
        </>
    );

}

