import { useTranslation } from "react-i18next";
import { Table } from "../components";
import useApi from "../hooks/useApi";
import { Flex, NumberFormatter, Text, useMantineTheme } from "@mantine/core";

export default () => {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const studentsApiInterceptor = useApi<any>({
        key: 'students',
        params: {},
    });
    
    return (
        <>
            <Table
                disableAdd
                disableEdit
                interceptor={'students_unpaid_invoices'}
                singular={t('وصل غير مدفوع')}
                plural={t('الوصولات غير المدفوعة')}
                columns={[
                    {
                        accessorKey: 'student_id',
                        accessorFn: ({ student }: any) => (
                            <Flex
                                direction={'column'}
                            >
                                <Text size={'sm'}>{student.first_name} {student.second_name} {student.third_name} {student.fourth_name}</Text>
                                <Text size={'xs'}>{student.department.college.name} / {student.department.name} / {student.study_type === 'morning' ? t('صباحي') : t('مسائي')} / {t('المرحلة')} {student.stage}</Text>
                            </Flex>
                        ),
                        header: t('الطالب'),
                        filterVariant: 'select',
                        mantineFilterSelectProps: () => ({
                            data: (studentsApiInterceptor.query.data?.data as any)?.map((item: any) => ({
                                value: String(item.id),
                                label: `${item.first_name} ${item.second_name} ${item.third_name} ${item.fourth_name} / ${item.department.college.name} / ${item.department.name} / ${item.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${item.stage}`,
                            })) || [],
                            searchable: true,
                            onSearchChange: (value: string) => {
                                studentsApiInterceptor.setParam('search', value);
                            },
                        }),
                    },
                    {
                        accessorKey: 'tuition_fee_id',
                        accessorFn: ({ tuition_fee }: any) => (
                            <Text size={'sm'}>{tuition_fee.name}</Text>
                        ),
                        header: t('القسط'),
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                    {
                        header: t('عام القبول'),
                        accessorKey: 'registration_academic_year',
                        accessorFn: ({ registration_academic_year }: any) => (
                            <Text size={'sm'}>{registration_academic_year.name}</Text>
                        ),
                    },
                    {
                        header: t('العام الدراسي'),
                        accessorKey: 'invoice_academic_year',
                        accessorFn: ({ invoice_academic_year }: any) => (
                            <Text size={'sm'}>{invoice_academic_year.name}</Text>
                        ),
                    },
                    {
                        accessorKey: 'amount',
                        accessorFn: ({ amount }: any) => (
                            <NumberFormatter
                                value={amount}
                                style={{
                                    fontSize: 24,
                                    fontWeight: 'bold',
                                }}
                                suffix={t('د.ع')}
                                thousandSeparator
                            />
                        ),
                        header: t('المبلغ'),
                    },
                    {
                        accessorKey: 'created_at',
                        accessorFn: ({ created_at }: any) => (
                            <Text size={'sm'}>{created_at}</Text>
                        ),
                        header: t('تم التحديث في'),
                        // enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                ]}
            />
        </>
    );

}