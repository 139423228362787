import { apiWithInterceptor } from '.';
import api from '../api';
import { StudentType, ApiConfig } from '../types';

export const students = new api<StudentType>('/students/results', true);

export default {
    list: (params?: any) => students.list(params),
    get: (id: number) => students.get(id),
    update: (id: number, data: any) => students.update(id, data),
    create: (data: any) => students.create(data),
    delete: (id: number) => students.delete(id),
} as ApiConfig<StudentType>;

export const getStudentResults = async (student_id: number): Promise<any> =>
    apiWithInterceptor.get(`/students/${student_id}/results`, {
        params: {
            limit: 99999,
            offset: 0,
        },
    });

export const updateStudentResult = async (result_id: number, data: any): Promise<any> =>
    apiWithInterceptor.put(`/students/results/${result_id}`, {
        data: data,
    });