import { apiWithInterceptor } from '.';
import api from '../api';
import { ApiConfig } from '../types';

export const finance_analytics = new api<any>('/analytics/finance', true);

export default {
    list: (params?: any) => finance_analytics.list(params)
} as ApiConfig<any>;

export const getMyTotals = async (): Promise<any> =>
    apiWithInterceptor.get(`/analytics/finance/me/total`);