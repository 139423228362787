import { useTranslation } from "react-i18next";
import { Table } from "../components";
import dayjs from "dayjs";
import { Box, Button, Card, Flex, Grid, LoadingOverlay, NumberInput, Select, Text, TextInput, ThemeIcon, Title } from "@mantine/core";
import useUserCan from "../hooks/useUserCan";
import { useForm } from "@mantine/form";
import { IconAntennaBars4, IconAntennaBars5, IconBattery3, IconLocationShare, IconSchool } from "@tabler/icons-react";
import useApi from "../hooks/useApi";
import { useState } from "react";
import { sendBulkNotification, sendPersonalizedNotification } from "../api/routes/notifications";
import { notifications } from "@mantine/notifications";

export default () => {

    const { t } = useTranslation();
    const { userCan } = useUserCan();
    const formGlobalNotification = useForm();
    const formPersonalizedNotification = useForm();

    const departmentsApiInterceptor = useApi({
        key: 'departments',
        params: {},
    });

    const studentsApiInterceptor = useApi({
        key: 'students',
        params: {},
    });

    const [focus, setFocus] = useState<null | 'global' | 'personalized'>(null);

    const [loading, setLoading] = useState(false);

    return (
        <Flex direction={'column'} gap={'md'} m={'md'} style={{ position: 'relative' }}>
            <LoadingOverlay visible={loading} overlayProps={{ radius: "sm", blur: 2 }} />
            {
                userCan('send_global_notification') && (
                    <Card withBorder shadow={'xs'} w={'100%'}
                        onMouseEnter={() => setFocus('global')}
                        onMouseLeave={() => setFocus(null)}
                    >
                        <Grid>
                            <Grid.Col span={{ xs: 12, sm: 6, md: 8, lg: 9 }}>
                                <form
                                    onSubmit={formGlobalNotification.onSubmit((values) => {
                                        setLoading(true);
                                        sendBulkNotification({
                                            title: values.title as string,
                                            message: values.body as string,
                                            link: values.link as string || undefined,
                                            department_id: values.department_id as number || undefined,
                                            stage: values.stage as number || undefined,
                                        }).then(() => {
                                            notifications.show({
                                                title: t('تم إرسال الإشعار بنجاح'),
                                                message: t('تم إرسال الإشعار بنجاح'),
                                                color: 'teal',
                                            });
                                        }).catch((err) => {
                                            notifications.show({
                                                title: t('حدث خطأ أثناء إرسال الإشعار'),
                                                message: err.message || t('حدث خطأ غير معروف أثناء إرسال الإشعار'),
                                                color: 'red',
                                            });
                                        }).finally(() => {
                                            setLoading(false);
                                        });
                                    })}
                                >
                                    <Flex direction={'column'} gap={'xs'}>
                                        <Title order={4}>{t('إرسال إشعار عام')}</Title>
                                        <Text size={'xs'}>{t('سيصل هذا الإشعار إلى جميع الطلاب المحددين')}</Text>
                                        <Flex direction={'column'} gap={'xs'} style={{ opacity: focus === 'global' ? 1 : 0.5, transition: '0.3s' }}>
                                            <TextInput
                                                size={'xs'}
                                                label={t('العنوان')}
                                                placeholder={t('العنوان')}
                                                required
                                                {...formGlobalNotification.getInputProps('title')}
                                            />
                                            <TextInput
                                                size={'xs'}
                                                label={t('النص')}
                                                placeholder={t('النص')}
                                                required
                                                {...formGlobalNotification.getInputProps('body')}
                                            />
                                            <TextInput
                                                size={'xs'}
                                                label={t('الرابط')}
                                                placeholder={t('الرابط')}
                                                {...formGlobalNotification.getInputProps('link')}
                                            />
                                            <Select
                                                size={'xs'}
                                                label={t('القسم')}
                                                placeholder={t('القسم')}
                                                searchable
                                                onChange={(value) => {
                                                    formGlobalNotification.setFieldValue('department_id', value);
                                                }}
                                                data={departmentsApiInterceptor?.query?.data?.data?.map((department: any) => ({
                                                    label: department.college.name + ' / ' + department.name,
                                                    value: String(department.id),
                                                })) || []}
                                                onSearchChange={(value) => {
                                                    departmentsApiInterceptor.setParam('search', value);
                                                }}
                                                clearable
                                                value={formGlobalNotification?.values?.department_id as string}
                                                description={t('اختر القسم المستهدف، اترك الحقل فارغا إذا كنت تريد إرسال الإشعار لجميع الأقسام.')}
                                            />
                                            <NumberInput
                                                size={'xs'}
                                                label={t('المرحلة')}
                                                placeholder={t('المرحلة')}
                                                {...formGlobalNotification.getInputProps('stage')}
                                                description={t('اختر المرحلة المستهدفة، اترك الحقل فارغا إذا كنت تريد إرسال الإشعار لجميع المراحل.')}
                                                min={1}
                                                max={6}
                                            />
                                            <Flex justify={'flex-end'} pt={'xs'}>
                                                <Button
                                                    size={'xs'}
                                                    type={'submit'}
                                                    color={focus === 'global' ? 'secondary' : 'gray'}
                                                    variant={focus === 'global' ? 'filled' : 'outline'}
                                                    rightSection={<IconLocationShare size={14} />}
                                                    style={{
                                                        transition: '0.3s',
                                                    }}
                                                >
                                                    {t('إرسال')}
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </form>
                            </Grid.Col>
                            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} style={{ opacity: focus === 'global' ? 1 : 0.2, transition: '0.3s' }}>
                                <MobilePreview>
                                    <NotificationPreview
                                        title={formGlobalNotification?.values?.title as string || t('عنوان الإشعار')}
                                        body={formGlobalNotification?.values?.body as string || t('نص الإشعار')}
                                    />
                                </MobilePreview>
                            </Grid.Col>
                        </Grid>
                    </Card>
                )
            }
            {
                userCan('send_personalized_notification') && (
                    <Card withBorder shadow={'xs'} w={'100%'}
                        onMouseEnter={() => setFocus('personalized')}
                        onMouseLeave={() => setFocus(null)}
                    >
                        <Grid>
                            <Grid.Col span={{ xs: 12, sm: 6, md: 8, lg: 9 }}>
                                <form
                                    onSubmit={formPersonalizedNotification.onSubmit((values) => {
                                        setLoading(true);
                                        sendPersonalizedNotification({
                                            title: values.title as string,
                                            message: values.body as string,
                                            link: values.link as string || undefined,
                                            student_id: values.student_id as number,
                                        }).then(() => {
                                            notifications.show({
                                                title: t('تم إرسال الإشعار بنجاح'),
                                                message: t('تم إرسال الإشعار بنجاح'),
                                                color: 'teal',
                                            });
                                        }).catch((err) => {
                                            notifications.show({
                                                title: t('حدث خطأ أثناء إرسال الإشعار'),
                                                message: err.message || t('حدث خطأ غير معروف أثناء إرسال الإشعار'),
                                                color: 'red',
                                            });
                                        }).finally(() => {
                                            setLoading(false);
                                        });
                                    })}
                                >
                                    <Flex direction={'column'} gap={'xs'}>
                                        <Title order={4}>{t('إرسال إشعار مخصص')}</Title>
                                        <Text size={'xs'}>{t('سيصل هذا الإشعار فقط للطالب المحدد')}</Text>
                                        <Flex direction={'column'} gap={'xs'} style={{ opacity: focus === 'personalized' ? 1 : 0.5, transition: '0.3s' }}>
                                            <TextInput
                                                size={'xs'}
                                                label={t('العنوان')}
                                                placeholder={t('العنوان')}
                                                required
                                                {...formPersonalizedNotification.getInputProps('title')}
                                            />
                                            <TextInput
                                                size={'xs'}
                                                label={t('النص')}
                                                placeholder={t('النص')}
                                                required
                                                {...formPersonalizedNotification.getInputProps('body')}
                                            />
                                            <TextInput
                                                size={'xs'}
                                                label={t('الرابط')}
                                                placeholder={t('الرابط')}
                                                {...formPersonalizedNotification.getInputProps('link')}
                                            />
                                            <Select
                                                size={'xs'}
                                                label={t('الطالب')}
                                                placeholder={t('الطالب')}
                                                searchable
                                                onChange={(value) => {
                                                    formPersonalizedNotification.setFieldValue('student_id', value);
                                                }}
                                                data={studentsApiInterceptor?.query?.data?.data?.map((student: any) => ({
                                                    label: student.first_name + ' ' + student.second_name + ' ' + student.third_name + ' ' + student.fourth_name + ' / ' + student.department.name + ' / ' + (student.study_type === 'morning' ? t('صباحي') : t('مسائي')) + ' / ' + 'المرحلة ' + student.stage,
                                                    value: String(student.id),
                                                })) || []}
                                                required
                                                onSearchChange={(value) => {
                                                    studentsApiInterceptor.setParam('search', value);
                                                }}
                                                clearable
                                                value={formPersonalizedNotification?.values?.student_id as string}
                                            />
                                            <Flex justify={'flex-end'} pt={'xs'}>
                                                <Button
                                                    size={'xs'}
                                                    type={'submit'}
                                                    color={focus === 'personalized' ? 'secondary' : 'gray'}
                                                    variant={focus === 'personalized' ? 'filled' : 'outline'}
                                                    rightSection={<IconLocationShare size={14} />}
                                                    style={{
                                                        transition: '0.3s',
                                                    }}
                                                >
                                                    {t('إرسال')}
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </form>
                            </Grid.Col>
                            <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }} style={{ opacity: focus === 'personalized' ? 1 : 0.2, transition: '0.3s' }}>
                                <MobilePreview>
                                    <NotificationPreview
                                        title={formPersonalizedNotification?.values?.title as string || t('عنوان الإشعار')}
                                        body={formPersonalizedNotification?.values?.body as string || t('نص الإشعار')}
                                    />
                                </MobilePreview>
                            </Grid.Col>
                        </Grid>
                    </Card>
                )
            }
        </Flex>
    );

}

const NotificationPreview = ({
    title,
    body,
}: {
    title: string,
    body: string,
}) => {
    return (
        <Card bg={'gray.2'} p={'xs'}>
            <Flex direction={'column'} gap={5}>
                <Flex gap={5} align={'center'}>
                    <IconSchool size={16} />
                    <Text size={'xs'} fw={'bold'}>{title}</Text>
                </Flex>
                <Text style={{ fontSize: 11 }}>{body}</Text>
            </Flex>
        </Card>
    );
}

const MobilePreview = ({
    children,
}: {
    children: React.ReactNode,
}) => {

    const { t } = useTranslation();

    return (
        <Flex
            h={400}
            w={'100%'}
            style={{
                border: '5px solid #333',
                borderRadius: 20,
                backgroundImage: 'radial-gradient(circle, #333, #000)',
                color: '#fff',
                position: 'relative',
            }}
            direction={'column'}
        >
            <Box
                style={{
                    position: 'absolute',
                    bottom: 10,
                    width: '50%',
                    height: 3,
                    backgroundColor: '#444',
                    left: 'calc(50% - 25%)',
                }}
            ></Box>
            <Flex justify={'space-between'} px={'xs'}>
                <Flex pt={5}>
                    <Text size={'xs'}>12:00</Text>
                    <IconBattery3 size={18} />
                </Flex>
                <Box
                    style={{
                        backgroundColor: '#333',
                        borderRadius: '0 0 10px 10px',
                        width: '50%',
                        height: 25,
                    }}
                ></Box>
                <Flex pt={5}>
                    <IconAntennaBars5 size={18} />
                    <IconAntennaBars4 size={18} />
                </Flex>
            </Flex>
            <Flex direction={'column'} gap={'md'} p={'xs'}>
                {children}
            </Flex>
        </Flex>
    )

}