import { apiWithInterceptor } from '.';
import api from '../api';
import { ApiConfig, StudentDiscountType } from '../types';

export const studentsdiscounts = new api<StudentDiscountType>('/tuitionfees/discounts', true);

export default {
    list: (params?: any) => studentsdiscounts.list(params),
    get: (id: number) => studentsdiscounts.get(id),
    update: (id: number, data: any) => studentsdiscounts.update(id, data),
    create: (data: any) => studentsdiscounts.create(data),
    delete: (id: number) => studentsdiscounts.delete(id),
} as ApiConfig<StudentDiscountType>;

export const doBulkDiscounts = async (data: {
    registration_channel_id: number,
    amount: number,
    academic_year_id: number,
    department_id: number,
}): Promise<any> => apiWithInterceptor.post(`/tuitionfees/discounts/bulk`, data);

export const checkBulkDiscounts = async (data: {
    registration_channel_id: number,
    academic_year_id: number,
    department_id: number,
}): Promise<any> => apiWithInterceptor.get(`/tuitionfees/discounts/bulk`, {
    params: data
});