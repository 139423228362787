import { useTranslation } from "react-i18next";
import { Table } from "../components";

export default () => {

    const { t } = useTranslation();

    return (
        <>
            <Table
                interceptor={'administrative_statements'}
                singular={t('الأوامر الإدارية')}
                plural={t('أمر إداري')}
                columns={[
                    {
                        accessorKey: 'name',
                        header: t('الاسم'),
                    },
                    {
                        accessorKey: 'departments_count',
                        header: t('عدد الأقسام'),
                    },
                ]}
                fields={[
                    {
                        type: 'text',
                        label: t('الاسم'),
                        name: 'name',
                        required: true,
                    },
                ]}
            />
        </>
    );

}