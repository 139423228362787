import { Footnote, PageBottom, Tailwind, CSS } from "@onedoc/react-print";
import { useTranslation } from "react-i18next";
import logo from '../../assets/img/logo-main.png'
import { StudentInvoiceType } from "../../api/types";
import { IconCash, IconNumber } from "@tabler/icons-react";
import ThemeProvider from "../../themeProvider";
import dayjs from "dayjs";
import { NumberFormatter } from "@mantine/core";
import QRCode from "react-qr-code";
import { useEffect } from "react";

export default ({
    invoice
}: {
    // invoice: StudentInvoiceType
    invoice: any
}) => {

    const { t } = useTranslation();

    return <ThemeProvider>
        <Tailwind>
            <CSS>{`
                @import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');
                * {
                    font-family: "Readex Pro", sans-serif;
                    font-optical-sizing: auto;
                    font-weight: <weight>;
                    font-style: normal;
                    font-variation-settings:
                      "HEXP" 0;
                    -webkit-print-color-adjust:exact;
                    -webkit-filter:opacity(1);
                  }
            `}</CSS>
            <div style={{ direction: 'rtl' }}>
                <div className="bg-gradient-to-r from-[#991b1d] to-[#1b2556] -z-10 absolute -left-[2cm] right-[25vw] -skew-y-12 h-[100vh] top-[-95vh]" />
                <div className="bg-gradient-to-r from-[#991b1d] to-[#1b2556] -z-20 absolute left-[75vw] -right-[2cm] -skew-y-12 h-[100vh] top-[-90vh]" />
                <div className="bg-slate-100 -rotate-12 -z-10 absolute -left-[200em] -right-[200em] h-[50vh] bottom-[0vh]" />
                <main className="text-slate-800 pt-12 h-[90vh] flex flex-col" style={{ margin: '0 25px' }}>
                    <img src={logo} alt="logo" className="mx-auto w-[15vw]" />
                    <h1 className="text-center text-slate-800 text-[2em] font-bold">
                        {t('جامعة النور')}
                    </h1>
                    <p className="pt-2 pb-4 text-slate-400 text-center text-[1em]">
                        {t('وصل دفع')} #{invoice.number}
                    </p>
                    <div className="p-12 py-6 flex-grow bg-white rounded-2xl rounded-t-none shadow-xl shadow-black/10">
                        <div className="flex justify-between gap-4">
                            <div>
                                <div className="text-[1em] text-gray-400 uppercase pb-1">
                                    {t('المبلغ المدفوع')}
                                </div>
                                <div className="text-[1.5em] items-center font-bold"><NumberFormatter thousandSeparator value={invoice.amount} />{t('د.ع')}</div>
                            </div>
                            <div>
                                <div className="text-[1em] text-gray-400 uppercase pb-1">
                                    {t('التاريخ')}
                                </div>
                                <div className="text-[1em] flex gap-4 items-center font-bold">{dayjs(invoice.created_at).format('YYYY-MM-DD')}</div>
                            </div>
                            <div>
                                <div className="text-[1em] text-gray-400 uppercase pb-1">
                                    {t('طريقة الدفع')}
                                </div>
                                <div className="text-[1em] flex gap-4 items-center font-bold">
                                    <IconCash />
                                    {t('نقداً')}
                                </div>
                            </div>
                            <div>
                                <div className="text-[1em] text-gray-400 uppercase pb-1">
                                    {t('رقم الدليل')}
                                </div>
                                <div className="text-[1em] flex gap-4 items-center font-bold">
                                    <IconNumber />
                                    { invoice.daleel_id }
                                </div>
                            </div>
                        </div>
                        <h2 className="text-slate-600 font-bold text-sm py-6 pt-12 uppercase">
                            {t('التفاصيل')}
                        </h2>
                        <div className="bg-slate-100 px-6 py-2 rounded-md">
                            <table className="w-full text-[0.8em]">
                                <tr className="border-b text-slate-500">
                                    <td className="py-2">{(invoice.title ?? '')}{invoice.tuition_fee?.name}</td>
                                    <td className="py-2"><NumberFormatter thousandSeparator value={invoice.amount} />{t('د.ع')}</td>
                                </tr>
                                {
                                    invoice.discount_amount > 0 && (
                                        <tr className="border-b text-slate-700">
                                            <td className="py-2 text-red-500">{t('الخصم')}</td>
                                            <td className="py-2 text-red-500"><NumberFormatter thousandSeparator value={invoice.discount_amount * -1} />{t('د.ع')}</td>
                                        </tr>
                                    )
                                }
                                <tr className="font-bold text-slate-700">
                                    <td className="py-2">{t('المجموع')}</td>
                                    <td className="py-2"><NumberFormatter thousandSeparator value={invoice.amount - (invoice.discount_amount ?? 0)} />{t('د.ع')}</td>
                                </tr>
                            </table>
                        </div>
                        <h2 className="text-slate-600 font-bold text-sm py-3 pt-18 uppercase">
                            {t('معلومات الطالب')}
                        </h2>
                        <div className="bg-slate-100 px-6 py-2 rounded-md">
                            <table className="w-full text-[0.8em]">
                                <tr className="text-slate-700 border-b">
                                    <td className="py-2 font-bold">{t('الاسم')}</td>
                                    <td className="py-2">{(invoice.title ?? '')}{(invoice.student?.first_name ?? '')} {(invoice.student?.second_name ?? '')} {(invoice.student?.third_name ?? '')} {(invoice.student?.fourth_name ?? '')}</td>
                                </tr>
                                <tr className="text-slate-700 border-b">
                                    <td className="py-2 font-bold">{t('القسم')}</td>
                                    <td className="py-2">{invoice.student?.department.college.name} / {invoice.student?.department.name}</td>
                                </tr>
                                <tr className="text-slate-700 border-b">
                                    <td className="py-2 font-bold">{t('الدراسة')}</td>
                                    <td className="py-2">{invoice.student ? (invoice.student.study_type === 'morning' ? t('صباحي') : t('مسائي')) : ''}</td>
                                </tr>
                                <tr className="text-slate-700 border-b">
                                    <td className="py-2 font-bold">{t('عام القبول')}</td>
                                    <td className="py-2">{invoice.student?.registration_academic_year.name}</td>
                                </tr>
                                <tr className="text-slate-700">
                                    <td className="py-2 font-bold">{t('العام الدراسي')}</td>
                                    <td className="py-2">{invoice.academic_year?.name}</td>
                                </tr>
                            </table>
                        </div>
                        {
                            invoice.notes ? (
                                <p className="text-[0.8em] text-slate-500 py-6">
                                    {t('ملاحظة')}: {invoice.notes}
                                </p>
                            ) : (
                                <hr className="my-6" />
                            )
                        }
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between items-center">
                                <QRCode value={invoice.verify_link} size={150} />
                                <div className="text-slate-400 text-sm uppercase px-5 mx-3 border-b border-slate-400">
                                    {t('أمين الصندوق')}
                                </div>
                            </div>
                            <p className="text-slate-500 text-[0.8em]">
                                {t('للتحقق من صحة هذا الوصل، قم بقراءة رمز QR الموجود على الوصل، وتأكد من أن الرابط الذي ستقوم بزيارته هو ضمن نطاق جامعة النور الرسمي alnoor.edu.iq، خلاف ذلك يرجى عدم الاعتماد على الوصل والتواصل مع الجامعة للتأكد من صحته.')}
                            </p>
                            <Footnote>
                                {t('جامعة النور')} - {t('الموصل، نينوى، العراق، طريق الشلالات')}
                                <br />
                                info@alnoor.edu.iq - 770 566 3332 - 6267
                            </Footnote>
                        </div>
                    </div>
                </main>
            </div>
        </Tailwind >
    </ThemeProvider >;
}

