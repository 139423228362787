import { apiWithInterceptor } from ".";

export const getStudentDocs = async (student_id: number): Promise<any> =>
    apiWithInterceptor.get(`/students/${student_id}/docs`);

export const setStudentDocs = async (student_id: number, data: any): Promise<any> =>
    apiWithInterceptor.put(`/students/${student_id}/docs`, data);

export const setStudentDocsByKey = async (data: any): Promise<any> =>
    apiWithInterceptor.put(`/students/docs`, data);

export const getChannelDocs = async (channel_id: number): Promise<any> =>
    apiWithInterceptor.get(`/channel/${channel_id}/docs`);

export const getAllChannelsDocs = async (): Promise<any> =>
    apiWithInterceptor.get(`/channel/docs`);

export const setChannelDocs = async (channel_id: number, data: any): Promise<any> =>
    apiWithInterceptor.put(`/channel/${channel_id}/docs`, data);