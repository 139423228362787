import { Outlet } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import api from '../../api';
import { AccountType } from '../../api/types';
import Loading from '../loading';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { AppShell, Avatar, Burger, Divider, Flex, Group, Text } from '@mantine/core';
import Navbar from './navbar';
import Actions from './actions';

export default () => {

    const { t } = useTranslation()

    const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
    const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

    const accountApi = useApi<AccountType>({
        key: 'account',
        params: {},
        api: api.account,
    })

    if (accountApi.query.isLoading) {
        return <Loading msg={t('جاري تحميل البيانات')} />
    }

    return (
        <AppShell
            layout="alt"
            header={{ height: 60 }}
            navbar={{
                width: 320,
                breakpoint: 'sm',
                collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
            }}
            padding={0}
        >
            <AppShell.Header
                style={(theme) => ({
                    // backgroundColor: theme.colors.secondary[8],
                })}
            >
                <Group h="100%" w="100%" px="md">
                    <Flex align="center" w="100%" justify="space-between">
                        <Flex align="center" gap="xs">
                            <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
                            <Burger opened={desktopOpened} onClick={toggleDesktop} visibleFrom="sm" size="sm" />
                            <Divider orientation='vertical' pe={'xs'} />
                            <Avatar size={'sm'}
                                src={accountApi.self?.data?.profile_picture?.sizes?.thumbnail}
                            >
                                {accountApi.self.data?.name ? accountApi.self.data.name[0]?.toUpperCase() : 'A'}
                            </Avatar>
                            <Flex direction="column" align="start">
                                <Text size={'sm'}>{accountApi.self.data?.name}</Text>
                            </Flex>
                        </Flex>
                        <Actions />
                    </Flex>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar>
                <Navbar />
            </AppShell.Navbar>
            <AppShell.Main>
                <Outlet />
            </AppShell.Main>
        </AppShell>
    )

}