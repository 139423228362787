import { useTranslation } from "react-i18next";
import { Table } from "../components";
import { Avatar, Box, Button, Flex, Grid, LoadingOverlay, Select, Text, TextInput, useMantineTheme } from "@mantine/core";
import useApi from "../hooks/useApi";
import { AccountType } from "../api/types";
import { useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import { useEffect, useRef, useState } from "react";
import { updateAccountPicture } from "../api/routes/account";
import { notifications } from "@mantine/notifications";
import { IconEditCircle } from "@tabler/icons-react";
import logo from '../assets/img/logo-main.png'

export default () => {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const accountApi = useApi<AccountType>({
        key: 'account',
        params: {},
    });

    const form = useForm();

    useEffect(() => {
        form.setValues({
            name: accountApi.self.data?.name,
            phone_number: accountApi.self.data?.phone_number,
            birth_date: accountApi.self.data?.birth_date
                ? new Date(accountApi.self.data.birth_date)
                : undefined,
            gender: accountApi.self.data?.gender,
        });
    }, [accountApi.self.data]);

    const [loading, setLoading] = useState(false);

    return (
        <>
            <Grid>
                <Grid.Col span={{ xs: 12, md: 5, lg: 4 }}
                    style={{
                        minHeight: 'calc(100vh - 50px)',
                        backgroundColor: theme.colors.gray[0],
                    }}
                >
                    <Flex align="center" gap="sm" py="xs" direction={'column'}>
                        {/* <Avatar
                            color={theme.colors.primary[9]}
                            variant="filled"
                            style={{
                                border: `2px solid ${theme.colors.secondary[0]}`,
                            }}
                            size={120}
                        >
                            {accountApi.self.data?.name ? accountApi.self.data.name[0]?.toUpperCase() : 'A'}
                        </Avatar> */}
                        <ProfilePicture account={accountApi.self.data} />
                        <Flex direction="column" gap={0} align="center">
                            <Text size={'md'} fw={'bold'}>{accountApi.self.data?.name}</Text>
                            <Text size={'sm'} color={'gray'}>{accountApi.self.data?.email}</Text>
                        </Flex>
                        <form
                            onSubmit={form.onSubmit((values) => {
                                accountApi.mutation.update(1, values);
                            })}
                            style={{ width: '100%' }}
                        >
                            <Flex direction="column" gap={'xs'} align="center" px={'lg'}>
                                <TextInput
                                    w={'100%'}
                                    size={'xs'}
                                    label={t('الاسم')}
                                    placeholder={t('الاسم')}
                                    {...form.getInputProps('name')}
                                />
                                <TextInput
                                    w={'100%'}
                                    size={'xs'}
                                    label={t('رقم الهاتف')}
                                    placeholder={t('رقم الهاتف')}
                                    {...form.getInputProps('phone_number')}
                                />
                                <DateInput
                                    w={'100%'}
                                    size={'xs'}
                                    label={t('تاريخ الميلاد')}
                                    placeholder={t('تاريخ الميلاد')}
                                    {...form.getInputProps('birth_date')}
                                />
                                <Select
                                    w={'100%'}
                                    size={'xs'}
                                    label={t('الجنس')}
                                    placeholder={t('الجنس')}
                                    data={[
                                        { label: t('ذكر'), value: 'male' },
                                        { label: t('أنثى'), value: 'female' },
                                    ]}
                                    {...form.getInputProps('gender')}
                                />
                                <Button
                                    type="submit"
                                    size={'xs'}
                                    color={'primary'}
                                    variant={'outline'}
                                    fullWidth
                                    loading={loading}
                                >
                                    {t('حفظ')}
                                </Button>
                            </Flex>
                        </form>
                    </Flex>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, md: 7, lg: 8 }} display={{ xs: 'none', md: 'block' }}>
                    <Box
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${logo})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            mixBlendMode: 'luminosity',
                            opacity: 0.04,
                        }}
                    ></Box>
                </Grid.Col>
            </Grid>
        </>
    );

}


export const ProfilePicture = ({
    account,
}: {
    account: any;
}) => {

    const [loading, setLoading] = useState(false);
    const [hoverAvatar, setHoverAvatar] = useState<boolean>(false);
    const profilePictureRef = useRef<HTMLInputElement>(null);
    const [studentObject, setStudentObject] = useState<any>(account);
    useEffect(() => {
        setStudentObject(account);
    }, [account]);
    const { t } = useTranslation();

    return (
        <Flex
            style={{
                position: 'relative',
            }}
        >
            <input type="file" accept="image/*" style={{ display: 'none' }} ref={profilePictureRef} id="picture" onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                    const file = e.target.files[0];
                    setLoading(true);

                    updateAccountPicture({
                        picture: file,
                    }).then((res) => {
                        setStudentObject(res);
                        notifications.show({
                            title: 'Success',
                            message: t('تم تحديث الصورة بنجاح'),
                            color: 'teal',
                        });
                    }).catch((err) => {
                        notifications.show({
                            title: 'Error',
                            message: err.message,
                            color: 'red',
                        });
                    }).finally(() => {
                        setLoading(false);
                    });
                }
            }} />
            <Avatar
                src={studentObject?.profile_picture?.sizes?.thumbnail}
                size={100}
                radius={999}
                color="primary"
                onMouseEnter={() => setHoverAvatar(true)}
                onMouseLeave={() => setHoverAvatar(false)}
            ></Avatar>
            {
                loading ? (
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 999,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                        }}
                    >
                        <LoadingOverlay visible={true} />
                    </Box>
                ) : (
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '100%',
                            height: '100%',
                            borderRadius: 999,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            opacity: hoverAvatar ? 1 : 0,
                            transition: 'opacity 0.3s ease',
                            cursor: 'pointer',
                        }}
                        onMouseEnter={() => setHoverAvatar(true)}
                        onMouseLeave={() => setHoverAvatar(false)}
                        onClick={() => {
                            if (profilePictureRef.current) {
                                profilePictureRef.current.click();
                            }
                        }}
                    >
                        <IconEditCircle size={30} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} color="white" />
                    </Box>
                )
            }
        </Flex>
    );

};