import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Invoice, Table } from "../components";
import useApi from "../hooks/useApi";
import { Button, Flex, NumberFormatter, Text, useMantineTheme } from "@mantine/core";
import { IconPrinter, IconX } from "@tabler/icons-react";
import { compile } from "@onedoc/react-print";
import { modals } from "@mantine/modals";
import useUserCan from "../hooks/useUserCan";

export default () => {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const { userCan } = useUserCan();

    const studentsApiInterceptor = useApi<any>({
        key: 'students',
        params: {},
    });
    const academicYearsApiInterceptor = useApi<any>({
        key: 'academic_years',
        params: {},
    });
    const studentsInvoicesApiInterceptor = useApi<any>({
        key: 'external_invoices',
        params: {},
    });

    const printInvoice = useMemo(() => async (item: any) => {
        // open <Invoice /> in new window
        const html = await compile(<Invoice
            invoice={item}
        />);
        const newWindow = window.open();
        if (!newWindow) return;
        newWindow.document.write(html);
        newWindow.document.title = `Invoice #${item.id} - ${item.title ?? ''}${item.student?.first_name} ${item.student?.second_name} ${item.student?.third_name} ${item.student?.fourth_name} - ${item.amount} د.ع`;
        newWindow.document.close();
        newWindow.focus();
        setTimeout(() => {
            newWindow.close();
        }, 500);
        newWindow.print();
    }, []);

    return (
        <>
            <Table
                // disableAdd
                // disableEdit
                interceptor={'external_invoices'}
                singular={t('وصل')}
                plural={t('الوصولات')}
                columns={[
                    {
                        accessorKey: 'number',
                        header: t('رقم الوصل'),
                        accessorFn: (item: any) => (
                            <Flex gap={'xs'} align={'center'}>
                                <Text size={'sm'} style={{ direction: 'ltr' }}>#{item.number}</Text>
                                <Button
                                    size={'xs'}
                                    variant={'light'}
                                    onClick={async () => {
                                        printInvoice(item);
                                    }}
                                    leftSection={<IconPrinter />}
                                >
                                    {t('طباعة')}
                                </Button>
                            </Flex>
                        ),
                    },
                    {
                        accessorKey: 'title',
                        header: t('العنوان'),
                    },
                    {
                        accessorKey: 'amount',
                        accessorFn: ({ amount }: any) => (
                            <Flex direction={'column'}>
                                <NumberFormatter
                                    value={amount}
                                    style={{
                                        fontSize: 24,
                                        fontWeight: 'bold',
                                    }}
                                    suffix={t('د.ع')}
                                    thousandSeparator
                                />
                            </Flex>
                        ),
                        header: t('المبلغ'),
                    },
                    {
                        accessorKey: 'daleel_id',
                        accessorFn: ({ daleel_id }: any) => (
                            <Text size={'sm'}>{daleel_id}</Text>
                        ),
                        header: t('رقم الدليل'),
                    },
                    {
                        accessorKey: 'created_at',
                        accessorFn: ({ created_at }: any) => (
                            <Text size={'sm'}>{created_at}</Text>
                        ),
                        header: t('تاريخ الإصدار'),
                        // enableSorting: false,
                        // mantineFilterTextInputProps: () => ({
                        //     disabled: true,
                        // }),
                        filterVariant: 'date-range',
                    },
                    {
                        accessorKey: 'author_id',
                        accessorFn: ({ author }: any) => (
                            <Flex direction={'column'}>
                                <Text size={'sm'}>{author.name}</Text>
                                <Text size={'sm'} style={{ color: 'gray' }}>{author.email}</Text>
                            </Flex>
                        ),
                        header: t('الموظف'),
                        // enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                    {
                        accessorKey: 'actions',
                        accessorFn: (row: any) => {
                            if (!userCan('delete_external_invoices')) {
                                return <></>;
                            }

                            return (
                                <Button
                                    size={'xs'}
                                    variant={'outline'}
                                    color={'red'}
                                    onClick={() => {
                                        modals.openConfirmModal({
                                            title: t('تأكيد الحذف'),
                                            children: (
                                                <Text size={'sm'}>
                                                    {t('هل أنت متأكد من حذف هذا الوصل؟')}
                                                </Text>
                                            ),
                                            labels: {
                                                cancel: t('إلغاء'),
                                                confirm: t('حذف'),
                                            },
                                            onConfirm: () => studentsInvoicesApiInterceptor.mutation.delete(row.id),
                                        });
                                    }}
                                    leftSection={<IconX size={14} />}
                                >
                                    {t('مسح')}
                                </Button>
                            );
                        },
                        header: t('الإجراءات'),
                        enableSorting: false,
                        mantineFilterTextInputProps: () => ({
                            disabled: true,
                        }),
                    },
                ]}
                fields={[
                    {
                        type: 'text',
                        label: t('عنوان الوصل'),
                        name: 'title',
                        required: true,
                    },
                    {
                        type: 'number',
                        label: t('المبلغ'),
                        name: 'amount',
                        required: true,
                    },
                    {
                        type: 'number',
                        label: t('رقم الدليل'),
                        name: 'daleel_id',
                        required: true,
                    },
                    {
                        type: 'textarea',
                        label: t('ملاحظات'),
                        name: 'notes',
                    },
                ]}
            />
        </>
    );

}