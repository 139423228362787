import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Invoice, Table } from "../../components";
import useApi from "../../hooks/useApi";
import { Alert, Button, Divider, Flex, Loader, NumberFormatter, NumberInput, Select, Skeleton, Text, TextInput, useMantineTheme } from "@mantine/core";
import { IconPrinter, IconX } from "@tabler/icons-react";
import { compile } from "@onedoc/react-print";
import { modals } from "@mantine/modals";
import { apiWithInterceptor } from "../../api/routes";
import { notifications } from "@mantine/notifications";
import useUserCan from "../../hooks/useUserCan";
import { useSearchParams } from "react-router-dom";
import { useForm } from "@mantine/form";
import AsyncSelect from "react-select/async";
import { getStudents } from "../../api/routes/students";

export default () => {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const studentsApiInterceptor = useApi<any>({
        key: 'students',
        params: {},
    });

    const tuitionFeesApiInterceptor = useApi<any>({
        key: 'tuition_fees',
        params: {},
    });

    const studentsInvoicesApiInterceptor = useApi<any>({
        key: 'students_invoices',
        params: {},
        onDone: (action, response) => {
            const status = response.data?.status || 200;
            if (action === 'create' && status === 200) {
                // studentsApiInterceptor.query.refetch();
                setStudent(null);
                studentsApiInterceptor.setParam('search', '');
                setTuitionFee(undefined);
                printInvoice(response);
            }
        },
        // disableCache: true,
    });

    // send request to /tuitionfees/discounts/check using axios
    const checkDiscount = useMemo(() => async (data: any) => {
        return await apiWithInterceptor.get('/tuitionfees/discounts/check', {
            params: data,
        });
    }, []);

    const [loadingDiscount, setLoadingDiscount] = useState<boolean>(false);
    const [discount, setDiscount] = useState<number>(0);

    const printInvoice = useMemo(() => async (item: any) => {
        // open <Invoice /> in new window
        const html = await compile(<Invoice
            invoice={item}
        />);
        const newWindow = window.open();
        if (!newWindow) return;
        newWindow.document.write(html);
        newWindow.document.title = `Invoice #${item.id} - ${item.student.first_name} ${item.student.second_name} ${item.student.third_name} ${item.student.fourth_name} - ${item.amount} د.ع`;
        newWindow.document.close();
        newWindow.focus();
        setTimeout(() => {
            newWindow.print();
            setTimeout(() => {
                newWindow.close();
            }, 500);
        }, 100);
    }, []);

    const [searchParams] = useSearchParams();
    const invoiceFor = searchParams.get('invoice_for');

    const [studentsSelect, setStudentsSelect] = useState<any[]>([]);
    const [student, setStudent] = useState<any>(null);
    const [tuitionFeesSelect, setTuitionFeesSelect] = useState<any[]>([]);
    const [tuitionFee, setTuitionFee] = useState<any>();
    const [daleelId, setDaleelId] = useState<number>(435);

    useEffect(() => {
        if (studentsApiInterceptor.query.data) {
            let data = studentsApiInterceptor.query.data.data?.map((item: any) => {
                return {
                    value: String(item.id),
                    label: `${item.first_name} ${item.second_name} ${item.third_name} ${item.fourth_name} / ${item.department.college.name} / ${item.department.name} / ${item.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${item.stage}`,
                };
            });

            // check if student is already inside the data, if so don'y add it
            if (student && !data.find((item) => item.value === String(student.id))) {
                data = [{
                    value: String(student.id),
                    label: `${student.first_name} ${student.second_name} ${student.third_name} ${student.fourth_name} / ${student.department.college.name} / ${student.department.name} / ${student.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${student.stage}`,
                }, ...data];
            }
            setStudentsSelect(data);
        }
    }, [studentsApiInterceptor.query.data, student]);

    useEffect(() => {
        if (tuitionFeesApiInterceptor.query.data) {
            let data = tuitionFeesApiInterceptor.query.data.data?.map((item: any) => {
                return {
                    value: String(item.id),
                    label: `${item.name}`,
                };
            });

            // check if tuition fee is already inside the data, if so don'y add it
            if (tuitionFee && !data.find((item) => item.value === String(tuitionFee.id))) {
                data = [{
                    value: String(tuitionFee.id),
                    label: `${tuitionFee.name}`,
                }, ...data];
            }

            setTuitionFeesSelect(data);
        }
    }, [tuitionFeesApiInterceptor.query.data, tuitionFee]);

    useEffect(() => {
        if (invoiceFor && studentsApiInterceptor.get) {
            studentsApiInterceptor.get(Number(invoiceFor)).then((response) => {
                setStudent(response);
            }).catch((error) => {
                notifications.show({
                    title: t('حدث خطأ'),
                    message: error.message || t('حدث خطأ'),
                    color: 'red',
                });
            });
        }
    }, [invoiceFor]);

    useEffect(() => {
        student?.id && tuitionFeesApiInterceptor.setParams({
            ...tuitionFeesApiInterceptor.params,
            filters: [
                {
                    id: "department_id",
                    value: student?.department.id,
                },
            ],
            student_id: student.id,
        });
    }, [student?.id]);

    useEffect(() => {
        setDiscount(0);
        setLoadingDiscount(true);
        if (student && tuitionFee) {
            const id = tuitionFee.id;
            // split id by "@" to get the real id
            const tuitionFeeId = id.split('@')[0];
            const academicYearId = id.split('@')[1];
            checkDiscount({
                student_id: student.id,
                tuition_fee_id: tuitionFeeId,
                academic_year_id: academicYearId,
            }).then((response: any) => {
                setDiscount(response.discount_amount || 0);
            }).catch((error) => {
                notifications.show({
                    title: t('حدث خطأ'),
                    message: error.message || t('حدث خطأ'),
                    color: 'red',
                });
            }).finally(() => {
                setLoadingDiscount(false);
            });
        }
    }, [student, tuitionFee]);

    const [students, setStudents] = useState<any[]>([]);

    const loadOptions = useMemo(() => (inputValue: string, callback: (options: any[]) => void) => {
        getStudents({ search: inputValue }).then((response) => {
            if (response.data) {
                setStudents(response.data);
                let data = response.data?.map((item: any) => {
                    return {
                        value: String(item.id),
                        label: `${item.first_name} ${item.second_name} ${item.third_name} ${item.fourth_name} / ${item.department.college.name} / ${item.department.name} / ${item.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${item.stage}`,
                    };
                });
                callback(data);
            }
        });
    }, []);

    return (
        <Flex direction={'column'} p={'md'} gap={'md'}>
            {/* <Select
                label={t('الطالب')}
                placeholder={t('اختر الطالب')}
                data={studentsSelect}
                size={'xs'}
                searchable
                onSearchChange={(value) => {
                    setStudentsSelect([]);
                    studentsApiInterceptor.setParam('search', value);
                }}
                clearable
                onClear={() => {
                    setStudent(null);
                    studentsApiInterceptor.setParam('search', '');
                }}
                value={String(student?.id)}
                onChange={(value) => {
                    const student = studentsApiInterceptor?.query?.data?.data.find((item: any) => item.id === Number(value));
                    setStudent(student);
                }}
            /> */}
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                isClearable
                isSearchable
                isRtl
                styles={{
                    container: (styles: any) => ({
                        ...styles,
                        width: '100%',
                        zIndex: 4,
                    }),
                } as any}
                onChange={(selected: any) => {
                    if (selected && selected.value) {
                        const student = students.find((item) => item.id === Number(selected.value));
                        setStudent(student);
                    } else {
                        setStudent(null);
                    }
                }}
                value={student ? { value: String(student.id), label: `${student.first_name} ${student.second_name} ${student.third_name} ${student.fourth_name} / ${student.department.college.name} / ${student.department.name} / ${student.study_type === 'morning' ? t('صباحي') : t('مسائي')} / ${t('المرحلة')} ${student.stage}` } : null}
            />

            {
                student?.id && tuitionFeesApiInterceptor.query.isFetching && (
                    <Skeleton height={30} radius="md" />
                )
            }
            {
                student?.id && tuitionFeesSelect?.length === 0 && !tuitionFeesApiInterceptor.query.isFetching && (
                    <Alert
                        color={'teal'}
                        title={t('جميع الأقساط مدفوعة')}
                    />
                )
            }
            {
                student?.id && tuitionFeesSelect?.length !== 0 && !tuitionFeesApiInterceptor.query.isFetching && (
                    <Select
                        size={'xs'}
                        label={t('القسط')}
                        placeholder={t('اختر القسط')}
                        data={
                            tuitionFeesSelect || []
                        }
                        value={tuitionFee?.id}
                        onChange={(value) => {
                            const tuitionFeeId = value;
                            const tuitionFee = tuitionFeesApiInterceptor.query.data?.data.find((item: any) => item.id === tuitionFeeId);
                            if (!tuitionFee) return;
                            setTuitionFee(tuitionFee);
                        }}
                        leftSection={
                            tuitionFeesApiInterceptor.query.isFetching && (
                                <Loader size={20} style={{ margin: 4 }} />
                            )
                        }
                    />
                )
            }

            {
                student && tuitionFee && (
                    <Flex
                        gap={'xs'}
                        direction={'column'}
                    >
                        {/* daleel */}
                        <Flex
                            gap={'xs'}
                            align={'center'}
                        >
                            <Text size={'sm'}>{t('رقم الدليل')}:</Text>
                            <NumberInput
                                size={'xs'}
                                placeholder={t('رقم الدليل')}
                                value={daleelId}
                                onChange={(value) => {
                                    setDaleelId(value as number);
                                }}
                            />
                        </Flex>
                        <Flex direction={'column'}>
                            <Flex
                                direction={'row'}
                                gap={'xs'}
                                align={'center'}
                            >
                                <Text size={'sm'}>{t('المبلغ')}:</Text>
                                <NumberFormatter
                                    value={tuitionFee.amount}
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                    suffix={t('د.ع')}
                                    thousandSeparator
                                />
                            </Flex>
                            {
                                loadingDiscount ? (
                                    <Loader size={20} />
                                ) : (
                                    <>
                                        <Flex
                                            direction={'row'}
                                            gap={'xs'}
                                            align={'center'}
                                        >
                                            <Text size={'sm'}>{t('الخصم')}:</Text>
                                            <NumberFormatter
                                                value={discount}
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: theme.colors.red[6],
                                                }}
                                                suffix={t('د.ع')}
                                                thousandSeparator
                                            />
                                        </Flex>
                                        <Flex
                                            direction={'row'}
                                            gap={'xs'}
                                            align={'center'}
                                        >
                                            <Text size={'sm'}>{t('الصافي')}:</Text>
                                            <NumberFormatter
                                                value={tuitionFee.amount - discount}
                                                style={{
                                                    fontSize: 24,
                                                    fontWeight: 'bold',
                                                }}
                                                suffix={t('د.ع')}
                                                thousandSeparator
                                            />
                                        </Flex>
                                    </>
                                )
                            }
                        </Flex>
                        <Button
                            size={'xs'}
                            onClick={() => {
                                const id = tuitionFee.id;
                                // split id by "@" to get the real id
                                const tuitionFeeId = id.split('@')[0];
                                const academicYearId = id.split('@')[1];
                                studentsInvoicesApiInterceptor.mutation.create({
                                    student_id: student.id,
                                    tuition_fee_id: tuitionFeeId,
                                    academic_year_id: academicYearId,
                                    daleel_id: daleelId,
                                });
                            }}
                            disabled={loadingDiscount}
                        >
                            {t('إصدار الوصل')}
                        </Button>
                    </Flex>
                )
            }
        </Flex>
    )

}