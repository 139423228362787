
import '@mantine/core/styles/global.css';
import '@mantine/core/styles.css';
import '@mantine/core/styles/UnstyledButton.css';
import '@mantine/core/styles/Button.css';
import '@mantine/core/styles/ScrollArea.css';
import '@mantine/core/styles/UnstyledButton.css';
import '@mantine/core/styles/VisuallyHidden.css';
import '@mantine/core/styles/Paper.css';
import '@mantine/core/styles/Popover.css';
import '@mantine/core/styles/CloseButton.css';
import '@mantine/core/styles/Group.css';
import '@mantine/core/styles/Loader.css';
import '@mantine/core/styles/Overlay.css';
import '@mantine/core/styles/ModalBase.css';
import '@mantine/core/styles/Input.css';
import '@mantine/core/styles/Flex.css';
import '@mantine/core/styles/NavLink.css';
import '@mantine/notifications/styles.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import '@mantine/core/styles/Menu.css';
import '@mantine/core/styles/ActionIcon.css';

import { ModalsProvider } from '@mantine/modals';

import { Notifications } from '@mantine/notifications';
import { DirectionProvider, MantineColorsTuple, MantineProvider, createTheme } from '@mantine/core';

import { CSS } from "@onedoc/react-print";

const primaryColor: MantineColorsTuple = [
    '#ffebeb',
    '#f9d2d2',
    '#f79fa0',
    '#f76b6b',
    '#f8423e',
    '#f82b23',
    '#f92216',
    '#de180c',
    '#c51007',
    '#ac0103'
];

const secondaryColor: MantineColorsTuple = [
    "#eff1fa",
    "#dcdfef",
    "#b6bde0",
    "#8d98d2",
    "#6a7ac6",
    "#5666bf",
    "#4a5cbd",
    "#3c4da6",
    "#344396",
    "#1b2556"
];

const theme = createTheme({
    colors: {
        primary: primaryColor,
        secondary: secondaryColor,
    },
    primaryColor: 'secondary',
    fontFamily: 'Readex Pro',
    // fontFamily: 'Almarai',
});


export default (props: any) => {
    return (
        <DirectionProvider initialDirection={'rtl'}>
            <MantineProvider theme={theme} withGlobalClasses withCssVariables>
                <ModalsProvider>
                    <Notifications position="bottom-left" />
                    {props.children}
                    <CSS>{`
                        body {
                            font-family: "Readex Pro", sans-serif;
                        }
                    `}</CSS>
                </ModalsProvider>
            </MantineProvider>
        </DirectionProvider>
    )
}