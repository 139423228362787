import { Box, Flex } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import classes from './halfscreen.module.css';
import image from '../../assets/img/bg-image.jpg';


export default function HalfScreen() {

    return (
        <Flex style={{ height: '100vh' }}>
            <Box className={classes.column}>
                <Flex
                    justify="center"
                    align="center"
                    style={{ height: '100%' }}
                >
                    <Outlet />
                </Flex>
            </Box>
            <Box
                className={classes.column + ' ' + classes.image}
                style={{ backgroundImage: `url(${image})` }}
            />
        </Flex>
    )

}